/**
 * Handle setting of rls relationship in retrieval filters
 * Bootstrap Templates Only
 */
const mrcFilters = (function (window, jQuery) {
	'use strict';

	function setRelationshipSelection (ev) {
		var $el = jQuery(ev.currentTarget);
		var rls = $el.data('rls');
		var inputName = $el.closest('.dropdown-menu').data('rls_field');
          var $valEl = jQuery(':input[name="val_' + inputName + '"]');

		jQuery(':input[name="rls_' + inputName + '"]').val(rls);

		$el.closest('.input-group').find('.display-rls').html(rls + '&nbsp;');

          if($valEl.hasClass('date-input')){
               if(rls == 'RG' || rls == 'NR'){
                    $valEl.addClass('date-range-input');
               }else{
                    $valEl.removeClass('date-range-input');
               }
               calendarPickers.initCalendarInput(0,$valEl.get(0));
          }
	}
	function setRelationshipValues($html) {
		var $forms = formHandler.getFormFrom$HTML($html);
   		 $forms.each(function () {
			jQuery(this).find('.hidden-rls').each(
				/**
				 * On page load, get rls values from hidden input and set them in the display rls button
				 * @param {HTMLInputElement} el
				 */
				function (i, el) {
					var rls = el.value,
							dftVal,
							$inputGroup;

					$inputGroup = jQuery(el).closest('.input-group');
                         var inputName = $inputGroup.find('.filter-relationship').attr('data-rls_field');
					
                         var $valEl = jQuery(':input[name="val_' + inputName + '"]');
                         var setDateRangeInput = false;
                         if (rls !== '') {
						$inputGroup.find('.display-rls').html(rls + '&nbsp;');

                              if(rls == 'NR' || rls == 'RG'){
                                   setDateRangeInput = true;
                              }
					} else {

						dftVal = $inputGroup.find('a[data-rls-default="true"]').attr('data-rls');
						if (dftVal) {
							jQuery(el).val(dftVal);
							$inputGroup.find('.display-rls').html(dftVal + '&nbsp;');
                                   
                                   if(dftVal == 'NR' || dftVal == 'RG'){
                                        setDateRangeInput = true;
                                   }
                              } else {
							jQuery(el).val('EQ');
							$inputGroup.find('.display-rls').html('EQ&nbsp;');
						}
					}
                         if( $valEl.hasClass('date-input') &&  setDateRangeInput){
                              $valEl.addClass('date-range-input');
                         }
				}
			);
		});
		
	}
	

	jQuery('body').on('click', '.filter-relationship a', setRelationshipSelection);

	return { setRelationshipValues: setRelationshipValues};
}(window, jQuery));
