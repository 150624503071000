/**
 * Add specific class names to links to produce various
 * one step maintainer behaviors
 * classes
 *  mrc-one-step-refresh - Click the link, performs the action, refreshes the form it is in
 */
const maintainerOneStep = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

    function handleOneStepLink (e){
        e.preventDefault()
        var $link = jQuery(e.currentTarget);
        var href = $link.attr('href');
        href = mrcJSUtil.addParm(href, 'one_step', '1');
        href =  mrcJSUtil.uniqueUrl(href);
        jQuery.get(href, function (){
            var  formInfo = formHandler.getForm($link);

            if(!formInfo.isForm){
                    formInfo.$form.find('[name=ajax_form]').val(1);
                    formHandler.doSubmit($link, null, true);
                    return;
            }
            var $form = formInfo.$form;
            //@ts-ignore
            submitViaAjax($form.get(0));
        });
        return false;
    }
    function handleOneStepCallback (e){
        e.preventDefault()
        var $link = jQuery(e.currentTarget);
        var callbackFunc = $link.attr('data-callback');
        var href = $link.attr('href');
        href = mrcJSUtil.addParm(href, 'one_step', '1');
        href =  mrcJSUtil.uniqueUrl(href);
        jQuery.get(href, function (data){
            window[callbackFunc].apply(this,[data,$link]);
        });
        return false;
    }
    function handleChildAddSelect(e){
        var $select = jQuery(e.currentTarget);
        var $span = $select.closest(".child-add-container");
        var val = $select.val();

        if(val == '') return;
        
        var url = $span.data('child');
        var updateField = $span.data('child-field');
        url = url.replace("slnk=1","");
        url += '&action_mode=add&one_step=1&json=1&' + encodeURIComponent(updateField) + '=' + encodeURIComponent(val);

        jQuery.get(url, function(){
            retrieveChildrenForQuickadd();
        })
    }

    function retrieveChildrenForQuickadd(){
        var $select = jQuery(':input[name=child_add_list]');
        
        if($select.length == 0) return;
        
        var $span = $select.closest(".child-add-container");
        var url = $span.data('child');
        var updateField = $span.data('child-field');
        url += '&reqtype=24';

        jQuery.getJSON(url, function(response){
            var $childList = $span.siblings('.child-list');
            $childList.html('');
            var keys = response.appData.keys;
            response.data.forEach(function(item){//

                let itemVal = item[updateField].value;
                let itemDesc = $select.find('option[value="' + itemVal + '"]').text();
                let displayVal = itemVal;

                if(!!itemDesc && itemDesc != ''){
                    displayVal = itemDesc;
                }
                var $li = jQuery('<li>');
                $li.text(displayVal).attr('data-value',itemVal);
                $li.attr('title','Click to remove').addClass('btn').addClass('btn-outline-primary').addClass('m-2');
                $li.attr('data-toggle','tooltip').attr('data-placement','bottom');
                $li.click(function(){
                    removeChild($li);
                })
                var keyParms = '';
                for(var i = 0; i < keys.length;i++){
                    keyParms += '&' + keys[i] + '=' + item[keys[i]].value;
                }
                $li.attr('data-keyparms', keyParms);
                $childList.append($li);
            })
           
            $span.attr('data-keys',keys);
            jQuery('.btn').tooltip({});
            
        })
    }
    function removeChild($li){
        var $span = $li.closest("ul").siblings('.child-add-container');
        var val = $li.attr('data-value');

        if(!!val){
            var url = $span.data('child');
            var updateField = $span.data('child-field');
            var keyParms = $li.attr('data-keyparms');
            url = url.replace("slnk=1","");
            url += '&one_step=1&action_mode=delete';
            url += keyParms;
            url += '&' + encodeURIComponent(updateField) + '=' + encodeURIComponent(val);
            
            jQuery.get(url, function(data){
                retrieveChildrenForQuickadd();
            });
        }
      
    }
    retrieveChildrenForQuickadd();
    jQuery('body').on('click', '.mrc-one-step-refresh', handleOneStepLink)
                    .on('change',':input[name=child_add_list]', handleChildAddSelect)
                    .on('click','.mrc-one-step-callback', handleOneStepCallback);
    
    
}(window, jQuery));
