/**
 * Add class mrc-signature-capture to an input to make it a signature capture field - needs to be very large (text db type is best)
 * Add <div data-signature="${row.SIGNATUREFIELD}"></div> to display the signature data from the database
 */
var mrcSignatureCapture = (function (window, jQuery) { // eslint-disable-line no-unused-vars
    'use strict';
    var SIG_AREA_HTML = '<div id="signaturearea{FIELD}" class="border mt-3"></div>';
    var SIG_CLEAR_BUTTON_HTML = '<button class="btn btn-secondary mt-2" id="clearButton{FIELD}" type="button">Clear Signature</button>';
    var SIG_DISPLAY = '<div class="signature-display"></div>';
    function setupJSignature($input){
        var id = $input.attr('id');
        var formName = $input.closest('form').attr('id');

        var signatureAreaHtml = SIG_AREA_HTML.replaceAll('{FIELD}',id);
        var signatureClearButtonHtml = SIG_CLEAR_BUTTON_HTML.replaceAll('{FIELD}',id);

        $input.before(signatureAreaHtml).hide();
        $input.data('hasData', false);
        var sig = jQuery('#signaturearea' + id).jSignature();

        sig.bind('change',function(){
            $input.data('hasData', true);
        });
        //handle form submit, set signature in input
        jQuery('#' + formName).bind('submit', function(e){

            if($input.data('hasData') == false) return;

            var datapair = sig.jSignature("getData","image");
            jQuery('#' + id).val(datapair);
        })

        //set existing signature
        var sigRead = jQuery('#' + id).val();
        if (sigRead.length > 25){
            var datapairRead = sigRead;
            sig.jSignature("setData", "data:" + datapairRead);
        }

        // Start Clear Signature Logic
        $input.before(signatureClearButtonHtml);
        jQuery('#clearButton' + id).click(function(){
            sig.jSignature("reset");
            $input.data('hasData', false);
        });

    }
    function setupJSignatureDisplays($el){
        var sigData = $el.data('signature');
        var img = new Image();
        if (sigData.length > 248) {
            img.src = 'data:' + sigData;
            $el.html(img);
           
        }
    }
    function initSignatureCapture ($html) {
        var selector = '.mrc-signature-capture';
        var displaySelector = '[data-signature]';
		var $signatureInputs = ($html ? $html.find(selector) : jQuery(selector));
        var $signatureDisplays = ($html ? $html.find(displaySelector) : jQuery(displaySelector));
		if ($signatureInputs.length === 0 &&  $signatureDisplays.length === 0) return;

		// @ts-ignore
		if (!loadjs.isDefined('jSignature')) {
			// @ts-ignore
			loadjs(['../assets/vendor/jSignature/jSignature.min.noconflict.js'], 'jSignature', { async: false });
		}

		// @ts-ignore
		loadjs.ready('jSignature', function () {
            $signatureInputs.each(function(){
                setupJSignature(jQuery(this));
            });
            $signatureDisplays.each(function(){
                setupJSignatureDisplays(jQuery(this));
            });
		});
	}
   
    return{
        initSignatureCapture:initSignatureCapture
    }
}(window, jQuery));