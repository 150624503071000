/**
 * Set the width of the side navigation to 250px and the left margin of the page content to 250px
 */
const mrcNavigation = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function openNav () {
		jQuery('.page-nav').animate({ 'left': 0 }, 350);
	}

	function closeNav () {
		jQuery('.page-nav').animate({ 'left': (jQuery('.page-nav').width() * -1) }, 350);
	}

	jQuery('.nav-toggler').click(function (ev) {
		if (jQuery('.page-nav').css('left') === '0px') {
			closeNav();
		} else {
			openNav();
		}
	});
	jQuery('body').on('click', '.navbar-brand', function (ev) {
		/*if (jQuery('.page-nav').css('left') === '0px') {
			closeNav();
		} else {
			openNav();
		}*/
	});
	return {};
}(window, jQuery));
