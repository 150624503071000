/**
 * Left side Accordion navigation
*/
const menuAccordion = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function showMinorGroup (ev) {
		var $el = jQuery(ev.currentTarget);

		setStatus($el.closest('li'));

		$el.closest('li').find('ul').first().toggle().find('ul').hide().find('li').each(function (i, el) {
			jQuery(el).attr('data-status', 'inactive');
		});

		var isActive = $el.closest('li').hasClass('active');

		handleGroupHighlighting(isActive, $el);
		toggleArrow($el);

		$el.closest('li').find('ul').first().find('.fa').each(function () {
			jQuery(this).removeClass('fa-minus').addClass('fa-plus');
		});
	}

	function setStatus ($li) {
		var status = $li.attr('data-status');

		if (!status || status === 'inactive') {
			$li.attr('data-status', 'active');
		} else {
			$li.attr('data-status', 'inactive');
		}
	}

	function handleGroupHighlighting (isActive, $el) {
		jQuery('.group-li.major-group.active').removeClass('active');

		var status = $el.closest('li').attr('data-status');

		if (!isActive && status == 'active') {
			$el.closest('li').addClass('active');
		} else {
			$el.closest('li').parents('.group-li').first().addClass('active');
		}
	}

	function toggleArrow ($el) {
		var $fa = $el.find('.fa');

		if ($fa.hasClass('fa-plus')) {
			$fa.removeClass('fa-plus').addClass('fa-minus');
		} else {
			$fa.removeClass('fa-minus').addClass('fa-plus');
		}
	}

	jQuery('body').on('click', '.major-group-a', showMinorGroup);

	return {};
}(window, jQuery));
