/**
 * Report prompt record selection, subtotal subtotalLevel
 * and outformat selection toggling
 * Bootstrap Templates
 */
const reportPrompt = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';
    
	// @ts-ignore
	var MRCCONSTANTS = window.MRCCONSTANTS;

	function toggleRadio (ev) {
		var $radio = jQuery(ev.currentTarget);
		var format = $radio.data('outfmt');

		$radio.addClass('active')
			.siblings().removeClass('active');

		jQuery('input[name="outfmt"][value="' + format + '"]').prop('checked', true);
		toggleListRadioIcon(ev.currentTarget);
	}

	function toggleCheckBox (ev) {
		var subtotalLevel = jQuery(ev.currentTarget).toggleClass('active').data('total');
		var $checkBox = jQuery('input[name="' + subtotalLevel + '"]');

		$checkBox.prop('checked', !$checkBox.prop('checked'));
		toggleListCheckboxIcon(ev.currentTarget);
	}

	function showPromptAndSetLevels (ev) {
		jQuery('.prompt-container').toggle();
          jQuery(window).scrollTop(0);
		let formInfo = formHandler.getForm(ev);
          var $form = formInfo.$form;
          let pgm = $form.attr('data-program');
		var formid = $form.attr('id');
          var  $promptForm;

          $promptForm = jQuery("form[id$='prompt']");

         /* if(!!pgm){
               $promptForm = jQuery(`[data-program="prompt-${pgm}"]`);
          }else{
               $promptForm = jQuery('#' + formid + 'prompt');
          }*/
		

		toggleActiveItem($promptForm, $form, 'D_DETAIL');
		toggleActiveItem($promptForm, $form, 'G_TOTAL');

		$form.find('input[name^="S_"]').each(function (i, el) {
			var name = jQuery(el).attr('name');

			toggleActiveItem($promptForm, $form, name);
		});
	}

	function toggleActiveItem ($promptForm, $form, name) {
		var $item = $promptForm.find('.list-group-item[data-total="' + name + '"]');
		var value = $form.find('input[name="' + name + '"]').val();

		if (value == 1) {
			$item.addClass('active');
			$promptForm.find('input[name="' + name + '"]').prop('checked', true);
			setFaActive($item);
		} else {
			$item.removeClass('active');
			$promptForm.find('input[name="' + name + '"]').prop('checked', false);
			setFaInactive($item);
		}
	}

	/**
	 * Developer sets the list items as active. On page load set the actual inputs checked based on the active list items
	 */
	function setPromptInputs ($html) {
		jQuery('.list-group-radio .list-group-item.active').each(function (i, el) {
			var format = jQuery(el).data('outfmt');
			var input = jQuery('input[name="outfmt"][value="' + format + '"]');

			input.prop('checked', true);
			toggleListRadioIcon(this);
		});

		jQuery('.list-group-checkbox .list-group-item.active').each(function (i, el) {
			var subtotalLevel = jQuery(el).data('total');
			var $checkBox = jQuery('input[name="' + subtotalLevel + '"]');

			$checkBox.prop('checked', true);
			toggleListCheckboxIcon(this);
		});

          jQuery('.mrc-list-input').each(function (i, el) {
               var $el = jQuery(this);
               var name = $el.attr('name');
               var val = $el.val();
               var $div = jQuery(getReportListPrompt(name, val));
               $el.after($div);
               $el.remove();
          });

	}

	function toggleListRadioIcon (element) {
		jQuery(element).find('.fa').addClass(MRCCONSTANTS.MRC_RADIO_CHECKED).removeClass(MRCCONSTANTS.MRC_RADIO_UNCHECKED);
		jQuery(element).siblings().find('.fa').removeClass(MRCCONSTANTS.MRC_RADIO_CHECKED).addClass(MRCCONSTANTS.MRC_RADIO_UNCHECKED);
	}

	function setFaInactive ($element) {
		$element.find('.fa').addClass(MRCCONSTANTS.MRC_CHECKBOX_UNCHECKED).removeClass(MRCCONSTANTS.MRC_CHECKBOX_CHECKED);
	}

	function setFaActive ($element) {
		$element.find('.fa').addClass(MRCCONSTANTS.MRC_CHECKBOX_CHECKED).removeClass(MRCCONSTANTS.MRC_CHECKBOX_UNCHECKED);
	}

	function toggleListCheckboxIcon (element) {
		var $element = jQuery(element);
		var isActive = $element.find('.fa').hasClass(MRCCONSTANTS.MRC_CHECKBOX_CHECKED);

		if (!isActive) {
			setFaActive($element);
		} else {
			setFaInactive($element);
		}
	}

	function reportToExcel (ev) {
          outputNonHTML(ev, 13);
	}

	function reportToPdf (ev) {
		outputNonHTML(ev, 11);
	}
     function outputNonHTML(ev, type){
          let $el = jQuery(ev.currentTarget);
          let formInfo = formHandler.getForm(ev);
		var $form = formInfo.$form;
          var ajaxForm = $form.find('input[name="ajax_form"]').val();
          $form.find('input[name="outfmt"]').val(type);
          $form.find('input[name="ajax_form"]').val(0);

          if(!formInfo.isForm){
               formHandler.doSubmit($el, null)
          }else{
               $form.submit();
          }

		$form.find('input[name="outfmt"]').val(0);
          $form.find('input[name="ajax_form"]').val(ajaxForm);
     }
     function getReportListPrompt(name,value,error){
          return  `<div id="div${name}" data-for="${name}">
                    <div id="hide${name}">
                         <div class="one-input0">
                              <input aria-labelledby="${name}-Label" id="1${name}" name="1${name}" type="text" size="6" fn="${name}" value="" class="ls ${name} form-control">
                         </div>
                         <div class="one-input0">
                              <input aria-labelledby="${name}-Label" id="2${name}" name="2${name}" type="text" size="6" fn="${name}" value="" class="ls ${name} form-control">
                         </div>
                         <div class="one-input0">
                              <input aria-labelledby="${name}-Label" id="3${name}" name="3${name}" type="text" size="6" fn="${name}" value="" class="ls ${name} form-control">
                         </div>
                         <div class="add-input" title="Add an input"><a href="#">Add...</a></div>
                         <div class="d-none">
                              <div class="one-input" id="oi${name}">
                                   <input aria-labelledby="${name}-Label" id="4${name}" name="4${name}" type="text" size="6" fn="${name}" value="" class="ls ${name} form-control">
                              </div>
                         </div>
                    </div>
                    <input type="hidden" class="lsinput" id="${name}" name="${name}" value="${value}">
               </div>`;
     }

	/**
	 * Report prompt have large list item buttons that need events
	 * handled to set the appropriate radio or check boxes that are hidden
	 * On report prompt pages, handle toggling of list groups for report format and subtotal level selection
	 */
	jQuery('body').on('click', '.list-group-radio .list-group-item', toggleRadio)
		.on('click', '.list-group-checkbox .list-group-item', toggleCheckBox)
		.on('click', '.report-to-excel', reportToExcel)
		.on('click', '.report-to-pdf', reportToPdf)
		.on('click', '.show-prompt', showPromptAndSetLevels);

	return {
		setPromptInputs: setPromptInputs,
          setFaActive,
          setFaInactive
	};
}(window, jQuery));
