/**
 * Logic for the full text search SOLR template
 * Web 2.0 and Bootstrap Templates
 */
var fullTextSearch = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function initFullTextSearch () {
		var searchValue = jQuery('#searchvalue').val();
		setupFulltextSearch(searchValue);
	}
	function setupFulltextSearch (searchValue) {
		if (searchValue && searchValue !== '_NULL_') {
			jQuery('#mrc_main_table td').each(function (index, el) {
				var $row = jQuery(el);
				var id = $row.text();
				var re = new RegExp('(\\b' + searchValue + '\\b)', 'gi');

				if (jQuery('#wordSearch').val() !== 'y') re = new RegExp('(' + searchValue + ')', 'gi');

				var html = $row.html();
				var id2 = id.replace(re, '<span class="hl">$1</span>');

				html = html.replace(id, id2);

				$row.html(html);
			});
		}
	}
	var form = jQuery('form').attr('id');
	jQuery('body').on('click', '.pagenav', function () {
		var cc = jQuery(this).data('pagetype');
		jQuery('#pagenav').val(cc);
		jQuery('#' + form).submit();
	});
	jQuery('body').on('click', '.facets', function () {
		jQuery('#actionField').val(jQuery(this).attr('name'));
		jQuery('#' + form).submit();
	});
	jQuery('body').on('click', '.clear-radio', function () {
		jQuery('.facet-radio').prop('checked', false);
		jQuery('#' + form).submit();
	});
	// @ts-ignore
	//if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
	// @ts-ignore
	window.setupFulltextSearch = setupFulltextSearch;
	//}

	return {
		setupFulltextSearch: setupFulltextSearch,
		initFullTextSearch:initFullTextSearch
	};
}(window, jQuery));
