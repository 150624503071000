var mrcMath = (function (window, jQuery) { // eslint-disable-line no-unused-vars
    'use strict';
    function standardDeviation (array) {
        const n = array.length
        const mean = array.reduce((a, b) => a + b) / n
        return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
      }
      function median(arr) {
        if (arr.length == 0) {
          return; // 0.
        }
        arr.sort((a, b) => a - b);
        const midpoint = Math.floor(arr.length / 2); 
        const median = arr.length % 2 === 1 ?
          arr[midpoint] : 
          (arr[midpoint - 1] + arr[midpoint]) / 2; 
        return median;
      }
      function mean(arr){
        return array => array.reduce((a, b) => a + b) / array.length;
      }
	return {
		median,
        standardDeviation,
        mean
	};
}(window, jQuery));