/**
 * Report record selection UI handling
 * Web 2.0 and Bootstrap Templates
 */
var reportInit = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	/*
	 * Called from application at page load.
	 */
	function initsetup (formid, isForm) {
		isForm = typeof isForm !== "undefined" ? isForm : true;
		// 2014-04-21: Runtime select rls=RT
		jQuery('.list-select select').attr('size', 5).attr('multiple', 'true');

		jQuery('body').on('click', '.add-input', function (ev) { // Add link clicked
			makeInput(jQuery(this));
		});

		jQuery('.add-input a').bind('click', cancelDefault);

		jQuery('body').on('keyup', 'input.ls', function (ev) { // Entered text in list input
			checkInput(jQuery(this).attr('fn')); // fn=R00x
		});
          if(isForm){
               jQuery('#' + formid).submit(function (ev) {
                    return checkInputAll();
               });
          }
		// Parse LS/NS values for update mode (error occured)
		jQuery('.lsinput').each(function (i, el) {
			var val = jQuery(el).val();
			var theid = jQuery(el).attr('id'); // id=R00x

			if (val === '') return; //Continue;

			makeInputUpdate(theid, val);
		});
	}

	/* global loadjs */
	function initAsmSelect (formId, isForm) {
		isForm = typeof isForm !== "undefined" ? isForm : true;
		var $asmSelects = jQuery('select[multiple]').not('select[mrccombobox]');
		if(formId !== undefined) {
			$asmSelects = jQuery((isForm ? '#' + formId : '[data-program="' + formId + '"]')).find('select[multiple]').not('select[mrccombobox]');
		}

		if ($asmSelects.length === 0 ) return;
		if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
			setAsmSelect(formId, isForm);
			return;
		}
		// @ts-ignore
		if (!loadjs.isDefined('asmselect')) {
			// @ts-ignore
			loadjs(['../mrcclasses/jquery.asmselect.js'], 'asmselect');
		}

		// @ts-ignore
		loadjs.ready('asmselect', function () {
			setAsmSelect(formId, isForm);
		});
	}

	function setAsmSelect (formId, isForm) {
		isForm = typeof isForm !== "undefined" ? isForm : true;
		var $select = 	jQuery('select[multiple]').not('select[mrccombobox]');
		if(formId !== undefined) {
			$select = jQuery((isForm ? '#' + formId : '[data-program="' + formId + '"]')).find('select[multiple]').not('select[mrccombobox]');
		}
		// @ts-ignore
		$select.asmSelect({
			addItemTarget: 'bottom',
			animate: true,
			highlight: true,
			sortable: false
		});
	}
    function setAsmSelectSingle ($select) {

		// @ts-ignore
		try{$select.asmSelect({
			addItemTarget: 'bottom',
			animate: true,
			highlight: true,
			sortable: false
		});
          }catch(err){}
	}
	/*
	 * Set Runtime Select
	 */
	function setRuntimeSelect () {
		jQuery('.runtime-rls').each(function (i, el) {
            var ele = jQuery(el).attr('seq');
            var rel = jQuery('#rls_' + ele + 'v').val();
            if (rel === 'RG' || rel === 'NR') {
                 // @ts-ignore
                 this.ia = jQuery('#sela_' + ele).detach();
                 // @ts-ignore
                 this.ic = jQuery('#selc_' + ele).detach();
    
            } else if (rel === 'LS' || rel === 'NS' || rel === 'CA' || rel === 'CO' ) {
               // @ts-ignore
               this.ia = jQuery('#sela_' + ele).detach();
               // @ts-ignore
               this.ib = jQuery('#selb_' + ele).detach();
                
            } else {
                // @ts-ignore
                this.ib = jQuery('#selb_' + ele).detach();
                // @ts-ignore
                this.ic = jQuery('#selc_' + ele).detach();
            }
			
		});

		jQuery('.runtime-rls').change(function (ev) {
			setRuntimeSelectInputs(this);
		});
	}
	function setRuntimeSelectInputs (select) {
		var ele = jQuery(select).attr("seq");
		var ida = '#sela_' + ele;
		var idb = '#selb_' + ele;
		var idc = '#selc_' + ele;
		var rel = jQuery(select).val();

		if (rel === 'RG' || rel === 'NR') {
			// @ts-ignore
			if (jQuery(ida).length > 0) select.ia = jQuery(ida).detach();

			// @ts-ignore
			if (select.ib){
                jQuery(select.ib).appendTo("#sel_" + ele);

                var $selectList = jQuery(select.ib).find('select');
                // @ts-ignore
                try{ $selectList.asmDestroy();}catch(err) {}
            } 
//
			// @ts-ignore
			if (jQuery(idc).length > 0) select.ic = jQuery(idc).detach();

        } else if (rel === 'LS' || rel === 'NS' || rel === 'CA' || rel === 'CO' ) {
			// @ts-ignore
			if (jQuery(ida).length > 0) select.ia = jQuery(ida).detach();

			// @ts-ignore
			if (jQuery(idb).length > 0) select.ib = jQuery(idb).detach();

			// @ts-ignore
            if (select.ic){
                jQuery(select.ic).appendTo('#sel_' + ele);
                var $selectList = jQuery(select.ic).find('select');
                try{ $selectList.asmDestroy();}catch(err) {}
                $selectList.attr('multiple','multiple');
                $selectList.attr('size','5');
                setAsmSelectSingle($selectList);
            }
            
		} else {
               if (jQuery(ida).length > 0) select.ic = jQuery(idc).detach();

			// @ts-ignore
			if (jQuery(idb).length > 0) select.ib = jQuery(idb).detach();
			// @ts-ignore
			if (select.ia){
                jQuery(select.ia).appendTo("#sel_" + ele);

                var $selectList = jQuery(select.ia).find('select');
                // @ts-ignore
               try{ $selectList.asmDestroy();}catch(err) {}
            } 

			// @ts-ignore
			if (jQuery(idb).length > 0) select.ib = jQuery(idb).detach();

			// @ts-ignore
			if (jQuery(idc).length > 0) select.ic = jQuery(idc).detach();
		}

		if (rel === 'IN' || rel === 'NN' || rel === 'IB' || rel === 'NB') {
			jQuery('#sel_' + ele).hide('slow');
		} else {
			jQuery('#sel_' + ele).show('slow');
		}
	}
	function cancelDefault (evt) {
		evt.preventDefault();
	}

	/*
	 * Make input for update mode (error mode)
	 */
	function makeInputUpdate (divId, val) {
        var values = [];
        
        if(val.indexOf("!") < 0){
            values = jQuery.trim(val) == '' ? [] : val.split(" ");
        }else{
            values = getValues(val);
        }
		

		for (var i = 0; i < values.length; i++) {
			if (i > 2) {
				makeInputUpdate2(divId, values[i]);

				continue;
			}

			var input0 = jQuery('#div' + divId + ' .one-input0');

			jQuery(input0).eq(i).find('input').val(values[i]);
		}

		jQuery('input.ls').keyup(function (ev) {
			checkInput(jQuery(this).attr('fn')); // fn=R00x
		});
	}

	/*
	 * Make input for update mode (error mode)
	 */
	function makeInputUpdate2 (divId, val) {
		var cloneDiv = '#div' + divId + ' #oi' + divId;
		var inputDiv = jQuery(cloneDiv);
		var input1 = inputDiv.clone();

		input1.attr('id', 'xxx').find('input').val(val);

		var addDiv = '#div' + divId + ' .add-input';

		jQuery(addDiv).before(input1);
	}

	/*
	 * Make input when user click Add link.
	 */
	function makeInput (addDiv) {
		var inputDiv = jQuery(addDiv).next().children();
		var input1 = inputDiv.clone();

		input1.attr('id', 'xxx').find('input').val('');

		jQuery(addDiv).before(input1);

		jQuery('input.ls').keyup(function (ev) {
			checkInput(jQuery(this).attr('fn')); // fn=R00x
		});
		// Move the focus to the newly-added runtime selection input field.
        //input1.trigger("focus");
		input1.focus();
		return input1;
	}

	/*
	 * Check all inputs of in the list
	 */
	function checkInputAll () {
		var OK = true;

		jQuery('.lsinput').each(function (i, el) {
			OK = false;
			var isok = checkInput(jQuery(el).attr('id')); // fn=R00x

			if (!isok) {
				jQuery(el).focus().select();

				return false;
			}

			OK = true;
		});

		return OK;
	}

	/*
	 * Check if we can derive delimiter.
	 */
	function checkInput (inputId) {
		dspError('', inputId);

		var inputs = jQuery.makeArray(jQuery('.' + inputId));

		if (inputs.length === 0) return true;

		// Find delimiter
		var valtot = '';
		var val;

		for (var i = 0; i < inputs.length; i++) {
			val = jQuery.trim(jQuery(inputs[i]).val().toString());

			valtot += val;
		}

		var deli0 = getDelimiter(valtot);

		if (!deli0) {
			dspError('Values cannot contain all of " ", "&", "#", "@", "%", "*", ":", ";"', inputId);

			return false;
		}

		// Put value in value3
		var val3 = '';
		var deli = '';

		for (i = 0; i < inputs.length; i++) {
			val = jQuery.trim(jQuery(inputs[i]).val().toString());
			if (val === '') continue;

			val3 += deli + val;
			deli = deli0;
		}

		if (val3 !== '') val3 += '!' + deli0 + '!';

		jQuery('#' + inputId).val(val3);

		return true;
	}

	/**
	 * Display error
	 */
	function dspError (errtext, msgId) {
		jQuery('#msg' + msgId).text(errtext);
	}

	var delimiters = [' ', '&', '#', '@', '%', '*', ':', ';'];
	/**
	 * Returns the delimiter not used in the string value.
	 * Return null if string contains all delimiters in definition.
	 */
	function getDelimiter (value) {
		if (!value) return ' ';

		value = jQuery.trim(value);

		var deli = null;

		for (var i = 0; i < delimiters.length; i++) {
			if (value.indexOf(delimiters[i]) < 0) {
				deli = delimiters[i];

				break;
			}
		}

		return deli;
	}

	/**
	 * Returns values in a value3 field 'aa aa&bb bb!&!'
	 */
	function getValues (value) {
		var reg = /!\W!/;
		var arr = reg.exec(value);

		if (!arr) {
			var myArray = [];

			return myArray; // Empty array
		}

		var deli = arr[0].substring(1, 2);
		var value0 = value.substring(0, arr.index);
		var values = value0.split(deli);

		return values;
	}

	// @ts-ignore
	if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
		// @ts-ignore
		window.initsetup = initsetup;
		// @ts-ignore
		window.setRuntimeSelect = setRuntimeSelect;
		// @ts-ignore
		window.setAsmSelect = setAsmSelect;
	}
	// @ts-ignore
	window.getDelimiter = getDelimiter;
	return {
    initAsmSelect: initAsmSelect,
    initsetup: initsetup,
    setRuntimeSelect: setRuntimeSelect,
	setRuntimeSelectInputs: setRuntimeSelectInputs,
     checkInputAll:checkInputAll
  };
}(window, jQuery));
