/**
 * Logic to control the Grid Add template UI
 * Web 2.0 and Bootstrap Templates
 */
var gridAdd = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';
     
	function deleteGridRow (ev) {
		var $tr = jQuery(ev.currentTarget).closest('tr');
        //console.log($tr.nextAll('.error-row').length);
        $tr.nextAll('.error-row:first').remove();
        $tr.remove();

		var rows = jQuery('.delete_row').length;

		if (rows === 1) jQuery('.delete_row').hide();

		jQuery('#max_rows').val(rows); // Update max_rows
		jQuery('#max_rowsspan').html('' + rows); // Update max_rows

		// Resequence checkbox
		var seq = 0;
		var names = [];
		var first = true;

		jQuery('#mrc_main_table').find('input[type="checkbox"]').each(function (i, el) {
			var fid = jQuery(el).attr('name');
			var newName;

			if (!fid) return; // Continue each loop

			var pos = fid.indexOf('_row');

			if (pos > 0) {
				var newName0 = fid.substring(0, pos + 4);

				names.push(newName0);

				if (!first && names[0] == newName0) seq++;

				first = false;
				newName = newName0 + seq;

				jQuery(el).attr('name', newName);
			}
        });
        jQuery('body').trigger('mrc:gridrowdeleted');
	}

	function deleteGridRowHandler () {
		jQuery(this).click(function () { // remove the clicked tr row
			jQuery(this).parentsUntil('tr').parent().remove();

			var rows = jQuery('.delete_row').length;

			if (rows === 1) jQuery('.delete_row').hide();

			jQuery('#max_rows').val(rows); // update max_rows
			jQuery('#max_rowsspan').html('' + rows); // update max_rows
		});
	}

	// @ts-ignore
	jQuery.fn.deleteGridRowHandler = deleteGridRowHandler;

	/**
	 * Handle 'Add row' for Grid Add Maintainer
	 * To use this plugin:
	 * jQuery("#addgrid_row").addGridRowHandler(tbodyId, pickerOptions);
	 */
	/* global pickeropt */
	function addGridRowHandler (form, pickerOptions) {
		jQuery(this).off('click.mrcGridAdd').on('click.mrcGridAdd', function addRowToGridAdd (ev) { // Add a row
			var $lastTr = jQuery('.grid_row').last();
			var $newRow = jQuery($lastTr).clone(false);
       // var $lastTr = jQuery('.grid_row').last();
			//var $newRow = jQuery('<tr>');
          //  $newRow.html(jQuery($lastTr).html()).attr("class", $lastTr.attr("class"));
			// Set default values for new row
			var $inputsToChange = $newRow.find('.blank-for-new-row,.blank-for-new-row select');//gulp 2019-04-26:fix (28662)
               $inputsToChange.val('');
               $inputsToChange.trigger('change');
                    $newRow.find('span.errortext').hide();
                    $newRow.find('.is-invalid, .is-valid').removeClass('is-invalid is-valid');
               $newRow.find('select[mrccascadefield]').prop('disabled',true);
               
               if($lastTr.closest('table.table-blank-inputs').length > 0){
                    $newRow.find('.mrcinput, .form-control').each(function(){
                         if(!jQuery(this).hasClass('preserve-input-value')){
                              jQuery(this).val('').trigger('change');
                              }
                    })
               }
               $newRow.find('[data-default]').each(function(){
                    jQuery(this).find(":input").val(jQuery(this).attr('data-default'));
               
               })
			/**
			 * @type {string | number}
			 */
			var newindex = jQuery('body').attr('newindex');

			if (!newindex) {
				var rowIndex = $newRow.find('[data-rowindex]').last().attr('data-rowindex');

				newindex = parseInt(rowIndex, 10);
			} /*2019-05-07 fix (28382) else {
				newindex = parseInt(newindex, 10) + 1;
			}*/

			if (isNaN(newindex)) {
				newindex = 1;
			} else {
				newindex++;
			}

			jQuery('body').attr('newindex', newindex);
			$newRow.find('input[data-rowindex]').attr('data-rowindex', newindex);

			// Add new row to form table
			jQuery(form).find('#mrc_main_table').append($newRow);
			$newRow.find('td').toggleClass('one two');
			$newRow.toggleClass('even odd');
			jQuery('.delete_row').show();

			// Bump id suffix (FLD_grid2 -> FLD_grid3) for input and errtext span
			$newRow.find('.gridinput, .errortext, .invalid-feedback').each(function (i, el) {
				var fid = jQuery(el).attr('id');

				if (!fid) return; // Continue each loop

				var pos = fid.indexOf('_grid');

				if (pos > 0) {
					var newid = fid.substring(0, pos + 5) + newindex;

					jQuery(el).attr('id', newid);
					// @ts-ignore
					jQuery('#' + newid).setAjaxHelpers();
				}
			});
            jQuery(form).find('#mrc_main_table').find("tr:last").find('select[mrccascadefield]').each(function (i, el) {
                var cascadeField = jQuery(this).attr('mrccascadefield');
                var fid =  jQuery(this).attr('id');
				if (!fid) return; // Continue each loop

				var pos = fid.indexOf('_grid');
				var pos2 = cascadeField.indexOf('_grid');
				if (pos > 0) {
					var newid = fid.substring(0, pos + 5) + newindex;
                    jQuery(this).attr('id', newid);
                }
                if (pos2 > 0) {
                    var newCascadeField = cascadeField.substring(0, pos2 + 5) + newindex;
                    jQuery(this).attr('mrccascadefield', newCascadeField);
				}
                try {
                    jQuery('#' + newCascadeField).change(function (ev) {
                        // @ts-ignore
                        setCascadingDropdowns(newCascadeField); // mrcajax.js, line 954
                    });
                } catch (err) {
                    /* NOOP */
                }
            });
			// Fix checkbox names
			$newRow.find('input[type="checkbox"]').each(function (i, el) {
				var fid = jQuery(el).attr('name');

				if (!fid) return; // Continue each loop

				var pos = fid.indexOf('_row');

				if (pos > 0) {
					var newid = fid.substring(0, pos + 4) + newindex;

					jQuery(el).attr('name', newid);
				}
			});

			// Bump *AUTOSEQ number if any (2012-11-30)
			var autoseqStr = jQuery('#autoseq_fields').val().toString(); // From skeleton
			var autoseqInc = jQuery('#autoseq_num').val().toString(); // From skeleton

			if (autoseqStr && autoseqStr !== '') {
				var autoseqs = autoseqStr.split(',');
				var autoseqIncs = autoseqInc.split(',');

				for (var i = 0; i < autoseqs.length; i++) {
					var autoseqName = jQuery.trim(autoseqs[i]);

					if (autoseqName === '') continue;

					var selector = 'input[name="' + autoseqName + '"]';
					var autoIncr = parseInt(autoseqIncs[i]);
					var lastVal = $lastTr.find(selector).eq(0).val().toString();
					var lastVali = parseInt(lastVal, 10);
					var newSeq = lastVali + autoIncr;

					/* jshint -W083 */
					$newRow.find(selector).each(function (i2, el2) {
						jQuery(el2).val(newSeq).attr('added_autoseq_field', 'y');
					});
					/* jshint +W083 */
				}
			}

			// Ajax return data if any
			// @ts-ignore
			$newRow.find('[data-remotefields]').ajaxAutoFill();

			// slnk return data
			// @ts-ignore
			$newRow.find('a[mrc="returndata"], .rtnlnk').setRowNumberForReturnData();

			// Handle datepicker if any
			$newRow.find('img.ui-datepicker-trigger').remove();

			var img = $newRow.find('.datefld'); // Generated date picker

			if (img && img.length > 0) {
				// @ts-ignore
				jQuery(img).removeClass('hasDatepicker').removeAttr('id').datepicker(pickerOptions);
			}

			var img2 = $newRow.find('input[mrc="mrcdate"]'); // m-Painter added date picker

			if (img2 && img2.length > 0) {
				// @ts-ignore
				jQuery(img2).removeClass('hasDatepicker').removeAttr('id').datepicker(pickeropt); // pickeropt is in skeleton
			}

			// Update max_rows input
			var rows = jQuery('.delete_row').length; 

			jQuery('#max_rows').val(rows); // Update max_rows
            jQuery('#max_rowsspan').html('' + rows); // Update max_rows
            jQuery('body').trigger('mrc:gridrowadded');
		});
	}
	// @ts-ignore
	jQuery.fn.addGridRowHandler = addGridRowHandler;

	/**
	 * Get sufix of '_grid123' from FLD_grid123
	 */
	function gridSufix (fld) {
		var sufix = '';
		var pos = fld.indexOf('_grid');

		if (pos > 0) sufix = fld.substring(pos);

		return sufix;
	}
	// @ts-ignore
	jQuery.gridSufix = gridSufix;

	/**
	 * Get field name from FLD_grid123
	 */
	function gridFieldName (fld) {
		var fld0 = fld;
		var pos = fld.indexOf('_grid');

		if (pos > 0) fld0 = fld.substring(0, pos);

		return fld0;
	}
	// @ts-ignore
	jQuery.gridFieldName = gridFieldName;

	/**
	 * Check if this app is grid maintainer
	 */
	function  isGridApp () {
		var isgrid = jQuery('#isGrid').val();

		return (isgrid && isgrid === 'Y');
	}
	// @ts-ignore
	jQuery.isGridApp = isGridApp;

	/**
	 * Set up Ajax suggest helper
	 */
	/* global set_ajax_suggest */
	function setAjaxHelpers () {
		var $el = jQuery(this);
		var lookupTable = $el.attr('mrclookup');
		var mnt_fld = $el.attr('id'); // Field to apply ajax suggest to
		var ret_fld = $el.attr('mrclookupfield'); // Name of field to lookup in retrieval
        var type = $el.attr('mrcsuggest');
        var cascadeType = $el.attr('mrcajaxdropdown');

		// @ts-ignore
		if (type && type === 'suggest') set_ajax_suggest(lookupTable, ret_fld, mnt_fld); // mrcajax.js, line 962
        // @ts-ignore
      
    }
	// @ts-ignore
	jQuery.fn.setAjaxHelpers = setAjaxHelpers;

	/**
	 * Set row number for Grid Add maintainer
	 * 'Lookup' return data: a[mrc=returndata],
	 * 'Smartlink' return data: .rtnlnk
	 * rownum0 is defined in skeleton.
	 */
	/* global rownum0: true */ // eslint-disable-line no-unused-vars
	function setRowNumberForReturnData () {
		jQuery(this).click(function (ev) { // Set row number for return data of Lookup
			var tr = jQuery(this).parentsUntil('tr').parent();
			var input1 = tr.find('input').eq(0);
			var row = input1.attr('data-rowindex');

			// @ts-ignore
			rownum0 = row;
		});
	}
	// @ts-ignore
	jQuery.fn.setRowNumberForReturnData = setRowNumberForReturnData;

	// @ts-ignore
	window.deleteGridRow = deleteGridRow;

	return {
		deleteGridRow: deleteGridRow
	};
}(window, jQuery));
