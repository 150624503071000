/**
 *
 */
const mrcNotification = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';
//
	function initializeNotificationsBadge($html) {
        var $target = !!$html ? $html : jQuery('body');
		$target.find('.mrc-notifications').each(function(){
            var href = jQuery(this).attr('href');
            var hideForZero = jQuery(this).hasClass('zero-hide');
            href += href.indexOf("?") >=0 ? '&' : '?';
            href += 'reqtype=30';
            var $badge = jQuery(this);
            jQuery.get(href, function(num){
                num = jQuery.trim(num);
                num = num == '' ? '0' : num;
                 var display =  $badge.hasClass('btn') || $badge.closest('.nav-item').length > 0 ? 'inline-block' : 'inline';
                 
                if(num == '0' && hideForZero) display = 'none';

                if($badge.text().indexOf('{{number}}') >=0){
                    var text = $badge.html();
                    text = text.replace('{{number}}', num);
                    $badge.html(text)
                   
                    $badge.css('display',display);
                    return;
                }
                if(jQuery.trim($badge.text()) != ''){
                    num += ' ';
                }
                
                $badge.prepend(num);
                $badge.css('display',display);
            })
        });
    }

	return {
        initializeNotificationsBadge
    };
}(window, jQuery));
