/**
 *
 */
const mrcPagination = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';
	var pageLoading = false;

	function getMoreRecordsAjax (max_rows, total_rows, form) {
		var page = parseInt(jQuery(form).find('input[name=pageNum]').val());
		if (!pageLoading && ((page * max_rows) < total_rows)) {
			var data1 = $(form).serialize() + '&mobile=1&btn_next=1&x=' + Math.random();
            
			pageLoading = true;

			var url = $(form).attr('action');
			showLoadingMessage();
			$.ajax({
				type: 'get',
				cache: false,
				url: url,
				data: data1,
				success: function (data) {
					var check = data.substring(0, 50);

					if (check.indexOf('Begin Paging') > 0) {
						$('.list-view').append(data);
						form.pageNum.value = parseInt(form.pageNum.value) + 1;
						pageLoading = false;
					}
					hideLoadingMessage();
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					pageLoading = false;
					hideLoadingMessage();
				},
				dataType: 'html'
			});
		} else if ((page * max_rows) >= total_rows) {
			hideLoadingMessage();
		}
	}

	function handleScrollPagination () {
		var	form = $('form.responsive-form').get(0);
		var max_rows;
		var total_rows;

		// @ts-ignore
		if (!form || !form.max_rows || !form.total_rows) return;

		// @ts-ignore
		max_rows = form.max_rows.value;
		// @ts-ignore
		total_rows = form.total_rows.value;

		if (parseInt(max_rows, 10) > parseInt(total_rows, 10)) $('#list_record_loader').remove();

		getMoreRecordsAjax(max_rows, total_rows, form);
	}
	function showLoadingMessage () {
		var $message = jQuery('<li>').addClass('responsive-loading-message list-group-item list-group-item-action').html('Loading additional records <i class="fa fa-spinner fa-spin fa-fw"></i>');
		jQuery('.list-view').append($message);
	}
	function hideLoadingMessage () {
		jQuery('.responsive-loading-message').remove();
	}
	/* global maintainerDetailView */
	if (maintainerDetailView.checkIsSmallScreen()) {
		$(document).unbind('scrollstart');
		$(document).bind('scrollstart', handleScrollPagination);
	}

	return {};
}(window, jQuery));
