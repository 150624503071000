/**
 * Call jquery plugin logic
 * Web 2.0 and Bootstrap Templates
 */
var mrcJqueryPlugins = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	/**
	 * Set email textarea rows
	 */
	function initJqueryPlugins () {
		try {
			//TODO: remove?
			//jQuery('.fixed').fixedtableheader();
		} catch (err) {
			console.log(err);
		}
	}

	// @ts-ignore
	if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
		// @ts-ignore
		window.initJqueryPlugins = initJqueryPlugins;
	}

	return {
		initJqueryPlugins: initJqueryPlugins
	};
}(window, jQuery));
