/**
 * Handles
 * Bootstrap Templates
 */
var statusButtons = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';
 
	// var mntapp = jQuery('form').attr('ACTION');	    
     var url0= '?action_mode=update&transition_to=';
   
     
     /*** open notes modal ***/
     var notesEle = null;
	 jQuery("body").on('click','.hasNotes', function(ev) {	
		 ev.preventDefault();
 		 var notesDiv = jQuery('#NoteModal');
 		 if (!notesDiv || notesDiv.length ==0) {
 			jQuery('body').append(jQuery(notesModal));
 		 }
	 		
		 notesEle = jQuery(this);
		 jQuery('#notes-text').val(''); 
 		 jQuery('#NoteModal').modal('show');
 	 });
 	 
 	 
 	 /*** submit notes event ***/
 	 jQuery("body").on('click','#save-notes',function(ev) {
 		 var kv = jQuery(notesEle).data('key_val');
    	 var noteFld = jQuery(notesEle).data('notes'); //'chage status' label
    	 var valTrans = jQuery(notesEle).val(); 
    	 var type00 = 'btn0';
    	 var trig = jQuery(notesEle).parent();
    	 var dsptype = 'button';
    	 var chgtext = ''; 
    	 
    	 if (!valTrans || valTrans == '') { //select not button
    		 valTrans = jQuery(notesEle).attr('href');
    		 dsptype = 'dropdown';
    		 trig = jQuery(notesEle).parent().parent();
    		 chgtext = '&chg_text=' + encodeURIComponent(jQuery(notesEle).data('chgtext'));

    	 }
    	 
    	 var valText = jQuery('#notes-text').val(); //val
    	 var text0 = encodeURIComponent(valText);
    	 var url = url0 + valTrans + '&' + kv + '&key_values=' + encodeURIComponent(kv);
    	 url += '&notes_fld=' + encodeURIComponent(noteFld); 
    	 url += '&notes_val=' +text0; 
    	 url += '&dspType=' + dsptype;
    	 url += chgtext;
    	 var app = jQuery(notesEle).data('libapp');
    	 url = app + url + '&ddApp=' + encodeURIComponent(app); 
    	  
    	 transition(url,trig, type00);
    	 
 		jQuery('.modal').modal('hide');
 		
 	 });
 	 
	 	 
 	/*** select event ***/ 
     jQuery('body').on('click','.trans-sel',function(ev) {
    	 ev.preventDefault();
    	 var kv = jQuery(this).data('key_val');
    	 var tx = jQuery(this).data('chgtext'); //'chage status' label
    	 var val = jQuery(this).attr('href');
    	 var url = url0 + val + '&' + kv + '&dspType=dropdown&key_values=' + encodeURIComponent(kv);
    	 url += '&chg_text=' + encodeURIComponent(tx); 
    	 var app = jQuery(this).data('libapp');
		 url = app + url + '&ddApp=' + encodeURIComponent(app); 
		 jQuery(this).closest('.btn-group').find('.menu-title .spinner-grow').css('display', 'inline-block');
    	 //alert('==='+url)	 
		 transition(url, this, 'trans-sel');
     });
          	
     
     /*** button event ***/
     jQuery('body').on('click','.trans-btn',function() {
      	var kv = jQuery(this).data('key_val');
    	var val = jQuery(this).val();
    	var url = url0 + val + '&' + kv + '&dspType=button&key_values=' + encodeURIComponent(kv);
    	var app = jQuery(this).data('libapp');
    	url = app + url + '&ddApp=' + encodeURIComponent(app); 
		 jQuery(this).find('.spinner-grow').css('display', 'inline-block');
    	var parentDiv = jQuery(this).parent();
    	transition(url,parentDiv, 'btn0');
     });
   
     
     function transition(url, ele, type0) {
       jQuery.ajax( url).done(function(data) {
     	 
     	  var json = JSON.parse(data);
     	  var err = json.error;
     	  if (err && err.length > 0) {
     		  alert(err)
     		  return;
     	  }
		var parent = jQuery(ele).parent();
		  jQuery(ele).find('.spinner-grow').hide();
     	  if (type0 == 'trans-sel') {
     		  var newone = jQuery(json.html);	  
			  jQuery(ele).closest('.btn-group').replaceWith(newone);
     		  
     	  } else {     		  
     		  jQuery(parent).append(jQuery(json.html));
     		  jQuery(ele).remove();
		   }

		   var $statusBadge = jQuery('span[data-keys="' + json.keys + '"]');
		   if($statusBadge.length != 0){
				$statusBadge.text(json.newStatus);
				$statusBadge.removeClass (function (index, css) {
					return (css.match (/(^|\s)badge-\S+/g) || []).join(' ');
				});
				$statusBadge.addClass('badge-' + json.newTheme);
		   }
		   //fire refresh event so that listeners can be added
		  jQuery('body').trigger('mrc:statusRefresh',json);
      
       });
     }
     
     var notesModal = '<div class="modal" id="NoteModal"  role="dialog"  aria-hidden="true">'+
        '<div class="modal-dialog" role="document">'+
         ' <div class="modal-content">'+
          '  <div class="modal-header flex-wrap bg-light">'+
           '     <h5 class="w-100 wf-title modal-title">Enter a note or comment below</h5>'+
            '</div>'+
            '<div class="modal-body wf-body">'+
             '   <div class="form-row">'+
              '      <div class="form-group col-lg-12">'+
					'	<textarea class="form-control" rows="5" name="" id="notes-text"></textarea>'+
                 '   </div>'+
                '</div>'+
            '</div>'+
            '<div class="modal-footer wf-foot bg-light">'+
             '   <button href="javascript:void(0)" type="button" class="btn wf-cancel" data-dismiss="modal">'+
              '      <i class="fa fa-times"></i> Cancel'+
               ' </button>'+
                '<button href="javascript:void(0)" type="button" id="save-notes" class="btn btn-action wf-save">'+
                 '   <i class="fa fa-save"></i> Accept'+
                '</button>'+
            '</div>'+
          '</div>'+       
       ' </div>'+
      '</div>';
     
	
}(window, jQuery));
