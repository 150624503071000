/**
 * m-Painter helper 'Element Visibility Control' allows hiding and showing
 * of any HTML element based on the selection of a form input value. this file controls the
 * hiding and showing of the lements based on the following attributes
 * data-show-when-value="value1,value2"
 * data-hide-when-value="value3,value4"
 * data-input-toggle="INPUTNAME"
 */
const formInputToggle = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';
	var FILE_INPUT_SELECTOR = '.custom-file-input';
     const BLOCK_ELEMENTS =  /^(address|blockquote|body|center|dir|div|dl|fieldset|form|h[1-6]|hr|isindex|menu|noframes|noscript|ol|p|pre|table|ul|dd|dt|frameset|li|tbody|td|tfoot|th|thead|tr|html)$/i;
	const D_CLASSES = ['d-flex','d-inline','d-block','d-inline-block'];
     function updateFileText (ev) {
		var DEFAULT_FILE_TEXT = 'Choose File';
		var $input = $(ev.currentTarget);
		var id = $input.attr('id');
		var $label = $('.custom-file-label[for="' + id + '"]');
		var origFileName = $input.data('value');
		var fileName = $input.val().toString();

		if (fileName) {
			fileName = fileName.trim().split('\\').pop();
		} else if (origFileName) {
			fileName = origFileName.trim();
			$input.data('value', null);
		} else {
			fileName = DEFAULT_FILE_TEXT;
		}

		$label.text(fileName);
	}
     function isBlockEl(el) {
          return BLOCK_ELEMENTS.test(el.nodeName);
      }
	function setFormToggleEvents ($html) {
		var $fileInputs = ($html ? $html.find(FILE_INPUT_SELECTOR) : $(FILE_INPUT_SELECTOR));

		if ($fileInputs.length) $fileInputs.trigger('change');

		toggleOnInputChange();
          toggleOnButtonClick();
          toggleBootstrapRadios($html);
	}
     function toggleOnButtonClick () {
          jQuery('#toggleme').show();
          var isGrid = jQuery('.grid-update').length > 0;
          jQuery('[data-show-when-click],[data-hide-when-click]').each(function (i, el) {
                 var $elementToShow = $(el);
                 var showWhenSelector = '' + $elementToShow.data('show-when-click');
                 var hideWhenSelector = '' + $elementToShow.data('hide-when-click');
                 
                 D_CLASSES.forEach(function(cls){
                    if($elementToShow.hasClass(cls)){
                         $elementToShow.attr('data-d-class', cls);
                         if(!!showWhenSelector){
                              $elementToShow.removeClass(cls);
                         }
                    }
                 })
                 jQuery('body').off('click.showOnClick').on('click.showOnClick', showWhenSelector + ',' + hideWhenSelector, function (ev) {
                    showHideOnClick(ev, $elementToShow);
                 });

            });
     }
     function showHideOnClick (ev, $elementToShow) {
          let cls = 'd-block';
          let isVisible = $elementToShow.is(':visible');
          $elementToShow.removeClass('d-none d-table d-inline d-block d-flex d-inline-block d-table-row');
               console.log(isVisible)
          if(!isVisible) {
               let dcls = $elementToShow.attr('data-d-class');
               if(!isBlockEl($elementToShow[0])){
                    cls = 'd-inline';
               }
               if($elementToShow.is('table')){
                    cls = 'd-table';
               }else if($elementToShow.is('tr')){
                    cls = 'd-table-row';
               }
               if(!!dcls){
                    cls = dcls;
               }
          }else{
               cls = 'd-none';
          }
          $elementToShow.addClass(cls);
     }
	function toggleOnInputChange () {
        var isGrid = jQuery('.grid-update').length > 0;
		$('[data-input-toggle]').each(function (i, el) {
			var $elementToShow = $(el);
			var showWhenValue =  $elementToShow.data('show-when-value');
			var hideWhenValue = $elementToShow.data('hide-when-value');
			var showValueAry = [];
			var hideValueAry = [];
			var listenToInput = $elementToShow.data('input-toggle');
			
            listenToInput = listenToInput == 'mrc_action_mode' ? 'action_mode' : listenToInput;
			showValueAry = !!showWhenValue ? showWhenValue.toString().split(',') : showValueAry;
			hideValueAry = !!hideWhenValue ? hideWhenValue.toString().split(',') : hideValueAry;
            var contextSelector = isGrid ? 'tr' : 'form';
			var $input = $elementToShow.closest(contextSelector).find(':input[name="' + listenToInput + '"]');

			$input.change(function (ev) {
				toggleInput($(this), showValueAry, hideValueAry, $elementToShow);

			});
			toggleInput($input, showValueAry, hideValueAry, $elementToShow);
		});
	}
	function toggleInput ($input, showValueAry, hideValueAry, $elementToShow) {
		var inputVal = $input.val();
		var hide = true;

        //handle action mode option
        if($input.attr('id') == 'action_mode' && inputVal.length > 0){
            let firstChar = inputVal.toLowerCase().substring(0,1);
            if(firstChar == 'a' || firstChar == 'c'){
                inputVal = 'add';
            }else if(firstChar == 'u'){
                inputVal = 'update';
            }else if(firstChar == 'v'){
                inputVal = 'view';
            }else if(firstChar == 'd'){
                inputVal = 'delete';
            }
        }

		inputVal = checkForCheckbox($input, inputVal);
		hideOrShowInput(showValueAry, inputVal, $elementToShow, hide);

		hide = false;

		hideOrShowInput(hideValueAry, inputVal, $elementToShow, hide);
	}
     function toggleBootstrapRadios($html){
          let cls = '.radio-button-container';
          let $radioContainers = $html ? $html.find(cls) : jQuery(cls);
          
          $radioContainers.find('input[type="radio"]:checked').closest('label').addClass('active');

     }
	function checkForCheckbox ($input, inputValue) {
		var type = $input.prop('type');

		if (type === 'checkbox') return $input.prop('checked') ? inputValue : '';
		if (type === 'radio') {
           if(!jQuery('input:radio[name="' + $input.attr('name') + '"]').is(":checked")){
                return 'NONE_SELECTED';
            }
            return jQuery('input[name="' + $input.attr('name') + '"]:checked').val();
        }
		return inputValue;
	}

	function hideOrShowInput (valueAry, inputVal, $elementToShow, hide) {
		if (valueAry.length === 0) return;

		for (var i in valueAry) {
            let val = valueAry[i];
            //2021-08-11 - if value is longer than 1 char, trim it to remove spaces
            if(val.length > 1) val = val.trim();

			if (val === 'NOT_BLANK' && inputVal !== '') hide = !hide;
			if (val === 'BLANK' && inputVal === '') hide = !hide;
			if (val === 'NONE_SELECTED' && typeof inputVal === 'undefined') hide = !hide;
			if (val === inputVal) hide = !hide;
		}

		if (hide) {
			$elementToShow.hide();
		} else {
			$elementToShow.show();
		}
	}

	// @ts-ignore
	if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
		// @ts-ignore
		window.setFormToggleEvents = setFormToggleEvents;
	}

	return {
		FILE_INPUT_SELECTOR: FILE_INPUT_SELECTOR,
		setFormToggleEvents: setFormToggleEvents,
		updateFileText: updateFileText
	};
}(window, jQuery));
