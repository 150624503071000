/**
 * All selection dialog related code
 */
var selectionDialog = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function setRelationshipDropdown ($dropdownMenu, relationship) {
		var $inputGroup = $dropdownMenu.parent('.input-group-btn');
		var inputName = $dropdownMenu.data('rls_field');
		var $hiddenInput = $inputGroup.find(':input[name="rls_' + inputName + '"]');
		var $displayButton = $inputGroup.find('.display-rls');
          
		if (relationship === '') return;

		$hiddenInput.val(relationship);
		$displayButton.html(relationship + '&nbsp;');
	}

	function getRelationshipFromInput (inputIndex, input) {
		var $input = jQuery(input);
		var relationship = $input.val();
		var $dropdownMenu = $input.parent('.input-group-btn').find('.dropdown-menu');

		setRelationshipDropdown($dropdownMenu, relationship);
	}

	function initializeSelectionDialog (formIndex, form) {

		var $form = jQuery(form);
		var $relationshipInputs = $form.find('.hidden-rls');

		$relationshipInputs.each(getRelationshipFromInput);
	}
     
	function initializeAllSelectionDialogs ($html) {       
		var $forms = ($html ? $html.find('form,[data-program]') : jQuery('form,[data-program]'));
		$forms.each(initializeSelectionDialog);
	}

	/* global submitViaAjax */
	function submitSelectionDialog (ev) {
		var $okButton = jQuery(ev.currentTarget);
          var formInfo = formHandler.getForm(ev);
		var isDash = $okButton.parents('.tab').length > 0;
		var isBootstrap = $okButton.closest('.modal').length > 0;
		var $dialog;
		var id;

		if (isDash) {
			$dialog = $okButton.parents('.selection_dialog').eq(0);
			id = $dialog.attr('data-id');

			jQuery('form[data-id="' + id + '"]').append($dialog);
		}
		if(!isBootstrap){
			doSelectionSubmit($okButton,formInfo);
			return;
		}
		$okButton.closest('.modal').on('hidden.bs.modal', function(){
			doSelectionSubmit($okButton,formInfo);
		});
		$okButton.closest('.modal').modal('hide');
		
	}
	function doSelectionSubmit($okButton, formInfo){
          
		$okButton.attr('clicked', 'true');

          if(!formInfo.isForm){
               formHandler.doSubmit($okButton, 'btn_search');
               return;
          }

          var $form = formInfo.$form;

		if ($form.find('input[name="ajax_form"][value="1"],input[name="ajax_form"][value="2"]').length > 0) {
			// @ts-ignore
			submitViaAjax($form[0]);

		} else {
			$form.append('<input name="btn_search" value="1" type="hidden" />');

			jQuery('input[name="btn_search"]').val(1);

			// @ts-ignore
			$form.submit();
		}
	}
	function handleRelationshipChange (ev) {
		var $relationshipLink = jQuery(ev.currentTarget);
		var relationship = $relationshipLink.data('rls');

		setRelationshipDropdown($relationshipLink.parent('.dropdown-menu'), relationship);
	}
	function openSelectionDialog (e){
          var formInfo = formHandler.getForm(e);
          if(formInfo.isForm){
               formInfo.$form.find('.selections-modal').modal('show');
               return;
          }
		formInfo.$form.filter('.selections-modal').modal('show');
	}
	jQuery(function () {
		jQuery('body').on('click', '.ok_button', submitSelectionDialog)
		.on('click','.open-selections-modal', openSelectionDialog)
			.on('click', '.filter-relationship .dropdown-item', handleRelationshipChange);
	});

	return {
		initializeAllSelectionDialogs: initializeAllSelectionDialogs
	};
}(window, jQuery));
