/**
 * Form Validation
 * @author Duffey
 */
var mrcValidation = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';

	var NEEDS_VALIDATION = 'needs-validation';
	var NO_VALIDATION = 'no-validation';

	function displayErrorMessageAtTopOfPage (validator) {
		var HIDDEN_CLASS = 'd-none';
		var $form = $(validator.currentForm);
		var $mainContent = $form.parents('.ajax_content');
          var lib = $form.find('input[name=userLib]').val();
          var locale = $form.find('input[name=localeStr]').val();
		var $errorDiv = $mainContent.find('.errortext1');
		var errorCount = validator.errorList.length;
		var isSetByJS = $errorDiv.data('from-js');
		var message = 'Please fix the ' + errorCount + (errorCount === 1 ? ' error' : ' errors') + ' below';

		if (!isSetByJS && errorCount === 0) return;

          var callback = function(dataMessage){
               $errorDiv.text(errorCount ? dataMessage : '').toggleClass(HIDDEN_CLASS, !errorCount).data('from-js', true);
          }

          if(!!locale && locale.length > 0){
               mrcJSUtil.translateText(message, lib, callback);
          }
          else{
               callback(message);
          }
		
        
	}

	function defaultErrorHandler (errorMap, errorList) {
		displayErrorMessageAtTopOfPage(this);

		this.defaultShowErrors();
	}

	function defaultInvalidSubmitHandler (ev, validator) {
		displayErrorMessageAtTopOfPage(validator);
	}

	function defaultErrorPlacement ($error, $element) {
		var $form = $element.parents('form');
		var isErrorsOnly = $form.hasClass(NO_VALIDATION) &&  $form.find(':input[required="required"]').length == 0
          $error.attr('role', 'alert');
		$error.addClass('invalid-feedback');

		if (isErrorsOnly) $error.removeClass('is-invalid');

		if ($element.prop('type') === 'checkbox') {
			$error.insertAfter($element.parent('label'));
		}else if ($element.prop('type') === 'radio') {
               let $parent = $element.parent('label').parent();
			$error.insertAfter($parent);
		} else {
			$error.insertAfter($element);
		}
	}

	/* global handleAjaxForms */
	function defaultSubmitHandler (form, ev) {
		// @ts-ignore
		var needsSubmit = handleAjaxForms(ev); // ajax_forms.js, line 11

		if (needsSubmit){
           // try{
                form.submit();
          //  }catch(err){}
        } 
	}

	function setupFormValidation ($form) {
        var $errors = $form.find('.fieldErrors');
        var noFocus = $form.hasClass('no-focus');
		var formName = $form.attr('name');
        var validationObj = window[formName + '_Validation'] || {};
        
        if(noFocus){
            validationObj.focusInvalid = false;
        }

		// @ts-ignore
		var validator = $form.validate(validationObj);
		var fieldErrors;

		try {
			fieldErrors = JSON.parse($errors.val().toString());
		} catch (err) {
			console.warn('Invalid JSON found', $errors.val());
		}

		$form.data('validator', validator);

		if (fieldErrors) validator.showErrors(fieldErrors);
	}

	function showErrorsOnly ($form) {
        var $errors = $form.find('.fieldErrors');
        var noFocus = $form.hasClass('no-focus');
        var options = {
            unhighlight: function (el, errorClass, validClass) { /* NOOP */ }
        }
        if(noFocus){
            options.focusInvalid = false;
        }
		// @ts-ignore
		var validator = $form.validate(options);
		var fieldErrors;

		try {
			fieldErrors = JSON.parse($errors.val().toString());
		} catch (err) {
			console.warn('Invalid JSON found', $errors.val());
		}

		if (fieldErrors) validator.showErrors(fieldErrors);

		validator.destroy();
	}

	function startFormValidation ($html) {
		var formSelector = 'form';
		var $forms = ($html ? $html.find(formSelector) : $(formSelector));
		var defaultProperties = {
			errorClass: 'is-invalid',
			errorPlacement: defaultErrorPlacement,
			ignoreTitle: true,
			invalidHandler: defaultInvalidSubmitHandler,
			showErrors: defaultErrorHandler,
			submitHandler: defaultSubmitHandler,
			validClass: 'is-valid2' // BD: Changed per TW to not apply is-valid bootstrap class to input
		};

		// @ts-ignore
		$.validator.setDefaults(defaultProperties);

		$forms.each(function filterForms (i, el) {
			var $form = $(el);
			var doValidation = $form.hasClass(NEEDS_VALIDATION);
			var skipValidation = $form.hasClass(NO_VALIDATION);
			var hasErrorInput = $form.find('input[name="fieldErrors"]').length > 0;
			var hasRequired = $form.find(':input[required="required"]').length > 0;

			if (doValidation || hasRequired || (!skipValidation && hasErrorInput)) {
				setupFormValidation($form);
			} else if (hasErrorInput) {
				showErrorsOnly($form);
			}
		});
	}

	/* global loadjs */
	function initFormValidation ($html) {
		var formSelector = 'form.' + NEEDS_VALIDATION + ', input[name="fieldErrors"]';
		var $forms = ($html ? $html.find(formSelector) : $(formSelector));

		if ($forms.length === 0) return;

		// @ts-ignore
		if (!loadjs.isDefined('jquery-validation')) {
			// @ts-ignore
			loadjs(['../assets/vendor/jquery-validation/jquery.validate.min.js', '../assets/vendor/jquery-validation/additional-methods.js'], 'jquery-validation', { async: false });
		}

		// @ts-ignore
		loadjs.ready('jquery-validation', function jQueryValidationHasLoaded () {
			startFormValidation($html);
        });
        
	}

	return {
		defaultErrorHandler: defaultErrorHandler,
		defaultErrorPlacement: defaultErrorPlacement,
		defaultInvalidSubmitHandler: defaultInvalidSubmitHandler,
		defaultSubmitHandler: defaultSubmitHandler,
        initFormValidation: initFormValidation,
        setupFormValidation:setupFormValidation
	};
}(window, jQuery));
