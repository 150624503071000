/**
 *Multi Level Header Nav Menu Control
 */
const menuHeaderRight = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	jQuery('.mrc-header-nav .dropdown-menu a.dropdown-toggle').on('click', function (ev) {
		if (!jQuery(this).next().hasClass('show')) {
			jQuery(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
		}

		var $subMenu = jQuery(this).next('.dropdown-menu');

		$subMenu.toggleClass('show');

		var left = '-' + ($subMenu.width() + 3) + 'px';

		$subMenu.css('left', left);

		return false;
	});

	jQuery('.mrc-header-nav .dropdown > a.dropdown-toggle').on('click', function (ev) {
		var $subMenu = jQuery(this).next('.dropdown-menu');
		var left = '-' + ($subMenu.width() > 110 ? $subMenu.width() - 110 : $subMenu.width()) + 'px';

		$subMenu.css('left', left);
	});

	return {};
}(window, jQuery));
