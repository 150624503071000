var mrcChartFunctions = (function (window) { // eslint-disable-line no-unused-vars
	'use strict';
     function formatLargeValue(value, formatLargeValues = true){

          if(formatLargeValues == false) {
               try{
                    if(typeof value === 'string' && value.indexOf('.') >= 0){
                         value = parseFloat(value);
                    }else if(typeof value === 'string' && value.indexOf('.') < 0){
                         value = parseInt(value);
                   }
               }catch(err){ console.log('catcherr', err);}
               return value.toLocaleString('en-US');
          }
               

          var fixed = 0;
          if(value >= 1000000000){
               fixed  = value%1000000000 == 0 ? 0 : 1;
               return (value/1000000000).toFixed(fixed) + 'B';
          }else if(value >= 1000000){
               fixed  = value%1000000 == 0 ? 0 : 1;
               return (value/1000000).toFixed(fixed) + 'M';
          }else if(value >= 1000){
               fixed  = value%1000 == 0 ? 0 : 1;
               return (value/1000).toFixed(fixed) + 'K';
          }
          return value;
     
     }
     function replaceLabelValues(data, str, formatLargeValues = true){
          str = str.replace('{value}', formatLargeValue(data.value, formatLargeValues));
          str = str.replace('{name}', data.name);

          return str;
     }
     window.formatLargeValue = formatLargeValue;
     window.replaceLabelValues = replaceLabelValues;
	
	return {
          
     };
}(window));


