

 var multiSelectInputs = (function (window, jQuery) { // eslint-disable-line no-unused-vars
     'use strict';
     function initDropdownComponent(){
          Vue.component('dropdown-list', {
               template: `
                   <div>
                       <select  :name="isMultiSelect ? 'select-' + name  : name" :ref="isMultiSelect ? 'select-' + name  : name" v-model="selectedItem" @change="addItem" :disabled="disabled" class="form-control cancel-asm" id="dropdown">
                           <option v-if="loading" disabled value="">Loading Items...</option>
                           <option v-if="!loading" disabled value="">Select an item</option>
                           <option v-if="!loading" v-for="item in availableItems" :value="item.value" :key="item.value">
                               {{ item.description }}
                           </option>
                       </select>
          
                       <!-- Display selected items -->
                       <ul v-if="isMultiSelect" class="selected-items">
                           <li v-for="item in selectedItems" :key="item.value" class="list-item">
                               {{ item.description }}
                               <span class="delete-icon fa fa-times" @click="removeItem(item)"></span>
                           </li>
                       </ul>
          
                       <!-- Hidden multi-select for form submission -->
                       <select  v-if="isMultiSelect" :name="name" :ref="name" multiple  class="d-none cancel-asm">
                           <option v-for="item in selectedItems" :value="handleSpaces(item.value)" selected="selected" :key="item.value">
                           {{ item.description }}
                           </option>
                       </select>
                   </div>
               `,
               props:['label','url','name','dependencies', 'isList'],
               inheritAttrs: false,
               data() {
                   return {
                       selectedItem: "",
                       availableItems: [
                           { value: 'item1', label: 'Item 1', description: 'Description for Item 1' },
                           { value: 'item2', label: 'Item 2', description: 'Description for Item 2' },
                           { value: 'item3', label: 'Item 3', description: 'Description for Item 3' },
                       ],
                       selectedItems: [],
                       disabled:false,
                       loading:false
                   };
               },
               computed:{
                    isMultiSelect(){
                         return !!this.isList || this.isList === "true";
                    },
                    disabled(){
                         let formData = jQuery(':input[name=' + this.name + ']').closest('form').serializeArray();
                         if(!!this.dependencies && this.dependencies.length){
                              let requiredIsSelected = false;
                              let isRequired = false;
                              let doDisable = false;
                              this.dependencies.some(function(item){
                                   let name = item.field.split("=")[1];
                                   if(item.required){
                                        isRequired = true;
                                        let formFieldObjAry = formData.filter(field => field.name === name);

                                        if(!formFieldObjAry.length || !formFieldObjAry[0].value){
                                             requiredIsSelected = false;
                                        }
                                        console.log(requiredIsSelected , isRequired);
                                        if(isRequired && !requiredIsSelected) doDisable =  true;
                                   }
                              });
                              return doDisable;
                         }
          
                         return false;
                    }
               },
               methods: {
                   addItem() {alert(222);
                         if (!this.selectedItem || !this.isMultiSelect )return;
                    
                       // Find the selected item from the available items
                       const item = this.availableItems.find(i => i.value === this.selectedItem);
                       if (item) {
                           // Move item to selected list
                           this.selectedItems.push(item);
          
                           // Remove item from available items
                           this.availableItems = this.availableItems.filter(i => i.value !== this.selectedItem);
                       }
          
                       // Reset the dropdown
                       this.selectedItem = "";
                       this.$nextTick(() => {
                         this.triggerChange();
                       });
                      
          
                   },
                   removeItem(item) {
                       // Remove the item from selected list
                       this.selectedItems = this.selectedItems.filter(i => i.value !== item.value);
          
                       // Add it back to the available items
                       this.availableItems.splice(item.index, 0, item);
                       this.$nextTick(() => {
                         this.triggerChange();
                       });
                   },
                   triggerChange(){
                         const selectElement = this.$refs[this.name]; // Get the select element
                    
                         // Trigger the change event
                         const event = new Event('change', { bubbles: true });
                         selectElement.dispatchEvent(event);alert(123);
                   },
                   loadItems(){
                         if(!this.checkDependencies()) return;
                         let url = this.getUrl();
                         this.loading = true;
                         jQuery.getJSON(url).done(function(data){
                              this.loading = false;
                              if(!!data){
                                   data = data.filter((item, index, self) =>
                                        index === self.findIndex(obj => obj.value === item.value)
                                        ).map(function(item,i){
                                             return {
                                                  value: item.value,
                                                  description: item.text,
                                                  index:i
                                             }
                                        });
                              }
                              this.availableItems = data;
                         }.bind(this));
                   },
                   checkDependencies(){
                         let formData = jQuery(':input[name=' + this.name + ']').closest('form').serializeArray();
                         if(!!this.dependencies && this.dependencies.length){
                              let requiredIsSelected = true;
                              let isRequired = false;
                              let doDisable = false;  
                              let atLeast1Required = false;
                              let atLeast1Selected = false
                              this.dependencies.forEach(function(item){
                                   let name = item.field.split("=")[1];
                                   let formFieldObjAry = formData.filter(field => field.name === name);
                                   console.log(formData);
                                   if(item.required == 1){
                                        isRequired = true;
                                       
                                        if(!formFieldObjAry.length || !formFieldObjAry[0].value){
                                             requiredIsSelected = false;
                                        }
                                        if(isRequired && !requiredIsSelected) {
                                             doDisable =  true;
                                        }
                                   }else if(item.required == 2){
                                        atLeast1Required = true;
          
                                        if(formFieldObjAry.length){
                                             atLeast1Selected = true;
                                        }
                                   }
                              });
                              if(doDisable){
                                   this.disabled = true;
                                   return false;
                              }
                              if(atLeast1Required && !atLeast1Selected){
                                   this.disabled = true;
                                   return false;
                              }
                         }
          
                         this.disabled = false;
                         return true;
                   },
                   handleSpaces(val){
                         return val.replaceAll(' ','_%_');
                   },
                   getUrl(){
                         // Collect form data
                         let formData = jQuery(':input[name=' + this.name + ']').closest('form').serializeArray();
                         let rlsParms = '';
                         let params = '';
                         // Filter data to include only the specified input names
                          if(!!this.dependencies){
                              let datAry = [];
                              this.dependencies.forEach(function(item){
                                   let pair = item.field.split("=");
                                   let lookupField = pair[0];
                                   let formField = pair[1];
          
                                   let formFieldObjAry = formData.filter(field => field.name === formField);
                                   if(!!formFieldObjAry){
                                        let vals = [];
                                        formFieldObjAry.forEach(function(formFieldObj){
                                             const match = formFieldObj.value.match(/!(.)!$/); // Captures a single character between the ! symbols at the end of the string

                                             if (!match) {
                                                  vals.push(formFieldObj.value.trimEnd());
                                             }else{
                                                  const somechar = match[1]; // Extract the character between !
                                                  const strippedString = formFieldObj.value.slice(0, -match[0].length); // Remove the !somechar! from the string
                                                  const splitArray = strippedString.split(somechar);
                                                  splitArray.forEach(function(split){
                                                       vals.push(split.trimEnd().replaceAll(' ','_%_'));
                                                  });
                                             }
                                        });

                                        datAry.push({
                                             name: lookupField,
                                             value: vals.join(' ')
                                        });
                                   }
                                   rlsParms += `&rls_${encodeURIComponent(lookupField)}=LS`;
                              });
          
                              params = datAry.map(field => {
                                   return `val_${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`;
                              }).join('&');
                         }
                         let fullUrl = `${this.url}?max_rows=10000&reqtype=27&${params}${rlsParms}`;
          
                         return fullUrl
                   }
               },
               mounted(){
                    let formData = jQuery(':input[name=' + this.name + ']').closest('form').serializeArray();
                    console.log(formData);
                    if(!!this.dependencies && this.dependencies.length){
                         let self = this;
                         let isRequired = false;
                         let atLeast1Required = false;
                         let atLeast1Selected = false
                         this.dependencies.forEach(function(item){
                              let name = item.field.split("=")[1];
                              var selector = ':input[name='+name + ']'
                              jQuery('body').off('change',selector,self.loadItems);
                              jQuery('body').on('change',selector,self.loadItems);
                              let formFieldObjAry = formData.filter(field => field.name === name);
                              console.log(name);
                              console.log(formFieldObjAry);
                              if(item.required == 1){
                                   if(!formFieldObjAry.length || formFieldObjAry[0].value == ''){
                                        isRequired = true;
                                   }
                              }else if(item.required == 2){
                                   atLeast1Required = true;
                                   if(formFieldObjAry.length && formFieldObjAry[0].value != ''){
                                        atLeast1Selected = true;
                                   }
                              }
                         });
                         console.log(isRequired,atLeast1Required,atLeast1Selected);
                         if(isRequired){
                              this.disabled = true;
                              return;
                         } 
                         if(atLeast1Required && !atLeast1Selected){
                              this.disabled = true;
                              return;
                         } 
                    }
                    this.disabled = false;
                    this.loadItems();
               }
           });
     }
     function initVueMultiSelects(){
          jQuery.noConflict();
          jQuery(function(){
              jQuery('dropdown-list').each(function(){
                   let el = this;
                   new Vue({
                        el: '#' + jQuery(el).parent().attr('id')
                   });
              });
          });
     }
    
     function initMultiSelects () {

            if (!loadjs.isDefined('vue')) {

                 loadjs(['../assets/vendor/vue/vue.js'], 'vue', { async: false });
            }
  
            loadjs.ready('vue', function fullCalendarHasLoaded () {
               initDropdownComponent();
               initVueMultiSelects();
            });
       }
       if( jQuery('dropdown-list').length){
              
               initMultiSelects();
       }
      return {
           
      };
 }(window, jQuery));

