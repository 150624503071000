/**
 * Report prompt UI handling for email
 * specific inputs
 * Web 2.0 and Bootstrap Templates
 */
var reportPromptEmail = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	// Set email textarea rows
	function initReportPromptEmail () {
		var hasEmail = jQuery('textarea#email_to');

		if (hasEmail.length > 0) {
			jQuery('#email_to, #email_cc').keyup(function (ev) {
				setTextareaRows(jQuery(this));
			});

			jQuery('#email_to, #email_cc').each(function (i, el) {
				setTextareaRows(jQuery(el));
			});
		}
	}

	function setTextareaRows (textarea) {
		var txt = jQuery(textarea).val().toString();
		var lines = txt.split('\n');
		var rows = lines.length;
		var colsp = jQuery(textarea).attr('cols');
		var cols = parseInt(colsp);

		for (var i = 0; i < lines.length; i++) {
			var len0 = lines[i].length / cols;
			var len = Math.round(len0);

			rows = rows + len;
		}

		jQuery(textarea).attr('rows', rows);
	}

	// @ts-ignore
	if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
		// @ts-ignore
		window.initReportPromptEmail = initReportPromptEmail;
	}

	return {
		initReportPromptEmail: initReportPromptEmail
	};
}(window, jQuery));
