/**
 * Constants for m-Power apps starting with version 1
 */
var MRCCONSTANTS = { // eslint-disable-line no-unused-vars
	VERSION: 1,
	MRC_RADIO_CHECKED: 'fa-check-circle-o',
	MRC_RADIO_UNCHECKED: 'fa-circle-thin',
	MRC_CHECKBOX_CHECKED: 'fa-check-square-o',
	MRC_CHECKBOX_UNCHECKED: 'fa-square-o',
};
