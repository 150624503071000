/**
 * Maintainer Detail View page (responsive view)
 * Handles UI events for the maintainer detail page
 * Bootstrap Templates
 */
const maintainerDetailView = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	/**
	 * On Load, setup events
	 */
	function setDetailViewEvents ($html) {
		showFirstDetailItem($html);
	}

	/**
	 * Hide and show the first  item detail
	 */
	/* global mrcJSUtil */
	function showFirstDetailItem ($html) {
        if($html.find('.list-view').length == 0) return;
		var $li = $html.find('.list-view .list-group-item[data-active-default=true]'),
				url = $li.data('url');

		if (checkIsSmallScreen()) return;

		$li.addClass('active');

		var isAddMode = checkInitialMode();
		if (isAddMode) {
			return;
		}
		url = mrcJSUtil.uniqueUrl(url);
		url = mrcJSUtil.addParm(url, 'async', 1);

		var $detailItem = $li.next();

		loadDetailItemForm($detailItem, url);
	}

	function checkInitialMode () {
		var $li = jQuery('.list-view .list-group-item').eq(0);
		var mode = $li.closest('.ajax_content').find('form').find('#initial_mode').val();
		if (mode == 'add') {
			if (checkIsSmallScreen()) return;
			showDetailItem($li);
			return true;
		}
		return false;
	}
	/**
	 * Show the clicked detail, hide the others
	 */
	function detailItemEvent (ev) {
		var $currentEl = jQuery(ev.currentTarget);
		showDetailItem($currentEl);
	}

	function showDetailItem ($currentEl) {
		var url = $currentEl.data('url');
		var isSmallScreen = checkIsSmallScreen();

		if ($currentEl.hasClass('active')) {
			if (isSmallScreen) {
				$currentEl.removeClass('active');
				$currentEl.next().hide()
					.find('.load-single-row').html('');
			}

			return;
		}

		$currentEl.addClass('active')
			.siblings().removeClass('active');

		url = mrcJSUtil.uniqueUrl(url);
		url = mrcJSUtil.addParm(url, 'async', 1);

		// Hide the item
		jQuery('.list-group-item-detail-view').hide()
			.find('.load-single-row').html('');

		// Remove any existing item from the detail view right pane
		jQuery('.detail-view').html('');

		var $detailItem = $currentEl.next();

		loadDetailItemForm($detailItem, url);
	}

	function loadDetailItemForm ($detailItem, url) {
		var isSmallScreen = checkIsSmallScreen(),
				$itemToLoad = $detailItem;

		if (!isSmallScreen) {
			var $detailItemClone = $detailItem.clone();

			jQuery('.detail-view').html($detailItemClone);

			$itemToLoad = $detailItemClone;
		}

		$itemToLoad.show().find('.load-single-row').load(url, function () {
			var needsRefresh = checkIfLoginPage(this);
			if(needsRefresh) {
				window.location.reload();
				return;
			}
			jQuery(':input[name="btn_back"]').remove();

			if (jQuery(this).find('#action_mode').val() === 'VVK') {
				jQuery(':input[name="btn_accept"]').remove();
			}
			jQuery(this).trigger('mrc:detailloaded');
			jQuery(this).trigger('mrc:contentLoaded');
		});
	}
	function checkIfLoginPage (content) {
		var $form = jQuery(content).find('form');
		if($form.length > 0 && $form.attr('action').indexOf('.Login') >= 0 ) {
			return true;
		}
		return false;
	}
	function checkMaintenanceAction (e) {
		if (!checkIsSmallScreen()) return;
		e.preventDefault();
		var $a = jQuery(e.currentTarget),
				href = $a.attr('href');
		if (href.indexOf('work_with=1')) {
			href = href.replace('work_with=1', 'work_with=2');
			window.location.href = href;
		}
	}
	/**
	 * If this caret is visible, then its a small screen
	 */
	function checkIsSmallScreen () {
		return !jQuery('.detail-view').first().is(':visible');
	}
	setDetailViewEvents (jQuery(document));
	jQuery('body').on('click', '.list-view .list-group-item', detailItemEvent);
	jQuery('body').on('click', '.mrc-action', checkMaintenanceAction);
	return {
		setDetailViewEvents: setDetailViewEvents,
		checkIsSmallScreen:checkIsSmallScreen
	};
}(window, jQuery));
