/**
 * Register bootstrap component js for things
 * like tooltips
 * Bootstrap Templates
 */
const mrcBootstrapJS = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function setBootstrapJs () {
		jQuery('[data-toggle="tooltip"], .mrc-tooltip').tooltip();
	}

	return {
		setBootstrapJs: setBootstrapJs
	};
}(window, jQuery));
