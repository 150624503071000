var mrcStatWidgets = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';
     var CLASS_PCT_DOUGHNUT = 'mrc-percentage-doughnut',CLASS_PCT_ICON_DOUGHNUT = 'mrc-percentage-icon-doughnut',CLASS_PCT_PROGRESS = 'mrc-percentage-progress',CLASS_PCT_GAUGE= 'mrc-percentage-gauge';
     var CLASS_STAT_WIDGET = 'mrc-stat-widget-card', CLASS_STAT_VALUE = 'mrc-stat-value',  CLASS_ICON = 'counter-icon';
     var STAT_TYPES = [CLASS_PCT_DOUGHNUT,CLASS_PCT_PROGRESS,CLASS_PCT_ICON_DOUGHNUT, CLASS_PCT_GAUGE];
     var doughnutRadius = 46;
	function render(container){
          container = !!container ? container : 'body';
          if (!loadjs.isDefined('d3')) {
			// @ts-ignore
			loadjs(['../assets/vendor/d3/d3.min.js'], 'd3', { async: false });
		}

          /**Once fusion charts is loaded, create the chart */
		// @ts-ignore
		loadjs.ready('d3', function d3Loaded () {
               jQuery(container).find('.' + CLASS_STAT_WIDGET).each(function(){
                    let $el = jQuery(this);
                    let val = $el.attr('data-stat-field');

                    STAT_TYPES.forEach(function(type){
                         if($el.find('.' + type).length){
                              try{
                                   let $svgContainer = $el.find('.' + type);
                                   let icon = getIcon($el);
                                   $svgContainer.html('');
                                   let element =  $svgContainer.get(0);
                                   if(type == CLASS_PCT_DOUGHNUT){
                                        let barColor = $el.attr('data-color');
                                        let baseColor = $el.attr('data-base-color') || '#eee';
                                        doughnutPercentage(element, doughnutRadius, 3, baseColor, barColor,(parseInt(val,10) / 100));
                                   }else if(type == CLASS_PCT_ICON_DOUGHNUT){
                                        let barColor = $el.attr('data-color');
                                        let baseColor = $el.attr('data-base-color')  || '#eee';
                                        if(!!icon) icon = 'fa ' + icon;
                                        doughnutIconPercentage(element, doughnutRadius, 2.5, baseColor, barColor,(parseInt(val,10) / 100), icon);
                                        setTextvalue(element,val);
                                   }else if(type == CLASS_PCT_PROGRESS){
                                        progressBarPercentage(element,val);
                                   }else if(type == CLASS_PCT_GAUGE){
                                        progressGauge;
                                   }
                                   $el.find('.' + CLASS_STAT_VALUE).show();
                                  
                              }catch(err){
                                   console.log(err)
                              }
                             
                         }
                    });

                   
               });
          });
     }
     function progressGauge(element, radius, border, backgroundColor, foregroundColor, end) {
     }
     function progressBarPercentage(element, value) {
          jQuery(element).css('width', value + '%');
          setTextvalue(element,value);
      }
      function setTextvalue(element,value){
          jQuery(element).closest('.' + CLASS_STAT_WIDGET).find('.' + CLASS_STAT_VALUE).text(value + '%');
      }
      function getIcon($el){
          let icon = $el.find('.' + CLASS_ICON).attr('class');
          if(!!icon){
               let iconClass = icon.split(' ').filter(function(item){
                    return item.indexOf('fa-') > -1;
               });
               return iconClass[0] || '';
          }
          return '';
      }
      function doughnutIconPercentage(element, radius, border, backgroundColor, foregroundColor, end, iconClass) {
          if (typeof d3 == 'undefined') {
              console.warn('Warning - d3.min.js is not loaded.');
              return;
          }
  
          if(element) {
              var d3Container = d3.select(element),
                  startPercent = 0,
                  iconSize = 32,
                  endPercent = end,
                  twoPi = Math.PI * 2,
                  formatPercent = d3.format('.0%'),
                  boxSize = radius * 2;
  
              // Values count
              var count = Math.abs((endPercent - startPercent) / 0.01);
  
              // Values step
              var step = endPercent < startPercent ? -0.01 : 0.01;
  
              var container = d3Container.append('svg');
  
              // Add SVG group
              var svg = container
                  .attr('width', boxSize)
                  .attr('height', boxSize)
                  .append('g')
                      .attr('transform', 'translate(' + (boxSize / 2) + ',' + (boxSize / 2) + ')');
  
              var arc = d3.svg.arc()
                  .startAngle(0)
                  .innerRadius(radius)
                  .outerRadius(radius - border)
                  .cornerRadius(20);
  
  
              svg.append('path')
                  .attr('class', 'd3-progress-background')
                  .attr('d', arc.endAngle(twoPi))
                  .style('fill', backgroundColor);
  
              var foreground = svg.append('path')
                  .attr('class', 'd3-progress-foreground')
                  .attr('filter', 'url(#blur)')
                  .style({
                      'fill': foregroundColor,
                      'stroke': foregroundColor
                  });
  
              var front = svg.append('path')
                  .attr('class', 'd3-progress-front')
                  .style({
                      'fill': foregroundColor,
                      'fill-opacity': 1
                  });
  
              var numberText = d3.select('.progress-percentage')
                      .attr('class', 'pt-1 mt-2 mb-1');
  
              d3.select(element)
                  .append("i")
                      .attr("class", iconClass + " counter-icon")
                      .style({
                          'color': foregroundColor,
                          'top': ((boxSize - iconSize) / 2) + 'px'
                      });
  
  
              function updateProgress(progress) {
                  foreground.attr('d', arc.endAngle(twoPi * progress));
                  front.attr('d', arc.endAngle(twoPi * progress));
                  numberText.text(formatPercent(progress));
              }
  
              updateProgress(endPercent);
            /*  (function loops() {
                  updateProgress(progress);
                  if (count > 0) {
                      count--;
                      progress += step;
                      setTimeout(loops, 10);
                  }
              })();*/
          }
      };
      function doughnutPercentage(element, radius, border, backgroundColor, foregroundColor, end) {
          if (typeof d3 == 'undefined') {
              console.warn('Warning - d3.min.js is not loaded.');
              return;
          }
          if(element) {
  
              var d3Container = d3.select(element),
                  startPercent = 0,
                  fontSize = 22,
                  endPercent = end,
                  twoPi = Math.PI * 2,
                  formatPercent = d3.format('.0%'),
                  boxSize = radius * 2;
  
              var count = Math.abs((endPercent - startPercent) / 0.01);
  
              var step = endPercent < startPercent ? -0.01 : 0.01;
  
              var container = d3Container.append('svg');
  
              var svg = container
                  .attr('width', boxSize)
                  .attr('height', boxSize)
                  .append('g')
                      .attr('transform', 'translate(' + radius + ',' + radius + ')');
  
              var arc = d3.svg.arc()
                  .startAngle(0)
                  .innerRadius(radius)
                  .outerRadius(radius - border)
                  .cornerRadius(20);
  
              svg.append('path')
                  .attr('class', 'd3-progress-background')
                  .attr('d', arc.endAngle(twoPi))
                  .style('fill', backgroundColor);
  
              var foreground = svg.append('path')
                  .attr('class', 'd3-progress-foreground')
                  .attr('filter', 'url(#blur)')
                  .style({
                      'fill': foregroundColor,
                      'stroke': foregroundColor
                  });
  
              var front = svg.append('path')
                  .attr('class', 'd3-progress-front')
                  .style({
                      'fill': foregroundColor,
                      'fill-opacity': 1
                  });
  
              var numberText = svg
                  .append('text')
                      .attr('dx', 0)
                      .attr('dy', (fontSize / 2) - border)
                      .style({
                          'font-size': fontSize + 'px',
                          'line-height': 1,
                          'fill': foregroundColor,
                          'text-anchor': 'middle'
                      });
  
              function updateProgress(progress) {
                  foreground.attr('d', arc.endAngle(twoPi * progress));
                  front.attr('d', arc.endAngle(twoPi * progress));
                  numberText.text(formatPercent(progress));
              }
  
              updateProgress(endPercent);
            /*  (function loops() {
                  updateProgress(progress);
                  
                console.log( progress);
                console.log(count);
                  if (count > 0) {
                      count--;
                      progress += step;
                      setTimeout(loops, 10);
                  }
              })();*/
          }
      };
      render();
	return {
          render
     };
}(window, jQuery));
