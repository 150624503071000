/**
 * Creates a cascading drop down list dependent on multiple fields.
 * Accepts a definiition specification object like:
   {
		lookup:'DD.I00010s',
		applyTo: 'CITY',
		lookupField: 'CITY',
		dependencies: [
			{
				triggerRefresh: true, //the field that triggers the drop down to load
				field: 'COUNTY',
				lookupField: 'COUNTY'
			},
			{
				field: 'STATE',
				lookupField: 'STATE'
			}
		]
	}
 *
 * */
var ajaxMultiCascade = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	/**Initialize the multi field cascade for a given field, register change events**/
	function initMultiCascade (spec) {

		spec.dependencies.forEach (function (item) {
			var fieldname = item.field;

			jQuery('body').on('change', '#' + fieldname, function () {
				if(item.triggerRefresh == true) {
					refreshList (spec, true);
				}else{
					disableList (spec);
				}
			});
			jQuery('body').on('mrcajaxdropdownloaded', '#' + fieldname, function () {
				if(item.triggerRefresh == true) {
					refreshList (spec, false);
				}else{
					disableList (spec);
				}
			});
		});
		createSelectList(spec);
	}
	/**Convert text input into a select list**/
	function createSelectList (spec) {
		var initialValue = jQuery('#' + spec.applyTo).val();
		jQuery('#' + spec.applyTo).replaceWith('<select class="form-control" data-value="' + initialValue + '" disabled id="' + spec.applyTo + '" name="' + spec.applyTo + '"></select>');
		for(var i = 0; i < spec.dependencies.length; i++) {
			var  item = spec.dependencies[i];
			if(jQuery('#' + item.field).val() == '') {
				return;
			}
		}
		refreshList(spec, false);
	}
	/**Call the app to populate the drop down**/
	function refreshList (spec, clearSelection) {
		var url = spec.lookup + '?reqtype=5&slnk=1',
				initialValue = jQuery('#' + spec.applyTo).attr('data-value');

        var incompleteDependencies = false;
		spec.dependencies.forEach (function (item) {
            var val = jQuery('#' + item.field).val();

            if((val == '' || val == null) && item.allowBlank == false){
                incompleteDependencies = true;
            }

			url += '&val_' + item.lookupField + '=' + encodeURIComponent(val);
        });
        
        /**If some of the dependency values are blank - dont call the app URL */
        if(incompleteDependencies){
            checkBlankDependencies(spec);
            return;
        }

		url += '&x=' + Math.random();
		jQuery.get(url, function (data) {
			data = data.replace(/RXXX/g, spec.applyTo);

			jQuery('#' + spec.applyTo).replaceWith(data);

			if(typeof initialValue !== undefined) {
				if(clearSelection == false) {
					jQuery('#' + spec.applyTo).val(initialValue).attr('data-value', initialValue);
				}
			}
			jQuery('#' + spec.applyTo).trigger('mrcajaxdropdownloaded');
			checkBlankDependencies(spec);
		});
	}
	/**If any dependency is blank, disable the drop down**/
	function checkBlankDependencies (spec) {
		spec.dependencies.forEach (function (item) {
			if(jQuery('#' + item.field).val() == '') {
				disableList (spec);
			}
		});
	}
	function disableList (spec) {
		jQuery('#' + spec.applyTo).prop('disabled', true).val('');
	}
	return {
		initMultiCascade:initMultiCascade
	};
}(window, jQuery));
