/* global appInit */

/**
 * Includes all necessary code to render Bootstrap modals in m-Power
 */
const mrcModal = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';

	const DEFAULT_MODAL_CLASS = 'mrc-modal';

	function injectModalHTML (modalClass) {
		const modalHTML = '<div class="modal ' + modalClass + '" role="dialog" tabindex="-1">' +
						'<div class="modal-dialog" role="document">' +
							'<div class="modal-content">' +
								'<div class="modal-header">' +
									'<h5 class="modal-title">Modal title</h5>' +
									'<button aria-label="Close" class="close modal-close" type="button">' +
										'<span aria-hidden="true">&times;</span>' +
									'</button>' +
								'</div>' +
								'<div class="modal-body">' +
									'<p class="loading"><i class="fa fa-spinner fa-pulse"></i> Loading...</p>' +
									'<div class="app-content hidden">ERROR</div>' +
								'</div>' +
								'<div class="modal-footer"><button class="btn btn-secondary modal-close"  type="button">Close</button></div>' +
							'</div>' +
						'</div>' +
					'</div>';
		const $modal = $(modalHTML);

		$('body').append($modal);

		return $modal;
	}

	function copyModal ($modal) {
		const $newModal = $modal.clone(true);

		$('body').append($newModal);

		$newModal.draggable({ handle: '.modal-header' });

		return $newModal;
	}

	function calculateSize (size) {
		const newSize = parseInt(size, 10);

		if (size === 'auto' || isNaN(newSize)) return 'auto';

		return size;
	}

	function displayAppInModal (html, jqXHR, status) {
		const $modal = this.$modal;
		const $modalContent = $modal.find('.app-content');
		const modalWidth = $modal.data('width');
		let maxWidth;

		$modal.find('.loading').hide();
		$modalContent.html(html);
		handleMaintainerSubmitButtons($modalContent);
		$modalContent.show();
          formHandler.pageLoadIntializers($modalContent);
		appInit.initializePage($modalContent);

		if (modalWidth === 'auto') {
			maxWidth = $modal.find('.modal-body')[0].scrollWidth + 2;
			$modal.find('.modal-dialog').css('max-width', maxWidth);
		}

        $modal.modal('handleUpdate');
	}

	function displayErrorInModal (jqXHR, status, error) {
		const $modal = this.$modal;
		const $modalContent = $modal.find('.app-content');
		const errorMessage = status + ': ' + error;

		$modal.find('.loading').hide();
		$modalContent.html(errorMessage).show();
	}

	function appHasErrors ($div) {
		var $errorDiv = $div.find('.errortext1, .errortext1_center');
		var hasError = false;

		if ($errorDiv.length === 0) return false;

		$errorDiv.each(function (i, el) {
			var errorText = jQuery(el).text().trim();

			hasError = (errorText.length > 0);

			if (hasError) return false;
		});

		return hasError;
	}

	function getHoverRedirFromLinkTag (link) {
		var queryString = link.search;
		var hoverRedirect = '';
		var paramArray;
		var param;

		if (!queryString) {
			console.warn('Link has no query string', link.href);

			return hoverRedirect;
		}

		paramArray = queryString.split('?')[1].split('&');

		for (var i = paramArray.length; i--;) {
			param = paramArray[i];

			if (param.indexOf('hover_redir=') === 0) return decodeURIComponent(param.split('=')[1]);
		}

		console.warn('Link has no hover_redir parameter', queryString);

		return hoverRedirect;
	}

	function afterModalRefresh (ev) {
		var $modal = jQuery(ev.currentTarget);
		var $modalBody = $modal.find('.modal-body');
		var $modalContent = $modal.find('.app-content');
		var $originalLink = $($modal.data('link'));
		var $parentAjaxContent= $($modal.data('parentAjaxContent'));
		var $parentForm = $($modal.data('parentForm'));
          var submitOption = $originalLink.attr('modalsubmit');
          var formPost = $originalLink.attr('data-form-post');
		var wasSubmit = handleMaintainerSubmitButtons($modalContent);
		var hoverRedirect;
		if (!wasSubmit) return;

		if (appHasErrors($modalBody)) {
			$modalBody.trigger('mrc:contentLoadedWithErrors');

			return;
		} else {
			$modalBody.trigger('mrc:contentLoadedWithNoErrors');
		}

		if (submitOption === 'redirectTo') {
			hoverRedirect = getHoverRedirFromLinkTag($originalLink[0]);

			if (hoverRedirect) window.location.href = hoverRedirect;

			return;
		} else if (submitOption === 'closeAndRefresh') {
			var noForm = checkNoForm($parentAjaxContent,$modal);
			if(noForm){
				$modal.modal('hide');
				return;
			}
			var $form = $parentForm;
			var isajaxform = $form.find('#ajax_form').val();
			isajaxform = parseInt(isajaxform) || 0;
			if(isajaxform > 0){
				$modal.modal('hide');
				// @ts-ignore
				submitViaAjax($form.get(0));
				return;
            }
            if(formPost == 'true'){
                $form.submit();
            }else{
                window.location.reload(true);
            }
			

			return;
		} else if (submitOption === 'closeOnly') {
			$modal.modal('hide');

			return;
		}
	}
	function checkNoForm($ajaxContent,$modal){
		var $closestForm = $ajaxContent.closest('form');
		var keyList = $ajaxContent.closest('form').find('#keylist').val();
		var hasForm = $ajaxContent.find('form').length;
		if(hasForm == 0){
			var contentAction = $ajaxContent.attr('data-action');
			if(contentAction){
				var keyParms = formTabs.getKeyValues(keyList,$closestForm);
				contentAction = mrcJSUtil.addParm(contentAction, 'async', '1');
				contentAction = mrcJSUtil.addParm(contentAction, 'slnk', '1');
				contentAction += '&' + keyParms;
				//@ts-ignore
				loadViaAjax($ajaxContent.get(0), contentAction, '', 'GET', null);
				return true;
			}
		}
		return false;
	}
	function handleMaintainerSubmitButtons ($modalContent) {
		const $form = $modalContent.find('form');
		const $modal = $modalContent.parents('.modal');
		const $modalFooter = $modal.find('.modal-footer');
		const $closeButton = $modalFooter.find('[data-dismiss]');
		const $acceptButton = $('<button class="btn btn-primary mrc-modal-submit" type="button">Accept</button>');
		let $submitButton;
		let skipCheck = $modal.data('skip-check');
		let wasSubmit = !skipCheck;

		if (skipCheck) $modal.data('skip-check', null);
        $modalFooter.find('.mrc-modal-submit').remove();
        
		if ($form.length === 0) return;

		if ($form.data('app_type').indexOf('M') === -1  && $form.data('template') != 'grid') return;

		$submitButton = $form.find('button[name="btn_accept"],button[name="btn_mnt"]').hide();
		$form.find('button[name="btn_back"]').hide();
		$modal.off('mrc:contentLoaded').on('mrc:contentLoaded', afterModalRefresh);
		$closeButton.text('Cancel');
	

		if ($submitButton.length === 0) {

			return;
		}
		if($form.data('action_mode')!='VVK'){
			$modalFooter.append($acceptButton);
		}else{
            //2021-08-09 -31672 remove the accept button if its view mode
            $modalFooter.find('.mrc-modal-submit').remove();
        }
		
		$acceptButton.on('click', function clickAcceptButton (ev) {
			$submitButton.trigger('click');
		});

		return wasSubmit;
	}

	function loadAppForModal ($modal, url) {
		const data = { basic: 1, async: 1, x: Math.random() };

		// @ts-ignore
		$.ajax({ url: url, data: data, $modal: $modal, error: displayErrorInModal, success: displayAppInModal });
	}

	function destroyModal (ev) {
		const $modal = $(ev.currentTarget);

		$modal.modal('dispose').remove();
	}

	function handleModalClick (ev) {
		const $link = $(ev.currentTarget);
		let options = {
			event: ev,
			showBackdrop: $link.attr('data-backdrop'),
			height: $link.attr('height'),
			link: ev.currentTarget,
			parentAjaxContent: jQuery(ev.currentTarget).closest('.ajax_content'),
			parentForm: jQuery(ev.currentTarget).closest('form'),
			modalClass: $link.attr('modal'),
			title: $link.attr('title'),
			url: $link.attr('href'),
			width: $link.attr('width')
		};

		ev.preventDefault();
		ev.stopPropagation();

		openBSModal(options);
	}

	/**
	 * Opens Bootstrap Modal with given options
	 *
	 * @typedef {Object} ModalOptions
	 * @property {string | number} [height]
	 * @property {string | HTMLLinkElement} [link]
	 * @property {string | HTMLFormElement} [parentForm]
	 * @property {string} [modalClass]
	 * @property {string} [title]
	 * @property {string} url
	 * @property {string | number} [width]
	 *
	 * @param {ModalOptions} options
	 */
	function openBSModal (options) {
		const link = options.link;
		const modalClass = options.modalClass || DEFAULT_MODAL_CLASS;
		const title = options.title || 'Lookup';
		const url = options.url;
		const parentForm = options.parentForm;
		let width = options.width || 'auto';
		let height = options.height || 'auto';
		let $modal = $('.' + modalClass).first();

		if ($modal.length === 0) {
			if (modalClass !== DEFAULT_MODAL_CLASS) console.warn('No modal found with class ' + modalClass + ', creating now...');
			$modal = injectModalHTML(modalClass);
		}else if ( modalClass != DEFAULT_MODAL_CLASS){
            $modal = copyModal($modal);
        }

		$modal.find('.modal-title').text(title);
		$modal.find('.loading').show();
		$modal.find('.app-content').hide();
		$modal.find('.modal-body').css('overflow', 'auto');

		width = calculateSize(width);
		height = calculateSize(height);
		$modal.data({ link: link,parentAjaxContent:options.parentAjaxContent, height: height, width: width, parentForm: parentForm  });

		if (width !== 'auto') {
			$modal.find('.modal-dialog').css('width', width).css('max-width', 'initial');
		}

		if (height !== 'auto') {
			$modal.find('.modal-body').css('height', height);
		}

		loadAppForModal($modal, url);
		let showBackdrop = options.showBackdrop == 'true' ? true : false;
		$modal.modal({
			backdrop: showBackdrop
		});//.on('hidden.bs.modal', destroyModal); //commented out for 27890 - could cause unknown issues

		return false;
	}

	function returnDataFromModal (ev) {
		const $link = $(ev.currentTarget);
		const $modal = $link.parents('.modal');
		const origLink = $modal.data('link');
		const $origLink = $(origLink);
		const dataStr = $link.data('rtndata');
		const data = (typeof dataStr === 'object' ? dataStr : JSON.parse(dataStr));
          const $form = formHandler.getForm($origLink).$form;
       
	
		$modal.modal('hide');
		insertDataIntoForm(data, $form, $origLink);
	}

	function insertDataIntoForm (data, $form, $origLink) {
        const isGrid = $origLink.hasClass('grid-type');
		for (let i = 0, iLen = data.length; i < iLen; i++) {
			const name = data[i++];
			const value = data[i];
			let $inp = $form.find('[name="' + name + '"]');

            if(isGrid){
                $inp = $origLink.closest('tr').find('[name="' + name + '"]');
            }
            
			if ($inp.length === 0) {
				console.warn('No input on page with name ' + name + ', skipping...');

				continue;
			}

			$inp.not(':checkbox, :radio').val(value).trigger('change');
			$inp.filter(':checkbox, :radio').filter('[value="' + escape(value) + '"]').prop('checked', true);
		}
	}
	function closeModal (e){
		var $link = jQuery(e.currentTarget);
		var $modal = $link.closest('.modal')
		$modal.modal('hide');
	}
	/**
	 * Click listeners for page
	 */
	$('body').on('click', '[mrc="returndata"]', handleModalClick)
		.on('click', '.modal-close', closeModal)
		.on('click', '[data-rtndata]', returnDataFromModal);

	return {
		openBSModal: openBSModal,
		checkNoForm: checkNoForm
	};
}(window, jQuery));
