//###########################################################
// Version:  2009-08-01, mrc ltd., Lombard, IL, USA
// Functions for web 2.0 report .xx
// Only jQuery library is used. ALways use jQuery in place of $.
//###########################################################
jQuery.noConflict();
var optionDisabledClass = 'asmOptionDisabled';// Class for items that are already selected / disabled
var CLOSE_CLS = 'menu_body_close';
var OPEN_CLS = 'menu_body_open';
var GREEN_COLOR = '#6f9'; //rgb(102, 255, 153) in FF
var GREY_COLOR  = '#777';
var WHITE_COLOR  = '#fff';
row_collapsed_img = '/mrcjava/image/closed.gif';
row_expanded_img = '/mrcjava/image/open.gif';
var irtReports = new Object();
function onePage (options) {
	var genericReport = new MrcReport();
	genericReport.onePage(options);
	if(genericReport.isPivot == true) {
		pivotReports[genericReport.formId2] = genericReport;
	}
	return genericReport;
}
function MrcReport () {
	this.datapaneIdj = '#data_pane';
	this.dataHolderId = '#report-data';
	this.graphHolderId = '#report-graph';
	this.trigger_run_query = false;
	this._imageDrill = true;
	this._hasEditError = false;
	this._enterKeyPressed;
	this._lastTextInput;

	this.formId2 = '';
	this.appUrl2 = '';
	this.datapaneId2 = '';
	this.options2;
	this.dashboard = 0;
	this.columes; //number of columns of the report table
	this.otherParms; //parms from the selection panel
	this.availOptions; //available option from server used to grey out selections
	this.hasEmptyOption; //indecates if the returned data has at least 1 empty
	this.numOfRefreshes = 0; //track how many refreshes
	this.firingId = '';
	this.firingVal = '';
	this.isRuntimeRemoval = false;
	this.isPivot = false;
	this.row_collapsed_img = '/mrcjava/image/closed.gif';
	this.row_expanded_img = '/mrcjava/image/open.gif';
	this.container = new Object();
	this.pivotReport = new Object();
}
/****************************************************************/
//   Init at document ready.
/****************************************************************/
MrcReport.prototype.initReport = function () {
	console.log("In MrcReport.prototype.initReport");
	this.setSavedSearchDimensions();
	  // ------- setup ajax helper inputs  -------
	//initializeAjaxHelpers();

	// ------- setup select pane for expand/cllapse  -------
	this.initSelectMenu();

	// Create checkbox/radio/ul inputs from select list  if any
	this.convertList();

	// ------- Hide/show menu pane    -----------------------
	//SetupMenuHideShow();

	// ------- remove option of blank value -------
	this.container.find('#select_menu select[multiple] option[value=\'\']').remove();

	// ------- asmselect plugin  for dropdown inputs -------
	if(typeof MRCCONSTANTS === 'undefined') {
		this.container.find('#select_menu select[multiple]').not('select[mrccombobox]').asmSelect({
			addItemTarget: 'bottom', animate: false, highlight: true, sortable: false
		});
	}
	// ------- Setup slider for input field  ----------------
	//initSliders();
	var report = this;
	var hasRunButton = false;
	if (this.container.find('form #btn_search').length > 0) {
		hasRunButton = true;
	}
	if(hasRunButton == false) {
		this.container.find('input[name=mode_select]').click(function () {
			var mode012 = jQuery(this).val();
			//report.appUrl2 += '?mode_select=' + mode012;
			report.blockUIandRun(report.formId2, report.appUrl2, report.dataPaneId2);
		});
	}

	try{
	 this.container.find('#selected-dimensions,#available-dimensions').sortable({
			connectWith:'.irt-dimensions',
			update:function () {
				if(hasRunButton == false) {
					report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneId2);
				}
			}
		});
	}catch(err) {}

    // ------- Setup key bindings for interactive report dimensions  ----------------
	// -- This allows the drag-and-drop functionality to be mimicked using the keyboard --
	// -- Up Arrow: Move dimension up a position
    // -- Down Arrow: Move dimension down a position
    // -- Enter: Move the dimension from the selected list to the available list (and vice-versa). 
	jQuery('#selected-dimensions li').bind('keydown', function(event) {
		var update = false;
		if (event.which == 38) { // Move dimension up one place
			jQuery(this).insertBefore(jQuery(this).prev()).focus();
			update = !hasRunButton;
		} else if (event.which == 40) { // Move dimension down one place
			jQuery(this).insertAfter(jQuery(this).next()).focus();  
			update = !hasRunButton;
		} else if (event.which == 13) { // ENTER. Move between lists
			var parentId = jQuery(this).parent().attr('id');
			var target = (parentId === 'selected-dimensions') ? '#available-dimensions' : '#selected-dimensions';
			jQuery(target).append(jQuery(this));
			jQuery(this).focus();
			update = !hasRunButton;
		}

		if (update) {
			report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneId2);
		}
	});

	// ------- Setup key bindings for pivot report dimensions  ----------------
	// -- This allows the drag-and-drop functionality to be mimicked using the keyboard --
	// Up Arrow: Move dimension up a position within its container.
	// Down Arrow: Move dimension down a position within its container.
	// Left Arrow: Move dimension left a position within its container.
	// Right Arrow: Move dimenson right a positions within its container.
	// Shift+Right Arrow: In available dimensions, this moves the dimension to the column dimensions.
	// Shift+Down Arrow: In available dimensions, this moves the dimension to the row dimensions.
	// Shift+Left Arrow/Shift+Up Arrow: In column or row dimensions, this moves the dimension back to the available dimensions.
    // We are forced to use a MutationObserver because the pivot table is refreshed en masse when anything changes.	
	var observer = new MutationObserver(function(mutations, observer) {
		// Set up the dimension key bindings
		jQuery('.dimension').bind('keydown', function(event) {
			// Get the parent id to determine the correct key bindings to use.
			var parentId = jQuery(this).parent().attr('id');
			
			switch (parentId) {
			case "avDimensions":
				setUpAvailableDimensionsKeyBindings(jQuery(this), event);
				break;
			case "columns":
				setUpColumnDimensionsKeyBindings(jQuery(this), event)
				break;
			case "rows":
				setUpRowDimensionsKeyBindings(jQuery(this), event);
				break;
			default:
				break;
			}
		});
		// Pressing Enter on the drill icons should mimic clicking. 
		jQuery('#pvTable img').attr('tabindex', '0');
		jQuery('#pvTable img').attr('role', 'button');
		jQuery('#pvTable').on('keypress', 'img', function(e) {
			if (e.which == 13) { e.target.click();}
		});
		// Pressing Enter on the sort headers should mimic clicking.
		jQuery('#pvTable tr.sort-enabled td.header').attr('tabindex', '0');
		jQuery('#pvTable tr.sort-enabled td.header').attr('role', 'button');
		jQuery('#pvTable').on('keypress', 'tr.sort-enabled td.header', function(e) {
			if (e.which == 13) { e.target.click();}
		});
	});
	// Only observe changes to the parent node
	observer.observe(document.getElementById('data_pane'), {
		subtree: false,
		childList: true
	});
}; // End MrcReport.prototype.initReport()

/**
 * Pivot Reports: Sets up the available dimension key bindings.
 * This function is called from the mutation observer set up in MrcReport.prototype.initReport().
 * @param {*} dimEl 
 * @param {*} event 
 */
function setUpAvailableDimensionsKeyBindings(dimEl, event) {
    if (event.which == 40 && event.shiftKey) { // Move to row dimensions
        var rowCount = jQuery('#rows .dimension').length;
        if (rowCount < 5) {
            jQuery('#rows').append(dimEl);
            dimEl.focus();
        }
    } else if (event.which == 39) { // Right Arrow
        if (event.shiftKey) { // Move to column dimensions
            var colCount = jQuery('#columns .dimension').length;
            if (colCount < 3) {
                jQuery('#columns').append(dimEl);
                dimEl.focus();
            }
        } else { // Move right one place in available dimensions
            dimEl.insertAfter(dimEl.next()).focus();  
        }
    } else if (event.which == 37) { // Move left one place in available dimensions
        dimEl.insertBefore(dimEl.prev()).focus();  
    }
}
/**
 * Pivot Reports: Sets up the column dimension key bindings.
 * This function is called from the mutation observer set up in MrcReport.prototype.initReport().
 * @param {*} dimEl 
 * @param {*} event 
 */
function setUpColumnDimensionsKeyBindings(dimEl, event) {
    if ((event.which == 37 || event.which == 38) && event.shiftKey) { // Move to available dimensions
        jQuery('#avDimensions').append(dimEl);
        dimEl.focus();
    } else if (event.which == 39) { // Right Arrow
        dimEl.insertAfter(dimEl.next()).focus();
    } else if (event.which == 37) { // Left Arrow
        dimEl.insertBefore(dimEl.prev()).focus();  
    }
}
/**
 * Pivot Reports: Sets up the row dimension key bindings.
 * This function is called from the mutation observer set up in MrcReport.prototype.initReport().
 * @param {*} dimEl 
 * @param {*} event 
 */
function setUpRowDimensionsKeyBindings(dimEl, event) {
    if ((event.which == 37 || event.which == 38) && event.shiftKey) { // Move to available dimensions
        jQuery('#avDimensions').append(dimEl);
        dimEl.focus();
    } else if (event.which == 40) { // Down Arrow
        dimEl.insertAfter(dimEl.next()).focus();
    } else if (event.which == 38) { // Up Arrow
        dimEl.insertBefore(dimEl.prev()).focus();  
    }
}

/*****************************************************************/
//   Enable one page logic - called from one page report.
/****************************************************************/
MrcReport.prototype.onePage = function (customOptions) {
	var options = {
		formId: '',                // Id of the form
		appUrl: '',                // URI of the APP - MRCJAVALIB.R00100s
		extraParms: '&p1=qqq',             // '&p1=v1&p2=v2...'
		datapaneId: 'data_pane',   // Id of the data pane - datapane
		greyoutOn: true,           // Use selection pane greyout - true/false
		appkeys: {},
		dashboard: 0
	};
	jQuery.extend(options, customOptions);
	this.formId2 = options.formId;
	this.dashboard = options.dashboard;
	this.appUrl2 = options.appUrl;
	this.datapaneId2 = options.datapaneId;
	this.options2 = options;
	this.container = jQuery('#' + this.formId2).parent();
	try{
		this.setIsPivot();
	}catch(err) {

	}
	this.initReport();
	// Setup the list div (if any) for click action
	var report = this;
	this.container.find('.list_div').click(function () {
		//with greyout logic and no submit button, do green color
		if ( report.options2.greyoutOn && jQuery('form #btn_search').length == 0) {
			if (jQuery(this).isGreen()) {
				jQuery(this).textListUnclick(report);
				jQuery(this).bg('');
			} else {
				jQuery(this).textListclick(report);
				jQuery(this).bg(GREEN_COLOR);
			}
		} else { //no greyout logic, do bold on text buttons
			if (jQuery(this).isBold()) {
				jQuery(this).textListUnclick(report);
				jQuery(this).css('font-weight', 400);
			} else {
				jQuery(this).textListclick(report);
				jQuery(this).css('font-weight', 700);
			}
		}
	});

	//save the input clicked for use grey when Enter pressed
	this.container.find('.menu_body input[type=text]').click(function () {
		var lastInput = jQuery(this);
		report._lastTextInput = lastInput;
	});

	//setup run report based on change of input field if no greyout.
	// If there is greyout then the 'run report' will happen after greyout
	if ( !options.greyoutOn ) {
		this.setupSubmitOnInput();
	} else { //there is greyout but only 1 select
		if (this.container.find('.menu_body select').length == 1) {
			//setupSubmitOnInput();
		}
	}

	//setup run refresh selections if grey on and no submit buton
	if (options.greyoutOn && this.container.find('form #btn_search').length == 0) {
		setupRefreshSelect(this);
	} else {
		//save Id/val of element selected/clicked to be used to grey
		setupRegisterFiringId(this);
	}

	// Set width at load time when the menu is expanded.
	var report = this;
	//this.container.find('#select_menu').width( report.container.find('#select_menu').width() + 5);
	this.container.find('#select_menu').width('auto');
	this.enableOnePage(this.formId2, this.appUrl2, this.datapaneId2);
	irtReports[this.formId2] = this;
};

/*****************************************************************/
//   Enable one page logic - display data on prompt page. (old interface)
/****************************************************************/
MrcReport.prototype.enableOnePage = function (formId, appUrl, dataPaneId) {
	this.datapaneIdj = '#' + dataPaneId;
	this.formId2 = formId;
	this.appUrl2 = appUrl;
	this.datapaneId2 = dataPaneId;
	var report = this;
	jQuery('#' + formId).submit(function (evt) {
	  report.blockUIandRun(formId, appUrl, dataPaneId);
		return false;
	});

	this.setupDrill() ; //used when 1-page report is imported to other apps
	this.container.find('#isinit').val('1'); //tell report this is init (for *RUNVALID)

	var imgDrill = jQuery.valByNameFromQS('imgdrill', window.location.href);
	if (imgDrill && imgDrill == 'y') {
		//var wkInput = jQuery(" <input />" ).attr( {"type": "hidden", "value":"y", "name":"imgdrill", "id":"imgdrill"});
		var wkInput = jQuery('<input type=hidden value=y name=imgdrill id=imgdrill />');  //chg for jquery 1.4.2
		var inp0 = this.container.find('#sel_R001 input.ls');
		if (inp0.length) { //if multiple inputs for LS/NS, set the value for img drill
			inp0.eq(0).val( this.container.find('#R001').val());
		}
		this.container.find('#' + formId).append(wkInput);
	}

	var data = this.container.find('#report-data').html();
	//if (data.indexOf('freemarker.core.') > 0) {
	//   return; //has error, show it.
	//}

	/* check if need to do smart logic for hard coded select input */
	checkRefreshSelections2(this);

	this.container.find('#' + formId).submit();

};
MrcReport.prototype.setSavedSearchDimensions = function () {
	var report = this;
	var isSearch = this.container.find('input[name=is_search]').val();
	if(isSearch && isSearch == 'Y') {
		var dimensionStr = this.container.find('input[name=row_dimensions]').val();
		var dimensions = dimensionStr.split(';');
		jQuery('.available-dimensions').append(jQuery('.irt-dimensions li'));
		for(var i = 0; i < dimensions.length;i++) {
			jQuery('.selected-dimensions').append(jQuery('li.irt-dimension[data-field=\'' + dimensions[i] + '\']'));
		}
		this.container.find('input[name=is_search]').val('N');
	}

};
/****************************************************************/
//   Initiallize Select menu exapnd/collapse
/****************************************************************/
MrcReport.prototype.initSelectMenu = function () {
	var report = this;
	this.container.find('.menu_head').addClass(OPEN_CLS);

	this.container.find('#select_menu p.menu_head').click(function ()  {
	    var menuHead = jQuery(this);
		menuHead.toggleClass(CLOSE_CLS);
		menuHead.toggleClass(OPEN_CLS);
		var itemOpen = menuHead.hasClass(OPEN_CLS);
		if (itemOpen) {
		    menuHead.attr('title', 'Collapse');
		} else {
		    menuHead.attr('title', 'Expand');
		}
		menuHead.next('div.menu_body').slideToggle(300);
	});

	/* Hide, show all record selections */
	this.container.find('#open_all').toggle(function ()  {
		jQuery(this).closest('tr').next().find('div.menu_body').not('.no_collapse').slideUp(300);
		report.container.find('.menu_head').addClass(CLOSE_CLS).removeClass(OPEN_CLS).attr('title', 'Expand');
		jQuery(this).find('img').attr('title', 'Expand all').attr('src', '/mrcjava/image/closed.gif');
	  }, 	function ()  {
		jQuery(this).closest('tr').next().find('div.menu_body').slideDown(300);
		report.container.find('.menu_head').addClass(OPEN_CLS).removeClass(CLOSE_CLS).attr('title', 'Collapse');
		jQuery(this).find('img').attr('title', 'Collapse all').attr('src', '/mrcjava/image/open.gif');
	  }
	);

	/* Hide, show subtotal selections */
	  this.container.find('#open_all_sub').toggle(function ()  {
		report.container.find('#sub_totals').slideUp(300);
		jQuery(this).html('<img src="/mrcjava/image/closed.gif"> Include Levels').attr('title', 'Show level selections');
	  }, 	function ()  {
		report.container.find('#sub_totals').slideDown(300);
		jQuery(this).html('<img src="/mrcjava/image/open.gif"> Include Levels').attr('title', 'Hide level selections');
	  }
	);
};
/****************************************************************/
/*   setup run report based on change of input field.           */
/****************************************************************/
MrcReport.prototype.setupSubmitOnInput = function () {

	/*  Check if there is submit button. If no then setup Ajax submit. */
	if (this.container.find('form #btn_search').length > 0) {
		return; //Has submit button.  skip setup
	}
	/* No submit button  */
	/* Setup select fields to run Ajax query */
	var report = this;
	this.container.on('change', '.menu_body select[name!=asmSelect0], .menu_body_skip select[name!=asmSelect0]', function () {

	    report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneId2);
	});
	/* Setup checkbox to run Ajax query */
	this.container.on('click', '.menu_body input:radio, .menu_body input:checkbox', function () {

		report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneId2);
	});

	/* Setup text buttons to run Ajax query */
	this.container.on('click', '.list_div', function () {

		report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneId2);
	} );

	this.trigger_run_query = true;
};
/****************************************************************/
/*   setup selection menu hide/show.                            */
/****************************************************************/
function SetupMenuHideShow (container) {

	container.find('#hide_menu').unbind('click');
	container.find('#hide_menu').click(function () {
		var width = container.find('#select_pane').width();
		var margin = '0px';
		var $selectionpane = container.find('#select_pane').parent();
		if(typeof MRCCONSTANTS === 'undefined') {
    	   $selectionpane = container.find('#select_pane');
    	   margin = '10px';
		}
		var widthc = '-' + (width + 12) + 'px',
       	ishidden =  $selectionpane.attr('data-hidden');
		if(ishidden && ishidden == 'true') {
    	   $selectionpane.attr('data-hidden', 'false').animate({ marginLeft: margin }, 1000, function () {
    	          container.find('#hide_menu img').attr({'src':'/mrcjava/image/hide_menu.png',  'title': 'Hide selection menu'});
    	       } );
		}else{
    	   $selectionpane.attr('data-hidden', 'true').animate({ marginLeft: widthc}, 1000, function () {
    	          container.find('#hide_menu img').attr({'src':'/mrcjava/image/show_menu.png',  'title': 'Show Record Selections'});
    	       } );
		}
	});
}
/****************************************************************************/
//   Refresh selections based on selected values.
/****************************************************************************/
function setupRefreshSelect (report) {

	//before setup refresh on controls, check if need to a refresh (need to
	// when clicked on image map and the 1st key is selected)
	if (report.container.find('select#R001 option:selected').length) {
		//refreshSelections(jQuery("select#R001"));
	}

	/* Enter Key pressed */
	jQuery('#' + report.formId2).bind('keypress', function (e) {

		if (e.keyCode == 13) {
			if (report._lastTextInput) {

				refreshSelections({ inpField : report._lastTextInput}, report);
			}
			report._enterKeyPressed = true;
		}
	});

	/* refresh if select changes */
	// var selects = jQuery('.menu_body select');
	var selects = report.container.find('.menu_body select.mrcselect:not(.asmSelect), .menu_body input.scombobox-value');//2016-09-12 - prevent multiple change events
	if (selects.length >= 1) {
		jQuery(selects).change(function (evt) {
			/* check if range select */
			var sel_id = jQuery(this).parents('.menu_body').attr('id');
			var sels = report.container.find('#' + sel_id + ' select.mrcselect');
			if (sels.length == 2) {
        	  var oid1 = jQuery(sels[0]).attr('oid');
        	  var oid2 = jQuery(sels[1]).attr('oid');
        	  if (!oid1 && !oid2) {//2013-06-17:exclude ASM selections
        		  var val1 = jQuery(sels).eq(0).val();
        		  var val2 = jQuery(sels).eq(1).val();
        		  var val3 = val1 + val2;
        		  if ( (val1 == '' || val2 == '') && !(val1 == '' && val2 == '') ) {
        			  return; //only 1 selected, do not fire
        		  }
        	  }
			}
			report.firingId = jQuery(this).parents('.menu_body').attr('id').substring(4);
			var firingValTmp = jQuery(this).val();
			if (jQuery.isArray(firingValTmp)) {
				report.firingVal = firingValTmp[0];
			}
			//alert(firingId + "---" + firingVal);
  	      refreshSelections({ inpField : this}, report) ;
		});
	}

	/* refresh if input blur */
	report.container.find('.menu_body input[type=text]').blur(function () {
	   var inpName = jQuery(this).attr('name');
	   var cssSel = 'input[name=\'' + inpName + '\']';
	   var inparr =  jQuery.makeArray( report.container.find(cssSel) );

	   //input (range), need check if both have values
	   if (inparr.length == 2) { //
			var val1 = jQuery.trim(jQuery(inparr[0]).val());
			var val2 = jQuery.trim(jQuery(inparr[1]).val());
			if (val1 == '' || val2 == '') {
       	     return; //at least 1 is empty, do not fire.
			}
	   }
	   //comment out 2010/01/07
	   //refreshSelections({ inpField : this}) ;
	});

	/* refresh if input check (radio/checkbox) */
	report.container.find('.menu_body input:radio, .menu_body input:checkbox').click(function () {
    	// 2015-05-15 - Added for combobox, so only the change to the input will trigger refresh
    	if (jQuery(this).parents('div.scombobox-list').length > 0) return;
		firingId = null ;
		report.firingVal = null;
		if (jQuery(this).attr('checked')) {
			report.firingId = jQuery(this).parents('.menu_body').attr('id').substring(4) ;
			report.firingVal = jQuery(this).val();
		}
		jQuery(this).checkListCheckEq(report);
	    refreshSelections({ inpField : this}, report) ;
	});

	/* refresh converted simple div list  */
	report.container.find('.list_div').click(function () {
		report.firingId = null ;
		report.firingVal = null;
		if (jQuery(this).isGreen()) {//select to turn green
			report.firingId = jQuery(this).parent().attr('id') ;
			report.firingVal = jQuery(this).attr('oval');//text();
		}

		refreshSelections({ inpField : this}, report ) ;
	} );

}
/****************************************************************************/
/*   Save Id/Val of element selected.                                       */
/****************************************************************************/
function setupRegisterFiringId (report) {

	var selects = report.container.find('.menu_body select.mrcselect');
	jQuery(selects).change(function (evt) {
		firingId = jQuery(this).parents('.menu_body').attr('id').substring(4);
		var firingValTmp = jQuery(this).val();
		if (jQuery.isArray(firingValTmp)) {
			firingVal = firingValTmp[0];
		}
	});

	report.container.find('.menu_body input:radio, .menu_body input:checkbox').click(function () {
		if (jQuery(this).attr('checked')) {
			firingId = jQuery(this).parents('.menu_body').attr('id').substring(4) ;
			firingVal = jQuery(this).val();
			jQuery(this).checkListCheckEqWithButton(report);
		}
	});

	report.container.find('.list_div').click(function () {
		var listdiv = jQuery(this);
		if (listdiv.isGreen() || listdiv.isBold()) {//select to turn green
			firingId = jQuery(this).parent().attr('id') ;
			firingVal = jQuery(this).attr('oval');//text();
		}
	} );
}
/****************************************************************************/
// Refresh selection values.
// optparms.menu_bodyId = 'sel_R00n' (no #)
/****************************************************************************/
function refreshSelections (optparms, report) {
	var validated = checkRequiredFields(jQuery('#' + report.formId2));
	if(validated == false) {
		return false;
	}
	if(report.isPivot == true) {
		report.saveDimensions();
		valid = report.getSubTotals();
	}
	if (report._hasEditError) { //has input edit error. No greyout
		report._hasEditError = false;
		//return;
	}

	if (report.container.find('.menu_body select').length == 0 &&
            report.container.find('.dsp_list').length == 0 &&
            report.container.find('.dsp_radio').length == 0 &&
            report.container.find('.dsp_check').length == 0 &&
            !optparms.firstKeyPair) {
		insertOrUnblock(report);
		return; //no list, skip smart logic
	}

	var menu_bodyId = optparms.menu_bodyId;
	if (!menu_bodyId) {
		menu_bodyId = report.container.find(optparms.inpField).parents('.menu_body').eq(0).attr('id');
	}

	//query DB and use result to refresh/greyout selecions pane
	var parms = report.parmValuesDrill();  //get parms from R001...

	var keyVal = '';
	if (optparms.firstKeyPair) {
		keyVal = '&' + optparms.firstKeyPair;
		var pos = optparms.firstKeyPair.indexOf('=');
		var rxxx = optparms.firstKeyPair.substring(0, pos);
		parms = report.parmValuesDrill(rxxx);  //get parms from R001...except key
	}

	//block browser during the grey logic
	blockBrowser(report.formId2, report.appUrl2, report.datapaneId2);

	var url = report.appUrl2 + '?reqtype=21' + keyVal + parms;// + "&x=" + Math.random();
	 url = getRowDimensions(url, report.formId2);
	url = url.replace(/_%_/g, '_%25_');//mrc encode for space
	// alert('refreshSelections-' + url);
	jQuery.ajax({ url: url,  dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			eval(data); //set var availOptions
			clearGreyoutSelections(menu_bodyId, {'url_togo':optparms.url_togo,
				firstKeyPair: optparms.firstKeyPair}, report);
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			var errmsg = 'Error happened during Ajax request: ';
			errmsg += XMLHttpRequest.responseText;
		}
	});
}
/****************************************************************/
//  Clear and grey out selections.
/****************************************************************/
function clearGreyoutSelections (menu_bodyId, optparms, report) {

	var selectedIsInReturnData = true;
	if (report.firingVal) {   //only use this condition if is select click (not unclick)
		//selectedIsInReturnData = isinArray(firingVal, firingId);
	}

	//has empty selection, or the return data does not have the selected value clear and resubmit
	if (report.hasEmptyOption || !selectedIsInReturnData) {
		//clear selected in all other menu_body
		var actingId = '#' + menu_bodyId;
		report.container.find('.menu_body').not(actingId).each(function () {
			var theid = '#' + jQuery(this).attr('id');
			clearSelected2(theid, report);
		});

		/* No option in the second try, query data anyway */
		report.numOfRefreshes++;
		if (report.numOfRefreshes > 1) {
			//alert("attempted " + numOfRefreshes + " times. Cannot refresh selections. url=" + optparms.url);
			insertOrUnblock(report);
			report.numOfRefreshes = 0;
			return;
		}

		//Ajax again to get new data
		refreshSelections({ menu_bodyId : menu_bodyId, firstKeyPair: optparms.firstKeyPair}, report);
		return;
	}

	//No conflict, go do grey out
	report.numOfRefreshes = 0;
	report.greyoutSelections({ menu_bodyId : menu_bodyId }) ;

	// Image drill down.
	if (optparms.firstKeyPair) {
		var pos = optparms.firstKeyPair.indexOf('=');
		var rxxx = optparms.firstKeyPair.substring(0, pos);
		var otparms = report.parmValuesDrill(rxxx);
		otparms = otparms.replace(/_%_/g, '_%25_');//mrc encode for space
		var url = report.appUrl2 + '?imgdrill=y&' + optparms.firstKeyPair + otparms;
		window.location = url;
		return;
	}

	insertOrUnblock(report);
}

function insertOrUnblock (report) {
	/*  If no submit button Ajax data. */
	if (report.container.find('form #btn_search').length == 0) {
		//blockUIandRun(formId2, appUrl2, datapaneId2);
		insertReportData(report.formId2, report.appUrl2, report.datapaneId2, report);
	} else {
		/* Has submit button, Unblock page after grey complete */
		setTimeout(function () {unblockBrowser();}, 200);
	}
}
/****************************************************************************/
//  When there are hard coded selection values, try refresh based on them
/****************************************************************************/
function checkRefreshSelections2 (report) {
	var selects = report.container.find('.menu_body select.mrcselect');
	if (selects.length == 0) {
		return;
	}

	/* find the first select that has value and do smart logic */
	jQuery(selects).each(function () {
		var theid = jQuery(this).parents('.menu_body').attr('id').substring(4);
		var val = jQuery(this).val();
		if (theid && theid.indexOf('R00') >= 0 && val && val.length > 0) {
			refreshSelections2({ menu_bodyId : theid}, report);
			return false; //break in each
		}
	});
}
/****************************************************************************/
// Refresh selection values without fire Ajax
// optparms.menu_bodyId = 'sel_R00n' (no #)
/****************************************************************************/
function refreshSelections2 (optparms, report) {

	if (report._hasEditError) { //has input edit error. No greyout
		return;
	}

	//query DB and use result to refresh/greyout selecions pane
	var parms = report.parmValuesDrill();  //get parms from R001...

	var keyVal = '';
	if (optparms.firstKeyPair) {
		keyVal = '&' + optparms.firstKeyPair;
	}
	var qoramp = '?';
	if(report.appUrl2.indexOf('?') >= 0) {
		qoramp = '&';
	}
	var url = report.appUrl2 + qoramp + 'reqtype=21' + keyVal + parms;// + "&x=" + Math.random();
	 url = getRowDimensions(url, report.formId2);
	url = url.replace(/_%_/g, '_%25_');//mrc encode for space
	//alert('2' + url);
	jQuery.ajax({ url: url,  dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			if (data) {
				eval(data); //set var availOptions
				clearGreyoutSelections2({menu_bodyId: optparms.menu_bodyId, 'url':url,
					firstKeyPair: optparms.firstKeyPair}, report );
			} else {
				setTimeout(function () {unblockBrowser();}, 200);
			}
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			var errmsg = 'Error happened during Ajax request: ';
			errmsg += XMLHttpRequest.responseText;
			//alert(errmsg);
		}
	});
}
/****************************************************************/
//  Clear and grey out selections.
/****************************************************************/
function clearGreyoutSelections2 (optparms, report) {

	//has empty selection, clear and resubmit
	if (report.hasEmptyOption) {
		//clear selected in all other menu_body
		var actingId = '#' + optparms.menu_bodyId;
		report.container.find('.menu_body').not(actingId).each(function () {
			var theid = '#' + jQuery(this).attr('id');
			clearSelected2(theid);
		});

		/* No option in the second try, query data anyway */
		report.numOfRefreshes++;
		if (numOfRefreshes > 1) {
			// alert("Tried " + numOfRefreshes + " times. Cannot refresh selections. url=" + optparms.url);
			//insertOrUnblock();
			report.numOfRefreshes = 0;
			return;
		}

		//Ajax again to get new data
		refreshSelections2(optparms, report);
		return;
	}

	//No conflict, go do grey out
	report.numOfRefreshes = 0;
	report.greyoutSelections({ menu_bodyId : optparms.menu_bodyId});
}
/****************************************************************/
//  grey out selection values based on new Ajax result.
/****************************************************************/
MrcReport.prototype.greyoutSelections = function (optparms) {
     //2023-07-04 - needs this check 
     if(!this.options2.greyoutOn) return ;

	//1. do select list
	var report = this;
	this.container.find('.menu_body').each(function () {
		var selects = jQuery(this).find('select');
		if (selects.length == 1) { //normal select
			report.greySelectOptions1(selects.eq(0).attr('id'));
		}
		if (selects.length == 2) { //asm select + normal list
			var asmId = selects.eq(0).attr('id'); //asmSelect...
			var mrcId = selects.eq(1).attr('id'); //R00...
			report.greySelectOptions2(asmId, mrcId);
		}

		// Radio inputs ( <div class="menu_body" id="sel_R002">)
		//<div><input type="radio" name="R002" value="R0040" />R0040<input type="radio" name="R002" value="R0030" />R0030
		var radios = jQuery(this).find(':radio');
		if(radios.length > 0) {
			var fldId = ( jQuery(this).attr('id')).substring(4); //R001
			radios.each(function () {
				var thisInp = jQuery(this);
				if (!isinArray( thisInp.val(), fldId ) ) { //not in returned list - grey out
					thisInp.next().bg(GREY_COLOR);
				} else { //is in returned list - remove grey
					thisInp.next().bg('');
				}
				if (thisInp.attr('checked')) {
					thisInp.next().bg(GREEN_COLOR);
				}
			});
		}

		// Comboboxes
		var comboboxOpts = jQuery(this).find('.scombobox-list p');
		if (comboboxOpts.length > 0) {
        	var whichSel = this.id.substring(4); //R001
			comboboxOpts.each(function () {
				var thisOpt = jQuery(this),
                 	thisDesc = thisOpt.text(),
                 	thisVal = jQuery('select[name="origFor' + whichSel + '"] option').filter(function () {
                 			return jQuery(this).text() === thisDesc;
            			}).first().val();
				if (!isinArray(thisVal, whichSel)) { //not in returned list - grey out
					thisOpt.bg(GREY_COLOR);
				} else { //is in returned list - remove grey
					thisOpt.bg('');
				}
			});
		}
	});

	//2. do check box list ( <div class="menu_body dsp_check" id="sel_R002">)
	//<div><input id="R0021" name="R002" value="1" type="checkbox"><label for="R0021">1 &nbsp; </label>...
	this.container.find('.dsp_check').each(function () {
		var fldId = ( jQuery(this).attr('id')).substring(4); //R001
		var checks = jQuery(this).find('input');
		jQuery(checks).each(function () {
			var thisInp = jQuery(this);
			if (!isinArray( thisInp.val(), fldId ) ) { //not in returned list - grey out
				thisInp.next().bg(GREY_COLOR);
			} else { //is in returned list - remove grey
				thisInp.next().bg('');
			}
			if (thisInp.attr('checked')) {
				thisInp.next().bg(GREEN_COLOR);
			}

		});
	});

	//3. do simple text list ( <div class="menu_body dsp_list" id="sel_R002">)
	//<div id="R001"> <div class="list_div">2005</div> <div class="list_div">2006</div>
	this.container.find('.dsp_list').each(function () {
		var fldId = ( jQuery(this).attr('id')).substring(4); //R001
		var fldVal = report.container.find('#' + fldId + 'Id').val(); //input fld val
		fldVal = fldVal.replace(/_%_/g, ' '); //space in text was converted to ' '
		var rxxx = optparms.menu_bodyId ? optparms.menu_bodyId.substring(4) : '';
		var texts = jQuery(this).find('.list_div');

		jQuery(texts).each(function () {
			var thisInp = jQuery(this);
			var val = thisInp.attr('oval');//text();
			//val = val.replace(/ /g,'_%_'); //space in text was converted to ' '
			if (!isinArray( val, fldId ) ) { //not in returned list - grey out
				thisInp.bg(GREY_COLOR);
			} else if (!thisInp.isGreen()) { //in returned list & not green - remove grey
				var expr = '\\b' + val + '\\b';
				if (fldVal.search(expr) < 0) { //val not in selected
					thisInp.bg('');
				} else { //val in selected, restore green
					thisInp.bg(GREEN_COLOR);
				}
			}
		});
	});

	if ( this.options2.greyoutOn && this.container.find('form #btn_search').length == 1) {
		setTimeout(function () {unblockBrowser();}, 200);
	}
};
/************************************************/
//  grey out select list options for regular select
/************************************************/
MrcReport.prototype.greySelectOptions1 = function (selectId) {
	if (!jQuery.isR0nn(selectId) ) {//if (selectId.indexOf('R00') < 0 ) {
		return;
	}

	//alert(availOptions[selectId]);
	var selOptions = this.container.find('#' + selectId + ' option');
	jQuery(selOptions).each(function () {
		var thisOpt = jQuery(this);
		var thisVal = thisOpt.val();
		thisVal = thisVal.replace(/_%_/g, ' '); //
		if (!isinArray( thisVal, selectId ) ) { //not in returned list - grey out
			thisOpt.bg(GREY_COLOR);
		} else { //is in returned list - remove grey
			thisOpt.bg(WHITE_COLOR);
		}
	});
};
/************************************************/
//  grey out select list options for asm select.
/************************************************/
MrcReport.prototype.greySelectOptions2 = function (asmId, mrcId) {

	//alert(availOptions[mrcId]);
	var selOptions = this.container.find('#' + asmId + ' option');
	jQuery(selOptions).each(function () {
		var thisOpt = jQuery(this);
		var thisVal = thisOpt.val();
		thisVal = thisVal.replace(/_%_/g, ' '); //
		if (!isinArray( thisVal, mrcId ) ) { //not in returned list - grey out
			thisOpt.bg(GREY_COLOR);
		} else { //is in returned list - remove grey

			if ( thisOpt.attr('disabled') ) {
				thisOpt.bg(GREEN_COLOR);  //selected in asm
			} else {
				thisOpt.bg(WHITE_COLOR);
			}
		}
	});
};
/************************************************/
//  Check if an value is in the available array.
/************************************************/
function isinArray (val, selectId) {
	if (val == null || val == '') {
		return true; //empty option etc
	}
	var avlOptions = availOptions[selectId]; //values from server
	if (!avlOptions) {
		return true; //ext obj list is not in the return data
	}

	var isInArray = false;
	for (var i = 0; i < avlOptions.length; i++) {
		var avl = avlOptions[i].replace(/_%_/g, ' '); //
		avl = avl.replace(/&quot;/g, '"'); // double quote in availOptions is encoded
		val = val.replace(/_%_/g, ' '); //
		if (val == avl) {
			isInArray = true;
			break;
		}
	}
	return isInArray;
}

/****************************************************************************/
/**   setup drill down for next level sub-total rows.                       */
/****************************************************************************/
MrcReport.prototype.setupDrill = function () {
	//get columes to be used in setting up drill link row
	if (!this.columes) {
		var subtr = this.container.find('.sub_total').eq(0);
		//alert(subtr + ",,  " + subtr.length);
		columes = subtr.children('td').length;
	}

	//setup click on sub-total rows, td containing drill icon clicked
	var myreport = this;
	this.container.find('.drill_icon').unbind('click');//2011-09-14:add for jquery 1.6.4
	this.container.find('.drill_icon').bind('click', function (event) { callSetupDrill2(event, myreport);});
	// Pressing Enter on a drill_icon should be the same as clicking on it (WCAG).
	jQuery('.drill_icon').on('keypress', function(evt) {
		if (evt.which == 13) { evt.target.click(); }
	});
	this.container.find('.s1m,.s2m,.s3m').unbind('click');
	this.container.find('.s1m,.s2m,.s3m').bind('click', function (event) { callSetupDrill4(event, myreport);});
};
/****************************************************************/
/** Setup paging records (in the same level) from Show More link */
/****************************************************************/
MrcReport.prototype.setupPaging = function () {
	var report = this;
	jQuery('.paging_alink').bind('click', function (event) {callPaging(event, report);});

};
/****************************************************************/
/** setup show/hide for Ajax loaded drill down step 2           */
/****************************************************************/
function callSetupDrill2 (event, report) {
	//'this' is the td containing drill icon
	var thistr = jQuery(event.currentTarget).parents('tr').eq(0);

	//If this is a 'show more' paging row, skip drill down
	if (jQuery(thistr).hasClass('paging_tr')) {
		return;
	}

	jQuery(thistr).find('.spin-img').show();

	if (thistr.attr('loaded') != 'y') { //rows have not been loaded
		report.drillDown(thistr);
		thistr.attr('loaded', 'y');
		thistr.attr('shown', 'y');
		thistr.find('img.drill_icon').attr('src', row_expanded_img);
		thistr.find('img.drill_icon').attr('title', 'Collapse');
		return;
	}
	//already loaded, do show/hide
	var thisClass = jQuery(thistr).attr('class');
	var thisLevel = getLevelInt(thisClass);

	var nextRow = jQuery(thistr).next('tr');
	var nextClass = nextRow.attr('class');
	var cnt = 1;

	while (nextClass) {
		if (cnt++ > 10000) {
			alert('contact mrc.');
			break;
		}

		var nextLevel = getLevelInt(nextClass);
		if (nextLevel <= thisLevel) {
			break; //done with this level
		}

		if (thistr.attr('shown') == 'y') {
			nextRow.hide();
		} else {
			nextRow.show();
			nextRow.attr('shown', 'y');
		}

		nextRow = jQuery(nextRow).next('tr');
		nextClass = nextRow.attr('class');

	} //while

	if (thistr.attr('shown') == 'y') {
		thistr.attr('shown', 'n');
		thistr.find('img.drill_icon').attr('src', row_collapsed_img);
		thistr.find('img.drill_icon').attr('title', 'Expand');
	} else {
		thistr.attr('shown', 'y');
		thistr.find('img.drill_icon').attr('src', row_expanded_img);
		thistr.find('img.drill_icon').attr('title', 'Collapse');
	}
	jQuery(thistr).find('.spin-img').hide();
}
function callSetupDrill4 (event, report) {
	//'this' is the td containing drill icon
	var thistr = jQuery(event.currentTarget).parents('tr').eq(0);

	//If this is a 'show more' paging row, skip drill down
	if (jQuery(thistr).hasClass('paging_tr')) {
		return;
	}

	jQuery(thistr).find('.spin-img').show();
	if (thistr.attr('loaded') != 'y') { //rows have not been loaded
		report.drillDown(thistr);
		thistr.attr('loaded', 'y');
		thistr.attr('shown', 'y');
		thistr.find('.indicator').toggleClass('indicator2');
		thistr.find('img.drill_icon').attr('title', 'Collapse');
		return;
	}
	//already loaded, do show/hide

	var thisClass = jQuery(thistr).attr('class');
	var thisLevel = getLevelInt(thisClass);

	var nextRow = jQuery(thistr).next('tr');
	var nextClass = nextRow.attr('class');
	var cnt = 1;
	while (nextClass) {
		if (cnt++ > 10000) {
			alert('contact mrc.');
			break;
		}

		var nextLevel = getLevelInt(nextClass);
		if (nextLevel <= thisLevel) {
			break; //done with this level
		}

		if (thistr.attr('shown') == 'y') {
			nextRow.hide();
		} else {
			nextRow.show();
			nextRow.attr('shown', 'y');
		}

		nextRow = jQuery(nextRow).next('tr');
		nextClass = nextRow.attr('class');

	} //while

	if (thistr.attr('shown') == 'y') {

		thistr.attr('shown', 'n');
		thistr.find('.indicator').toggleClass('indicator2');
		thistr.find('img.drill_icon').attr('title', 'Expand');
	} else {

		thistr.attr('shown', 'y');
		thistr.find('.indicator').toggleClass('indicator2');

	}
	jQuery(thistr).find('.spin-img').hide();
}
/****************************************************************/
/**  get the level number 1,2,3 ...
/ *  return 99 if detail, return 0 if no level
/****************************************************************/
function getLevelInt (str) {
	if (str.indexOf('level_detail') >= 0) {
		return 9; //detail level
	}
	var level = 0;
	var pos = str.indexOf('level_R00');
	if (pos > 0) {
    	var intp = str.substring(pos + 9);
		level = parseInt(intp);
	}
	return level;
}
/****************************************************************/
/**   drill down for sub-total rows using Ajax.
/****************************************************************/
MrcReport.prototype.drillDown = function (theRow) {
	var thisClass = jQuery(theRow).attr('class');
	var thisLevel = getLevelInt(thisClass);

	var parms = '';
	jQuery(theRow).find('.sub_value').each(function () {
    	var key = jQuery(this).attr('key');
    	var val = jQuery(this).attr('val');
    	val = encodeURIComponent(val);
	    parms += '&' + encodeURIComponent(key) + '=' + val;
	} );

	//Add other selections
	if (this.otherParms) {

		this.otherParms = this.otherParms.replace(/_%_/g, '_%25_');//mrc encode for space
		parms += this.otherParms;
	}

	var appurl = this.appUrl2;
	var urldiv = jQuery(theRow).parent('tbody');
	//alert(urldiv.attr('id') + ",  " + urldiv.attr('appurl'));
	if (jQuery(urldiv).length > 0) {
		appurl = jQuery(urldiv).attr('appurl');
		//alert('appurl=' + appurl);
	}

	//make url to Ajax request drill down rows
	var qoramp = '?';
	 if(appurl.indexOf('?') >= 0) {
		qoramp = '&';
	}
	var url = appurl + qoramp + 'run=1&outfmt=21&level=' + thisLevel + parms + '&colspan=' + this.columes;
	url += this.options2.extraParms;
	var rowFormId = jQuery(theRow).parents('form').attr('id');
	url =  getRowDimensions(url, rowFormId);
	var debug = this.container.find('input[name=\'debug\']').val();
	url += '&debug=' + debug;
	var udview = this.container.find('input[name=\'use_udview\']').val();
	// url += '&use_udview=' + udview;

	//var cookie_name = this.container.find("input[name='cookie_name']").val();
	//if(cookie_name && cookie_name != ''){    url += '&cookie_name=' + cookie_name;}
	url += '&x=' + Math.random();
	//alert('3' + url);
	var report = this;
	jQuery.ajax({  url: url, dataType: 'html',
		success: function (data, textStatus) {	//Ajax success!

      	   //2007-08-28:BJ:redirect if seesion expired and data is signon page (26214)
    	   if (data.indexOf('"mrcsignon"') > 0 && data.indexOf('"mrcpswd"') > 0 ) {
    		   window.location.href = appurl;
    		   return;
    	   }

			var pos = data.indexOf('<i>Debug');
			if (pos > 0) { //extract debug info if exists
				var bugInfo = data.substring(pos);
				data = data.substring(0, pos);
				report.container.find('#debug-info').html(bugInfo);
			}
			report.container.find(theRow).after(data);
			report.setupDrill(); //setup drill down for next level
			report.setupPaging();//setup paging in the same level
			report.setupLinkWithParms();
		 try{
				setupPopupDialog();
			}catch(err) {

			}

	   },
	   error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
	     var errmsg = 'Error happened during Ajax drilldown: ';
	     errmsg += XMLHttpRequest.responseText;
	     errmsg = '<tr>' + errmsg + '</tr>';
			jQuery(theRow).html(errmsg);
	   },
	   complete: function (XMLHttpRequest, textStatus) { //Ajax completed
	      jQuery(theRow).find('.spin-img').hide();
	   }
	});

	return ;
};
/****************************************************************/
/** Setup paging records (in the same level) from Show More link */
/****************************************************************/
function callPaging (event, report) {
	event.preventDefault(); //supress click on a tag

	var url = jQuery(event.currentTarget).attr('href');
	var theRow = jQuery(event.currentTarget).parents('tr').eq(0); //get tr of 'show more'
	jQuery(theRow).find('.spin-img').show();
	//alert('4' + url);
	jQuery.ajax({  url: url, dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			jQuery(theRow).after(data).remove();
			report.setupDrill(); //setup drill down for next level
			report.setupPaging();//setup paging in the same level
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			var errmsg = 'Error happened during Ajax page down: ';
			errmsg += XMLHttpRequest.responseText;
			errmsg = '<tr>' + errmsg + '</tr>';
			jQuery(theRow).html(errmsg);
		},
		complete: function (XMLHttpRequest, textStatus) { //Ajax completed
			jQuery(theRow).find('.spin-img').hide();
		}
	});
	return false; //TW 2010-03-04 added for mobile jquery1.5 does not support the event.preventDefault() must return false
}
/****************************************************************/
/** Setup refresh page when image map clicked                   */
/****************************************************************/
MrcReport.prototype.setupImageMapCall = function () {
	var report = this;
	report.container.find('map area').click(function (event) {
		var url = jQuery(this).attr('href');
		if (url.indexOf('other=1') > 0) { //&other=1, go there
			return;
		}
		event.preventDefault(); //supress click on map area

		var firstkeyName = jQuery.valByNameFromQS('firstkey', url );
		if (firstkeyName) {
			firstkeyName = jQuery.trim(firstkeyName);
		}
		var firstkey = firstkeyName;
		var needDsp = '&q=1'; //need to dsp selection info when page load
		var rxxx = report.options2.appkeys[firstkeyName]; //R001, R002

		if (!rxxx) {
			return; //if 1st key not in selection, no graph drill down
		}

		//1st key is in selection, clear/grey selections before going to url
		firstkey = rxxx;
		needDsp = ''; //1st key is in selection, no need to dsp another one

		//alert(rxxx + "----" + url);
		var r001Val  = jQuery.valByNameFromQS('firstVal', url );
		r001Val = jQuery.trim(r001Val);
		r001Val = r001Val.replace(/,/g, '');
		r001Val = r001Val.replace(/ /g, '_%25_');
		var firstKeyPair = firstkey + '=' + r001Val ;
		var url = appUrl2 + '?' + firstKeyPair + report.parmValuesDrill(firstkey) + needDsp;
		var theid = '#sel_' + rxxx;

		if (report.options2.greyoutOn) {//smart logic
			refreshSelections({ menu_bodyId : theid, url_togo: url, firstKeyPair: firstKeyPair}, report);
		} else { //no smart logic, just drill down
			var otparms = report.parmValuesDrill(rxxx);
			otparms = otparms.replace(/_%_/g, '_%25_');//mrc encode for space
			var url = report.appUrl2 + '?imgdrill=y&' +  firstKeyPair + otparms;
			window.location = url;
		}

	});
};
/********************************************************************/
/** Setup link with page parms. When users paint a link in the
 * page the can add class=link_with_parms to include selected parms */
/********************************************************************/
MrcReport.prototype.setupLinkWithParms = function () {
	var report = this;
	this.container.find('.link_with_parms').click(function (event) {
		var url = jQuery(this).attr('href');
		event.preventDefault(); //supress click on map area
		var qora = '?';
		if (url.indexOf('?') > 0) {
			qora = '&';
		}
		var url = url + qora + report.parmValuesDrill( );
		window.location = url;
	});
};
/****************************************************************/
/** display selection when page is from image map click         */
/****************************************************************/
function dspSelectionForImageMap (url) {
	var needDsp = jQuery.valByNameFromQS('q', window.location.href);
	if (needDsp == '' ) {
		return;
	}

	var fkdiv = jQuery('#firstKeyDiv');
	var kname = fkdiv.attr('kname');

	if (url.indexOf('&' + kname) < 0) {
		return; //if url does not contain this key, skip
	}

	var kval = jQuery.valByNameFromQS(kname, window.location.href);
	jQuery('#firstKeyVal').text(kval);
	fkdiv.show();
	jQuery('#dlt_img_sel').click(function () { //click to go to main page with no selection
		var url = appUrl2 + '?z=1' + parmValuesDrill();
		//alert(url);
		window.location = url;
	});
}
/****************************************************************/
//   Initiallize input sliders if any
/****************************************************************/
function initSliders () {
	jQuery('.hideme').remove(); //remove by user

	var sliderDivs = jQuery('.slider-bar');
	if (!sliderDivs) {
	   return;
	}

	sliderDivs.each(function () {
	    var slider = jQuery(this);
	    var thisid = slider.attr('id').substring(6); //id = sliderR001...
		var min = parseInt(slider.attr('min'));
		var max = parseInt(slider.attr('max'));
		var step = parseInt(slider.attr('step'));
		var trigger_run = slider.hasClass('trigger_run');
		if (slider.hasClass('range') ) {
		   rangeSlider(thisid, min, max, step, trigger_run);
		} else {
		   singleSlider(thisid, min, max, step, trigger_run);
		}
	});
}
function blockUIandRun (formid) {//for call from ajax cascading drop down list
	var report = irtReports[formid];
	report.blockUIandRun(report.formid2, report.appUrl2, report.dataPaneId2);
}
/****************************************************************/
// Block UI and run report.
/****************************************************************/
MrcReport.prototype.blockUIandRun = function (formId, appUrl, dataPaneId) {
	if(this.isPivot == true) {
		var valid = this.getSubTotals();
		if(valid == true) {
			if(this.dashboard == 0) {
				blockBrowser(formId, appUrl, dataPaneId);
			}
			insertReportData(formId, appUrl, dataPaneId, this);
		}else{
			alert('At least one value field for totaling must be selected and set to display.');
		}
	}else{
		if(this.dashboard == 0) {
			blockBrowser(formId, appUrl, dataPaneId);
		}
		insertReportData(formId, appUrl, dataPaneId, this);
	 }
};
/****************************************************************/
//  Block UI
/****************************************************************/
function blockBrowser (formId, appUrl, dataPaneId) {
	  //block page at form submit
	  if(jQuery.blockUI) {
		jQuery.blockUI.defaults.css = {};
		jQuery.blockUI({  centerY: 0,
			css: { top: '250px', left: '380px', right: ''},
			message: '<div id="blockui_splash" ><img src="/mrcjava/image/maploader.gif" /> &nbsp; Loading data, please wait ...</div>'
		});
		return;
	  }
	var html = '<div class="loading_ajax" style="z-index:9999;">Processing<br/><br/><i class="fa fa-spinner fa-spin"></i></div>';
	jQuery('#' + formId).find('#mrcMainContent').append(html);
}
function unblockBrowser () {
	if(jQuery.blockUI) {
		jQuery.unblockUI();
		return;
	}
	jQuery('.loading_ajax').remove();
}
/****************************************************************/
// Set graph position for PDF.
/****************************************************************/
function graphPosition () {
	var top0 = jQuery(graphHolderId).css('top');
	var left0 = jQuery(graphHolderId).css('left');
	if (top0 == 'auto' || left0 == 'auto') {
   	   return;  //graph location not changed
	}
	jQuery('#graphtop').val('0');
	jQuery('#graphleft').val(left0);
}
/****************************************************************/
//  Get report data and insert data to passed element Id
/****************************************************************/
function insertReportData (formId, appUrl, dataPaneId, report) {

	var parms = parmValues(formId, report);
	if(report.dashboard == 1) {
		Dashboard.submitSelectionDialog();
		setTimeout(function () {unblockBrowser();}, 200);
		return;
	}
	var container = jQuery('#' + formId).parent();
	if(report.isPivot == true) {
		parms += '&pivot=y';
	}
	var qoramp = '?';
	if(appUrl.indexOf('?') >= 0) {
		qoramp = '&';
	}

	var url = appUrl + qoramp + 'onepage_data=1&' +  parms + '&x=' + Math.random();
	var urlparm = window.location.search.substring(1);
	try{//mod 2012-03-15 mrcTW fix for 17707 - call to window.parent.document was getting perssion denied if loaded in cross domain iframe
	  var previewMaxRows = jQuery('.max_rows_list > li.selected', window.parent.document).attr('max_rows');
	  if(previewMaxRows && previewMaxRows != '') {
			url += '&max_preview_rows=' + previewMaxRows;
	  }
	}catch(err) {

	}
	//has key name rather than R001 and is image drill
	if (urlparm && urlparm.indexOf('q=1') > 0 && _imageDrill) {
		var pos = urlparm.indexOf('&');
		url += '&' + urlparm.substring(0, pos); //add name=val only
	}
	url = getRowDimensions(url, formId);
	url = url.replace(/_%_/g, '_%25_');//mrc encode for space in select list
	var udview = report.container.find('input[name=\'use_udview\']').val();
	// url += '&use_udview=' + udview;
	//alert(url  );
	if(report.isPivot == true) {
		url = url.replace('&run=0', '');
		url += '&is_IRT=Y'; //added for 23156 - pivot saved seach needs this
	}
	var modeselect = report.container.find('input[name=mode_select]:checked').val();
	url += '&mode_select=' + modeselect;
    //alert('5'+url);
    var qIndex = url.indexOf("?");
    var app = url.substring(0, qIndex);
    var data = url.substring(qIndex + 1);
	jQuery.ajax({ url: app,data:data, dataType: 'html', type:'POST',
		success: function (data, textStatus) {	//Ajax success!
			report.displayData(data);
			if(report.isPivot == true) {
				report.setInitialParms();
				report.container.find('#report-graph').show();
				report.container.find('#pivotContainer').show();
				report.resetDimensions();
				setupPivotSorting(report);
			}
			setExpandCollapse(report, modeselect);
			try{
                irtCallback();
                resetJSFeatures();
				report.container.find('.fixed').fixedtableheader();
			}catch(err) {}
			try{
				columnToggle.setColumnsSelected(report.container.find('form'));
			}catch(err) {}
	    report.otherParms = report.parmValuesDrill();  //get parms for drill down
	 },
	 error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
	   var errmsg = 'Error happened during Ajax request: ';
	   errmsg += XMLHttpRequest.responseText;
			jQuery(report.datapaneIdj).html(errmsg);
	 },
	 complete: function (XMLHttpRequest, textStatus) { //Ajax completed

			/* setup customer links that need include page parms */
			report.setupLinkWithParms();

			/* Hide/show menu pane   */
			SetupMenuHideShow(container);

	   /* Unblock page when Ajax complete */
	   if (report.container.find('form #btn_search').length == 0 ||
	           report.container.find('#isinit').val() == '1' ||
	           !report.options2.greyoutOn) {
 	       setTimeout(function () {unblockBrowser();}, 200);
			}

			/* no selections, unblock */
			if (jQuery.jsonIsEmpty(report.options2.appkeys)) {
				setTimeout(function () {unblockBrowser();}, 200);
			}

	  report.container.find('#reorder').val('N');
			//report.container.find('#sort_typ').val('');
			//  report.container.find('#cur_sort_col').val('');
			report.container.find('#isinit').val('0');
			report.container.find('#imgdrill').val('n');

	   //make graph graggable
	   //jQuery(graphHolderId).draggable({cursor: 'move'});

	   /*show runtime selections ,  setup Runtime Remove */
	   report.container.find('#runtime_select').show();
	   if (report.container.find('#runtime_select').length) {
	       report.container.find('#sel_criteria_text').show();
	   }

	   report.setupRuntimeRemove();
	 }
	});

	return ;
}
function setExpandCollapse (report, modeselect) {
	if(modeselect == 1) {
		var numDimensions = report.container.find('#selected-dimensions li').length;
		var classname = 'sub' + numDimensions + 'Background';
		report.container.find('tr.'  + classname).attr('shown', '').attr('loaded', '').find('img.drill_icon').attr('src', row_collapsed_img);

	}
}
function getRowDimensions (url, formid) {
	var dimensionParms = '';
	var i = 1;
	jQuery('#' + formid).find('.selected-dimensions li').each(function () {
		var value = encodeURIComponent(jQuery(this).attr('data-field'));
		dimensionParms += '&R' + i + '=' + value;
		i++;
	});
	url += dimensionParms;
	return url;
}
/****************************************************************/
//   Display data sent by server.
/****************************************************************/
MrcReport.prototype.displayData = function (data) {

	var dataPane = this.container.find(this.datapaneIdj).html();
	this.container.find(this.datapaneIdj).html(data);

	var error = data.indexOf('displayError');
	if(this.isPivot == true && error > 0) {
		this.container.find(this.datapaneIdj).html(dataPane);
	}

	//Setup drill down from subtotal rows
	this.setupDrill();

	//Setup refresh page when image map clicked
	this.setupImageMapCall();

	if (jQuery.jsonIsEmpty(this.options2.appkeys)) {
		if(this.isPivot == false) {
			this.container.find('map').remove(); //no img drill if 1st key not in R000
			return; //no selection, skip refresh selection
		  }
	}

	//Refresh selections if 1st key is in selection
	var kname = this.container.find('#firstKeyDiv').attr('kname');
	var rxxx = this.options2.appkeys[kname]; //R001, R002
	if (this.options2.keyrls) {
		var rls = this.options2.keyrls[kname];
		if (rls && (rls != 'EQ' && rls != 'LS')) {
			if(this.isPivot == false) {
				this.container.find('map').remove();
			}
		}
	}

	var imgdrlval = this.container.find('#imgdrill').val();
	var isinit = this.container.find('#isinit').val();
	if (!rxxx) {
		var rxxx0 = this.firingId;
		/*    if (!rxxx0) {//no firing id, just grab the first one
            var menus = jQuery('.menu_body') ;
            if (menus.length > 0) {
                rxxx0 = menus.eq(0).attr('id').substring(4)
            }
        }*/
		rxxx = rxxx0; //if first key not in selection, still do smart logic
		//if 1st key not in Selections (not R00n), remove img map
		if(this.isPivot == false) {
			this.container.find('map').remove();
		}
	}

	if (rxxx) {
		if ( this.options2.greyoutOn && isinit) {
			//  if (isinit == '1' && imgdrlval == 'y') {
			if (isinit == '1'  ) {
				refreshSelections2({ menu_bodyId : rxxx}, this);
			} else  if (isinit != '1') {
				if (this.container.find('form #btn_search').length == 1) { //has run button
					refreshSelections2({ menu_bodyId : rxxx}, this);
				} else if (this.container.find('form #btn_search').length == 0  ) {//no run button
					//run from enter key
					if (this._enterKeyPressed) {
						this._enterKeyPressed = false;
						refreshSelections2({ menu_bodyId : rxxx}, this);
					}
				} else {
					setTimeout(function () {unblockBrowser();}, 200);
				}
			}
		}
		return;
	}

	setTimeout(function () {unblockBrowser();}, 200);
};
/****************************************************************/
//   Display errors sent by server. Called from Ajax response.
/****************************************************************/
function displayError (errors) {
	_hasEditError = false;

	if (!errors) {
		return;
	}

	_hasEditError = true; //tell not do greyout

	for (var i = 0; i < errors.length; i++) {
		var msg = errors[i];
		if (!msg || msg == '') {
	    continue;
	 }
	 var errid = '#msg_R00' + (1 + i);
	 if (i >= 9) {
	    errid = '#msg_R0' + (1 + i);
	 }
	 jQuery(errid).text(msg);
		jQuery(this.datapaneIdj).html('');
	}
}
/****************************************************************/
//   Setup for runtime selection removal when user delete selection from data pane
/****************************************************************/
MrcReport.prototype.setupRuntimeRemove = function () {
	var report = this;
	report.container.find('.dlt_sel').each(function () {
		var imgspan = jQuery(this);
		var theid0 = imgspan.attr('id');
		var theid = '#sel_' + theid0.substring(4);
		imgspan.click(function () {
	    isRuntimeRemoval = true;

	    // clear selected
	    clearSelected(theid, report);

	    // hide this selection
	    imgspan.fadeOut(1000);

	    // clear/grey for selections, and run query
	    if ( report.options2.greyoutOn ) {
	        refreshSelections({ menu_bodyId : theid}, report);
	    // no refresh, just run query
	    } else if (report.trigger_run_query) {

	       report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneIdj);
	    }
		});
	});
};

/*****************************************************************/
//  Clear selected items inside a menu_body (called from data pane)
//  <div class="menu_body"  id="sel_R004">
/****************************************************************/
function clearSelected (theid, report) {

	//reset select list
	clearSelectedSelectList(theid, report);

	//reset input or input with slider
	var rid = theid.substring(5); //R001..
	var rid2 = rid.replaceAll("R","S");
	var sid = '#slider' + rid;
    if (report.container.find(sid).length == 0) { //not slider
        if(report.container.find(theid).find('.asmListItemRemove').length > 0){
            report.container.find('.asmListItemRemove').trigger("click");
            return;
        }
		report.container.find(theid + ' .text_input').val('');
		report.container.find('input.' + rid).val(''); //clear LS/NS inputs
        report.container.find('input#' + rid).val(''); //clear LS/NS inputs
        report.container.find('input#' + rid2).val(''); //clear LS/NS inputs

       
	} else { //slider, reset min/max values
		var min = report.container.find(sid).slider('option', 'min');
		var max = report.container.find(sid).slider('option', 'max');
		var inputSel = 'input[name=\'' + rid + '\']';
		var inputs = jQuery(inputSel);
		if (inputs && inputs.length == 1) { //single slider
			jQuery(inputs.val(min));
			report.container.find(sid).slider('option', 'value', min);
		} else  if (inputs && inputs.length == 2) { //range slider
			jQuery(inputs[0]).val(min);
			jQuery(inputs[1]).val(max);
			report.container.find(sid).slider('option', 'min', min);
			report.container.find(sid).slider('option', 'max', max);
			report.container.find(sid).slider( 'values', 0, min);
			report.container.find(sid).slider( 'values', 1, max);
		}
	}

	//reset converted list divs and their inputs
	var listDivs = report.container.find( theid + ' .list_div');
	if (listDivs.length > 0) {
		listDivs.each(function () {
			//jQuery(this).textListUnclick();
			jQuery(this).bg('').css('font-weight', 400);
		});
		var rid = theid.substring(5); //R001
		var inp = '#' + rid + 'Id';
		report.container.find(inp).val('');
	}

	//reset converted checkboxes / radios
	var chkDivs = report.container.find( theid + ' input');
	if (chkDivs.length > 0) {
    	chkDivs.each(function () {
			jQuery(this).attr('checked', false);
		});
	}


}
/*****************************************************************/
//  Clear selected items inside a menu_body (called from Ajax referesh)
//  <div class="menu_body"  id="sel_R004">
/****************************************************************/
function clearSelected2 (theid, report) {

	//reset select list
	clearSelectedSelectList(theid, report);

	//reset input fields
	//jQuery(theid + ' input[type=text]').val('');
	report.container.find(theid + ' .text_input').val('');

	//reset converted list divs and their inputs
	report.container.find(theid + ' .list_div').each(function () {
		jQuery(this).bg('');
		jQuery(this).css('font-weight', 400);
		var theId = jQuery(this).parent().attr('id');
		var inp = '#' + theId + 'Id'; //the created hidden input - R001Id
		report.container.find(inp).val('');

	});
	//reset converted checkboxes / radios
	report.container.find(theid + ' :checked').attr('checked', false);
}
/*********************************************/
//  Clear selected select list inside a menu_body
//  <div class="menu_body"  id="sel_R004">
/**********************************************/
function clearSelectedSelectList (theid, report) {

	//reset mrc regular select
    var mrcSelect =   theid + ' select';
    
    if(report.container.find(mrcSelect).hasClass('asmSelect')) return;
    
	report.container.find(mrcSelect).val('');
	var mrcOpt = mrcSelect + ' option';
	report.container.find(mrcOpt).each(function () {
		if (jQuery(this).attr('disabled')) {
			jQuery(this).removeClass(optionDisabledClass).attr('disabled', false);
		}
	});

	//reset asm select
	var asmSelect =   theid + ' select.asmSelect';
	report.container.find(asmSelect).val('');

	//remove asm list item from ol list
	var ol =   theid + ' ol.asmList';
	var olliItems = ol + ' li';
	report.container.find(olliItems).slideUp('fast', function () {
		jQuery(this).remove();
	});
}
/*****************************************************************/
//  Reset background after removing selected from data pane.
//  <div class="menu_body"  id="sel_R004">
/****************************************************************/
function clearSelectedBg (theid) {
	jQuery(theid + ' .list_div').each(function () {
		jQuery(this).bg('');
	});

	//reset converted checkboxes / radios
	jQuery(theid + ' :checked').next().bg('');
}
/*****************************************************************/
//  Get report parm values from form inputs
/****************************************************************/
function parmValues (formId, report) {
	if(report.isPivot == true) {
		var get = report.getSubTotals();
	}
	var valueUrl = '';
	var ids = '#' + formId + ' input,' + '#' + formId + ' select';
	var saved = [];

	report.container.find(ids).each(function (index) {
	  var fieldName = jQuery(this).attr('name'); 
	  var fieldNameEn = fieldName;
	  if (fieldName) {
		  fieldNameEn = fieldName.replace(/#/g,"\\#"); /*20180906:BJ 27763 */
	  }
	  var sels = report.container.find('#sel_' + fieldNameEn + ' select.mrcselect');//chk select
	  if (sels.length == 0) {
			sels = report.container.find('input[name=\'' + fieldName + '\']'); //chk inputs
	  }

	  var pair = parmPair(fieldName, this);
	  var pairInSaved = false;
	  for (var i in saved) {
	      //do not add dup parm (pair: R001=A%20B, saved[i]: R001=A
	      if ( pair == saved[i] || (pair.indexOf(saved[i]) == 0 && pair.indexOf('%20') > 0) ) {
	         if (sels.length != 2  ) { //for range allow dup
	           pairInSaved = true;
	         }
			}
	  }
	  if (!pairInSaved && pair.length > 0) {
			valueUrl += pair;
			saved.push(pair);
	  }

		var thisId = jQuery(this).attr('id');
		//var errid = "#msg_" + thisId; 	 //remove err msg if any
		//report.container.find(errid).text("");
		//2017-08-07:BJ:use plain js to handle $ in id (26304)
		var errid = 'msg_' + thisId;
		var e1 = document.getElementById(errid);
		if (e1) {
    	  e1.textContent = '';
		}

	});

	return valueUrl;
}
/****************************************************************/
//  Get report parm values from form inputs for Ajax drill down
/****************************************************************/
MrcReport.prototype.parmValuesDrill = function (firstkey) {
	var valueUrl = '';
	var ids = '#' + this.formId2 + ' input,' + '#' + this.formId2 + ' select';
	this.container.find(ids).each(function (index) {
	 var fieldName = jQuery(this).attr('name'); //R001, R002
	 if (fieldName) {
	   if (jQuery.isR0nn(fieldName) || fieldName.indexOf('CALCULA') == 0
			   || fieldName.indexOf('rls_') == 0 || fieldName.indexOf('compare') == 0
			   || fieldName.indexOf('compVal') == 0) {
	     var pair = parmPair(fieldName, this);
	     if (firstkey  && fieldName == firstkey) {
	         pair = ''; //image map link, do not incl R0001
	     }
	     valueUrl += pair;
	   }
	 }
	});
	return valueUrl;
};
/****************************************************************/
//  Get a pair of field name/value
/****************************************************************/
function parmPair (fieldName, InputField) {
	var inp = jQuery(InputField);
	if (!fieldName) {
		return ''; //2011-09-14, for jQuery 1.6.4
	}
	if ( inp.prop('type') == 'radio' && !inp.prop('checked')) {
		return ''; // uncheck radios
	} else if ( inp.prop('type') == 'checkbox' && !inp.prop('checked')) {
		return ''; // uncheck checkbox
	} else if ( fieldName.indexOf('asm') >= 0) {
		return ''; // asm select field
	}
	var fldVal = inputFieldValue(InputField);
	if (!fldVal || fldVal == '') {
		return '';
	}
	//fldVal = fldVal.replace(/&/g, '%26')
	var pair = '&' + encodeURIComponent(fieldName) + '=' + fldVal;
	return pair;
}

/****************************************************************/
//  Get input field value of one field.
/****************************************************************/
function inputFieldValue (InputField) {
	var value = '';
	var inp = jQuery(InputField);
	var inpval = inp.val();
	if (!inp || !inpval) {
		return '';
	}

	var fieldName = inp.attr('name');
	var type = inp.attr('type');
	//Detail, subtotal checkbox fields:
	if (type && type == 'checkbox' && fieldName && fieldName.indexOf('_') > 0) {
		var cked = inp.attr('checked'); //return true or false
	 var chkVal = cked ? '1' : '0';
	 return chkVal;
	}
	if(fieldName == 'udspecjson') {//2015-02-11
		return inpval;
	}
	if (!jQuery.isArray(inpval) ) {
		inpval = encodeURIComponent(inpval);
		return inpval;
	}

	var spc = '';
	jQuery(inpval).each(function (index, val) {
		val = encodeURIComponent(val);
		value += spc + val  ;
		spc = '%20';
	});
	return value;
}

/****************************************************************/
//  Create a jquery single slider.
/****************************************************************/
function singleSlider (id, min, max, step, trigger_run) {
	var inputSel = 'input[name=\'' + id + '\']';
	jQuery(inputSel).val(min);
	var inpId = '#' + id;
	var sldId = '#slider' + id;
	jQuery(sldId).slider({ min: min, max: max, step: step,
	   slide: function (event, ui) { jQuery(inpId).val(ui.value); },
	   stop: function (event, ui) {
		   if (jQuery('form #btn_search').length == 0) { //no run button
				blockUIandRun(formId2, appUrl2, datapaneId2);
		   }
	   }
	});
}
/****************************************************************/
//  Create a jquery range slider.
/****************************************************************/
function rangeSlider (id, min, max, step, trigger_run) {
	var inputSel = 'input[name=\'' + id + '\']';
	var inputs = jQuery(inputSel);
	if (inputs && inputs.length == 2) {
	   jQuery(inputs[0]).val(min);
	   jQuery(inputs[1]).val(max);
	}
	//alert(inputs + ",  " + inputs[1]);
	var inpId = '#' + id;
	var sldId = '#slider' + id;
	jQuery(sldId).slider({range:true,  min: min, max: max,  step: step,
	    values: [min, max],
		slide: function (event, ui) {
		   jQuery(inputs[0]).val(ui.values[0]);
		   jQuery(inputs[1]).val(ui.values[1]);
		},
		stop: function (event, ui) {
		    if (jQuery('form #btn_search').length == 0) { //no run button
				blockUIandRun(formId2, appUrl2, datapaneId2);
		   }
		}
	});
}

/*****************************************************************************/
/* Create checkbox/radio/ul inputs from select list  if any                                                                */
/******************************************************************************/
MrcReport.prototype.convertList = function () {
	var report = this;
	/* Create radio inputs from select list */
	this.container.find('.dsp_radio > select').each(function () {
		var radioDiv = makeInputs(this, 'radio');
		radioDiv.appendTo( jQuery(this).parent()); //append to <div class=dsp_radio>
		jQuery(this).remove(); //remove select list
	});

	/* Create checkbox inputs from select list */
	this.container.find('.dsp_check  select').each(function () {

		var checkDiv = makeInputs(this, 'checkbox');
		/* append to <div class=dsp_check> or <div class=dsp_check><span> */
		checkDiv.appendTo( jQuery(this).parent());
		var itemWidth =  jQuery(this).parents('div.dsp_check').attr('itemwidth');
		if (itemWidth ) {
		    jQuery(this).parent().find('label').css('width', itemWidth);
		    //jQuery(this).parents('div.dsp_check').find('label').css('width',itemWidth);
		}
		var thisId = jQuery(this).attr('id');
	   // alert("dsp-check:" + jQuery(this).html());
		jQuery(this).addClass('removed').remove(); //remove select list

	});


	/* Create simple div list from select list */
	this.container.find('.dsp_list  select').each(function () {
		var itemWidth =  jQuery(this).parents('div.dsp_list').attr('itemwidth');
		var itemPerRow = jQuery(this).parents('div.dsp_list').attr('itemsperrow');

		if (!itemPerRow) {
			itemPerRow = '5'; //default
		}
		var itemPerRowInt = parseInt(itemPerRow);

		var fldName =  jQuery(this).attr('name');
		var aDiv = jQuery('<div />').attr('id', fldName);
		var selectedVal = '';
		var aSpace = '';
		var item = 0;
		var qs = window.location.search ;

		jQuery(this).children().each(function (i, val) {
			var option = jQuery(this);
			var optionVal = option.val();
		  if (optionVal && optionVal != ' ') {
		      if (option.attr('selected')) {
		          selectedVal += aSpace + optionVal;
		          aSpace = ' ';
		      }
		      //2016-01-11- 25597 move this line down to fix sequencing of LS value encoding
		      optionVal = optionVal.replace(/_%_/g, ' ');//mrc encode for space 2017-01-04 - 25597 - move this line down to fix button list in saved saerch
		      var listDiv = jQuery(' <div />' ).attr('oval', optionVal ).text( option.text() ).addClass('list_div');

		      if (option.attr('selected') ) {//has qs
		          listDiv.bg(GREEN_COLOR); //only do it if not origial request
		      }
		      item++;
				var br = jQuery('<span />');
				if (item >= itemPerRow) {
					br = jQuery('<br />');
					item = 0;
				}
	          listDiv.appendTo( aDiv ).after(br);

	      }
		});
	   aDiv.appendTo( jQuery(this).parent() );

	   if (itemWidth) {
	      jQuery(this).parent().find('.list_div').css('width', itemWidth);
	   }
	   jQuery(this).remove(); //remove select list
	   //create a hidden input for this list
	   //var inputhd = = jQuery("<input  />");  //chg 1.4.2
	   var inputhd = jQuery(' <input type=hidden />' ).attr(
	        { 'value':selectedVal, 'name':fldName, 'id':fldName + 'Id'});
	   report.container.find('#hiddenfields' ).after(inputhd);

	});
};
MrcReport.prototype.updateSelectLists = function (inputId, value) {
	var report = this;
	//jQuery("#FORM_DBOARD").find("input[name='${prop("chartClickValue")}']").val(value);//.submit();
	/* Create radio inputs from select list */
	var inputContainer = this.container.find('#sel_' + inputId);
	if(inputContainer.hasClass('dsp_list')) {
		this.container.find('input[name=\'' + inputId + '\']').val(value);
		inputContainer.find('.list_div').css('font-weight', 400);
		inputContainer.find('div[oval=\'' + value + '\']' ).css('font-weight', 700);
	}else if(inputContainer.hasClass('dsp_check') ||  inputContainer.hasClass('dsp_radio') ) {
		inputContainer.find(':input[name=\'' + inputId + '\']').prop('checked', false);
		inputContainer.find(':input[name=\'' + inputId + '\'][value=\'' + value + '\']').prop('checked', true);
	}else if(inputContainer.find('#div' + inputId).hasClass('multiple-select') ) {
		this.container.find(':input[oid=\'' + inputId + '\'] option').removeAttr('selected');
		this.container.find(':input[oid=\'' + inputId + '\'] option[value=\'' + value + '\']').attr('selected', 'selected').trigger('change');
		return;
	}else{
		this.container.find(':input[name=\'' + inputId + '\']').val(value);
	}
	jQuery('#FORM_DBOARD').submit();
};
/****************************************************************/
/* Create checkbox/radio inputs from select list */
/****************************************************************/
function makeInputs (select, inpType) {

	var dspType = 'div.dsp_check';
	if (inpType == 'radio') {
		dspType = 'div.dsp_radio';
	}
	var itemPerRow =  jQuery(select).parents(dspType).attr('itemsperrow');
	if (!itemPerRow) {
		itemPerRow = '5'; //default
	}
	var itemPerRowInt = parseInt(itemPerRow);
	var fldName =  jQuery(select).attr('name');
	var inpDiv = jQuery('<div />');
	var item = 0;
	jQuery(select).children().each(function (i, val) {
		var option = jQuery(this);
		var tempId = fldName + i;
		var inpstr = ' <input type=' + inpType + ' />';
	   var inputRadio = jQuery(inpstr )
	         .attr( { 'value':option.val(), 'name':fldName, 'id':tempId});

	   var aLabel = jQuery('<label />').attr('for', tempId).html(option.text() + ' &nbsp; ');

	   if (option.val() && option.val() != ' ') {
	       item++;
	       var br = jQuery('<span />');
			if (item >= itemPerRow) {
				br = jQuery('<br />');
				item = 0;
			}
	   	   inputRadio.appendTo( inpDiv ).after(br).after(aLabel);
	   	   if (option.attr('selected')) {
				inputRadio.attr('checked', true);
				inputRadio.next().bg(GREEN_COLOR);
			}
	   }
	});
	return inpDiv;
}

/*****************************************************************************/
//  Sort column when advanced sort is on
/*****************************************************************************/
function sortCol (col, typ) {  // for advanced sort

	//  var oForm = jQuery('#' + formId2);
	var oForm = jQuery('form');
	jQuery('#reorder').val('Y');
	jQuery('#sort_typ').val(typ);
	jQuery('#cur_sort_col').val(col);
	oForm.submit();
}
function sendTable (format, element) {

	var $el = jQuery('<div>');
	$el.append(jQuery('.outputTable').clone());
	$el.find('.drill_icon').remove();
	$el.find('.spin-img').remove();
	$el.find('.hideme').remove(); //25608 - 2017-01-04 - remove hidden values in blank cells before exporting
	$el.find('img').remove();
    $el.find('.drill-more').remove();
    $el.find('*:not([colspan])').removeAttributes();
    $el.find('script').remove();

    var myHTML = $el.html();
    myHTML= myHTML.replace(/>\s+</g,'><');
    
    var myForm = jQuery('form').get(0);
	if(element) {
		reportForm = jQuery(element).closest('form').get(0);
	}
	reportForm.runtimeHTML.value = encodeURIComponent(myHTML);
	reportForm.runtimeFormat.value = format;
	reportForm.submit();
	reportForm.runtimeHTML.value = '';
	reportForm.runtimeFormat.value = '';

}
/****************************************************************/
//  Set desc or asc sort image
/****************************************************************/
var s1 = new Image(); s1.src = '../image/ascg.gif';
var s0 = new Image(); s0.src = '../image/descg.gif';
function setSortImage (names) { // for set grey sort images
	for (var i = 0; i < names.length; i++) {
		var im = names[i];
		if (document.images[im]) {
			if (im.indexOf('s0') == 0) {
				document.images[im].src =  s0.src ;
			} else {
				document.images[im].src =  s1.src ;
			}
		}
	}
}
/*****************************************************************************/
//  jQuery plugins
/*****************************************************************************/
/*************************************************/
/* Set background-color                          */
/*************************************************/
(function ($) {

	$.fn.bg = function (color) {
		$(this).css('background-color', color);
		return $(this);
	};
	/*************************************************/
	/* check if element is green                     */
	/*************************************************/
	$.fn.isGreen = function () {
		var yesIs = false;
		var bg = $(this).css('background-color');
		if (bg.indexOf('6f9') > 0 ||  bg.indexOf('102') > 0 ) {
			yesIs = true;
		}
		return yesIs;
	};
	/*************************************************/
	/* check if element is bold                      */
	/*************************************************/
	$.fn.isBold = function () {
		var yesIs = false;
		var bg = $(this).css('font-weight');
		if (bg == '700' || bg == 'bold' ) {
			yesIs = true;
		}
		return yesIs;
	};
	/*************************************************/
	/* Disable a form element                        */
	/*************************************************/
	$.fn.disable = function () {
		$(this).attr('disabled', 'disabled');
		return this;
	};
	/*************************************************/
	/* List_div click handler                        */
	/*************************************************/
	$.fn.textListclick = function (report) {
		//first check if need to remove other selected for EQ
		$(this).textListCheckEq(report);

		//$(this).bg(GREEN_COLOR);
		var theId = $(this).parent().attr('id');
		var inp = '#' + theId + 'Id'; //the created hidden input - R001Id
		var thisTxt =  $(this).attr('oval');//text();
		thisTxt = thisTxt.replace(/ /g, '_%_');

		var expr = '\\b' + thisTxt + '\\b';
		if (report.container.find(inp).val().search(expr) >= 0) { //value already in there
			return $(this);
		}

		var newVal = thisTxt + ' ' + report.container.find(inp).val();
		report.container.find(inp).val(newVal);
		return $(this);
	};
	/*************************************************/
	/* List_div unclick handler                      */
	/*************************************************/
	$.fn.textListUnclick = function (report) {
		$(this).bg('');
		$(this).css('font-weight', 400);
		var theId = $(this).parent().attr('id');
		var inp = '#' + theId + 'Id'; //the created hidden input - R001Id
		var newVal = report.container.find(inp).val();
		var inpVal = $(this).attr('oval');//text();
		inpVal = inpVal.replace(/ /g, '_%_');
		var origInpVal = inpVal;
		inpVal = escapeRegExp(inpVal);
		var expr = new RegExp('\\b' + inpVal + '\\b');
		
		if(inpVal !== origInpVal){
			expr = new RegExp(inpVal);
		}
		
		newVal = newVal.replace(expr, ' ');
		report.container.find(inp).val(newVal);
		return $(this);
	};
    function escapeRegExp(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
	/*************************************************/
	/* Check if list has selected (is green)         */
	/*************************************************/
	$.fn.textListHasSelected = function () {
		var texts = $(this).find('.list_div');
		var hasSelected = false;
		$(texts).each(function () {
			if ($(this).isGreen()) {
				hasSelected = true;
				return false;
			}
		});
		return hasSelected;
	};

	/*************************************************/
	/*  check List_div if select relation is 'EQ',    */
	/* if yes we need to unckeck other selected.     */
	/*************************************************/
	$.fn.textListCheckEq = function (report) {
		var theId =  $(this).parent().attr('id');
		var kname = jQuery.fldNameFromRxxx(theId, report.options2.appkeys);
		var rls = report.options2.keyrls[kname];
		if (rls != 'EQ') {
			return $(this);  //no need to check if not EQ
		}
		//Reset input value
		var inp = '#' + theId + 'Id'; //the created hidden input - R001Id
		var inpVal = $(this).attr('oval');//text();
		report.container.find(inp).val(''); // set value to the selected
		//remove color if any
		report.container.find('#' + theId + ' .list_div').each(function () {
			jQuery(this).bg('');
		});

		return $(this);
	};
	/*************************************************/
	/* check checkbox list if select relation is 'EQ'*/
	/* if yes we need to unckeck other selected.     */
	/*************************************************/
	$.fn.checkListCheckEq = function (report) {
		var theId = $(this).attr('name'); //R001...
		if (!theId) return $(this);
		var kname = jQuery.fldNameFromRxxx(theId, report.options2.appkeys);
		var rls = report.options2.keyrls[kname];
		if (rls == 'LS' || rls == 'NS') {
			return $(this);  //no need to check if not EQ
		}
		//Reset all checkboxes
		report.container.find('#sel_' + theId + ' input:checkbox').each(function () {
			$(this).next().bg('');
			$(this).attr('checked', false);
		});

		//set the clicked one
		$(this).next().bg(GREEN_COLOR);
		$(this).attr('checked', true);

		return $(this);
	};
	/*************************************************/
	/* check checkbox list if select relation is 'EQ'*/
	/* For with run button situation                 */
	/*************************************************/
	$.fn.checkListCheckEqWithButton = function (report) {
		var theId = $(this).attr('name'); //R001...
		if (!theId) return $(this);
		var kname = jQuery.fldNameFromRxxx(theId, report.options2.appkeys);
		var rls = report.options2.keyrls[kname];
		if (rls == 'LS' || rls == 'NS') {
			return $(this);  //no need to check if not EQ
		}
		//Reset all checkboxes
		report.container.find('#sel_' + theId + ' input:checkbox').each(function () {
			$(this).attr('checked', false);
		});

		//set the clicked one
		$(this).attr('checked', true);
		return $(this);
	};
	/************************************************************/
	//  Get parm value from querystring
	/************************************************************/
	$.valByNameFromQS = function ( name, qs ) {
		name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
		var regexS = '[\\?&]' + name + '=([^&#]*)';
		var regex = new RegExp( regexS );
		var results = regex.exec( qs );
		if( results == null )
			return '';
		else
			return results[1];
	};
	/************************************************************/
	//  Check if a JSON object is empty
	/************************************************************/
	$.jsonIsEmpty = function (obj) {
		for(var i in obj) {
			return false;
		}
		return true;
	};
	/************************************************************/
	//  Return field name given R001...
	/************************************************************/
	$.fldNameFromRxxx = function (rxxx, obj) {
		var namei = null;
		for(var name in obj) {
			var rxxxi = obj[name];
			if (rxxxi == rxxx) {
				namei = name;
				break;
			}
		}
		//alert(namei + '===' + rxxx);
		return namei;
	};
	/************************************************************/
	//  Check if String is of form R0nn (nn is 2-digits)
	/************************************************************/
	$.isR0nn = function (rxxxStr) {
		var pattern = /^R0\d{2}$/; // match 'R0dd' - d is a difit
		var yesOrNo = pattern.test(rxxxStr);
		return yesOrNo;
    };
    jQuery.fn.removeAttributes = function() {
        return this.each(function() {
          var attributes = jQuery.map(this.attributes, function(item) {
            return item.name;
          });
          var $el = $(this);
          jQuery.each(attributes, function(i, item) {
            $el.removeAttr(item);
          });
        });
      }
})(jQuery);
