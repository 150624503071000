/**
 * Handle form tab paging and events
 */
const formTabs = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	jQuery('body')
		.on('click', '.save-proceed', nextTabAndSave)
		.on('click', '.save-complete', saveAndComplete)
		.on('click', '.previous-tab', previousTab)
		.on('click', '.proceed-next-tab', function(e){
			var $buttonClicked = jQuery(e.currentTarget);
			nextTab($buttonClicked);
		});

	function nextTab ($button) {
		var $tab = $button.closest('.tab-pane').next();
		if($tab) {
			var href = $tab.attr('id');
			// @ts-ignore
			jQuery('.nav-link[href="#' + href + '"]').tab('show');
		}

	}
	function saveAndComplete (e){
		var $form = jQuery(e.currentTarget).closest('form');
		var currentAction = $form.find('#action_mode').val();
		if(currentAction.substring(0,1) != 'D'){
			$form.find('#action_mode').val('URD');
		}
		$form.append('<input name="forceupdate" type="hidden" value="1"/>');
		$form.get(0).submit();
	}
	function nextTabAndSave (e, saveAndComplete) {
		var $buttonClicked = jQuery(e.currentTarget),
			$form = $buttonClicked.closest('form'),
			action = $form.attr('action'),
			keyList = $form.find('#keylist').val(),
			$tab =  jQuery(e.currentTarget).closest('.tab-pane');
		var parms = getKeyValues(keyList, $form);
		var extraParms = $tab.find(":input").filter(function(){
			return ( jQuery(this).closest('#hiddenfields').length == 0)
		}).serialize();
		extraParms += '&one_step=1&gotoadd=1&action_mode=update&x=' + Math.random();
		parms += '&' + extraParms;

		jQuery.ajax({
			url:action,
			type:'GET',
			data: parms,
			success:function(data){
				nextTab($buttonClicked);
			}
		})
	}
	function getKeyValues (keyList, $form){
		var url = '',
			listAry = keyList.split(',');
		for(var i = 0; i < listAry.length; i++){
			var value = $form.find('#' + listAry[i]).val();
			url = i > 0 ? (url + '&') : url;
			url += listAry[i] + '=' + value;
		}
		return url;
	}
	function previousTab (e) {
		var $tab = jQuery(e.currentTarget).closest('.tab-pane').prev();
		if($tab) {
			var href = $tab.attr('id');
			// @ts-ignore
			jQuery('.nav-link[href="#' + href + '"]').tab('show');
		}

	}
	return {
		getKeyValues: getKeyValues
	};
}(window, jQuery));
