/**
 * Handles sorting in table column headers
 * Bootstrap Templates
 */
const mrcSorting = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function initializeArrowsForSort (formIndex, el) {
          const formInfo = formHandler.getForm(el);
		const $form =  formInfo.$form;
		const $sortInput = jQuery(el);
		let sortNamesStr;
		let sortNames;
		let sortNamesLength;

		if ($sortInput.length === 0) return;

		sortNamesStr = $sortInput.val().toString();
		sortNames = sortNamesStr.split(',');
		sortNamesLength = sortNames.length;

		$form.find('.sort-col').parent().removeClass('highlight');

		for (let i = 0; i < sortNamesLength; i++) {
			const sortName = sortNames[i];
			const dir = sortName.substring(0, 2);
			const name = sortName.substring(2);

			if (dir === 's0') {
				$form.find('.sort-col[data-field="' + name + '"] i').removeClass('fa-sort fa-caret-up').addClass('fa-caret-down').closest('th').addClass('highlight');
				$form.find('th[name="' + sortName + '"]').attr('data-sort_type', '1').attr('name', 's1' + name);
			} else {
				$form.find('.sort-col[data-field="' + name + '"] i').removeClass('fa-sort fa-caret-down').addClass('fa-caret-up').closest('th').addClass('highlight');
				$form.find('th[name="' + sortName + '"]').attr('data-sort_type', '0').attr('name', 's0' + name);
			}
		}
	}

	function initializeAllArrowsForSort ($html) {
		const $inputs = ($html ? $html.find('[name=sortFields]') : jQuery('[name=sortFields]'));

		$inputs.each(initializeArrowsForSort);
		$inputs.each(initializeMultipleSort);
	}

	function initializeMultipleSort (formIndex, el) {
          const formInfo = formHandler.getForm(el);
		const $form =  formInfo.$form;
		var sortFieldsStr = $form.find('input[name=cur_sort_col]').val();
		sortFieldsStr = (sortFieldsStr && sortFieldsStr.toString()) || '';
		if (sortFieldsStr === '') return;

		var	sortTypStr = $form.find('input[name=sort_typ]').val();
		sortTypStr = (sortTypStr && sortTypStr.toString()) || '';
		var sortFieldAry = sortFieldsStr.split(',');
		var sortTypAry = sortTypStr.split(',');

		for (var i in sortFieldAry) {
			if (i === '0') {
				$form.find('.sort-field-select-box').val(sortFieldAry[i]);
				$form.find('.sort-field-type').val(sortTypAry[i]);
			} else {
				var $rowClone = $form.find('.sort-option-row').first().clone();

				$rowClone.find('.sort-field-select-box').val(sortFieldAry[i]);
				$rowClone.find('.sort-field-type').val(sortTypAry[i]);
				$form.find('.sort-option-row').last().after($rowClone);
			}
		}
	}

	function addSortRow (ev) {
		var $menu = getMenuObj(ev);

		$menu.find('.sort-option-row').last().after(jQuery('.sort-option-row').first().clone());
	}

	function removeSortRow (ev) {
		var $menu = getMenuObj(ev);

		if ($menu.find('.sort-option-row').length > 1) {
			$menu.find('.sort-option-row').last().remove();
		}
	}

	function submitMultipleSort (ev) {
          const formInfo = formHandler.getForm(ev);
		const $form =  formInfo.$form;
		var $menu = getMenuObj(ev);
		var fieldAry = [];
		var sortTypeAry = [];

		$menu.find('.sort-field-select-box').each(function (i, el) {
			fieldAry.push(jQuery(el).val());
		});

		$menu.find('.sort-field-type').each(function (i, el) {
			sortTypeAry.push(jQuery(this).val());
		});

		jQuery('input[name="sort_typ"]').val(sortTypeAry.join(','));
		jQuery('input[name="cur_sort_col"]').val(fieldAry.join(','));
		jQuery('input[name="reorder"]').val('Y');

          if(!formInfo.isForm){
               formHandler.doSubmit(jQuery(ev.currentTarget), null);
               return;
          }
		$menu.closest('form').submit();
	}

	function getMenuObj (ev) {
		return jQuery(ev.currentTarget).closest('.multiple-sort-menu');
	}

	jQuery('body').on('click', '.add-sort-field', addSortRow)
		.on('click', '.remove-sort-field', removeSortRow)
		.on('click', '.apply-multiple-sort', submitMultipleSort);

	jQuery('body').on('click', '.multiple-sort-menu', function (ev) {
		/* jshint -W030 */
		jQuery(ev.currentTarget).is('.show') && ev.stopPropagation();
		/* jshint +W030 */
	});

	return {
		initializeAllArrowsForSort: initializeAllArrowsForSort
	};
}(window, jQuery));
