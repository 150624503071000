//###########################################################
// Version:  2010-01-07: convert to use jQuery
// mrc ltd., Lombard, IL 60148, USA
//###########################################################
var AJAX_HOVER_DELAY = 150;
/******************************************************************
 * Hover Ajax called from:
 * <a class="hover" target="_hover" linkedtype="I" width="400" height="0" onclick="stopEvent(event)" onmouseover="ajaxlink(this,event)" href="MRCJAVA2.I00018s?basic=1">${row.PRDNO?html}</a>
 ******************************************************************/
function ajaxlink (element, ev) {//mouseover handler
	url = element.href;
	boxw = element.getAttribute('width');
	boxh = element.getAttribute('height');
	new ALinkObj(element, {boxw:boxw, boxh:boxh, url:url, cursorY:ev.clientY});
}
stopEvent = function (ev) {
	if(window.event) {
		ev.preventDefault();
	}else{
		ev.preventDefault();
		ev.stopPropagation();
	}
	return false;
};
function ALinkObj (elementName, options) {
	this.element = elementName;
	if (!this.element) throw new Error(elementName + ' not found');
	this.element.alinkobj = this;
	this.options = options || {};
	this.boxw = this.options.boxw || 400;
	this.boxh = this.options.boxh || 350;
	var url0 = this.options.url || '';
	this.url =  url0.replace(/#/g, '%23');
	this.cursorY = this.options.cursorY || 0;
	this.inAjaxBox = 'n';
	this.onmouseover();
	var thisCtx = this;
	//Event.observe(this.element, "mouseout", this.hidebox.bind(this));
	jQuery(this.element).bind('mouseout', function () {thisCtx.hidebox(); }) ;
}
ALinkObj.prototype.onmouseover = function () {
	this.cursorIn = 'y';
	this.ajaxboxid = getAjaxBox();
	this.element.style.cursor = 'pointer';
	var thisCtx = this;
	window.setTimeout( function () { thisCtx.doajax(); }, AJAX_HOVER_DELAY);
};
ALinkObj.prototype.doajax = function () {
	if (this.ajaxBoxSattus == 'on') {return;}
	if (this.cursorIn == 'n') return;

	var obj =  this;
	this.url += '&x=' + Math.random();
	jQuery.ajax({ url: this.url, dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			displayData1(data, obj);
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			displayErr1(textStatus, obj);
		},
		complete: function (XMLHttpRequest, textStatus) { //Ajax completed

		}
	});
};
displayData1 = function (data, thisobj) {
	if (thisobj.cursorIn == 'n') return;
	var responseText = data;
	//var innerDiv = '<div class="ajaxboxinner">' + responseText + "</div>";
	var innerDiv = '<div class="ajaxboxinner"></div>';
	jQuery(thisobj.ajaxboxid).html(innerDiv);
	thisobj.setAjaxbox1(data);
	thisobj.ajaxBoxSattus = 'on';
};

function displayErr1 (textStatus, thisobj) {
	jQuery(thisobj.ajaxboxid).html('Ajax failed: ' + textStatus);
}

ALinkObj.prototype.hidebox = function () { //context is <a> tag
	this.cursorIn = 'n';
	var thisCtx = this;
	window.setTimeout( function () { thisCtx.hidebox2(); }, AJAX_HOVER_DELAY);
	// Event.observe(this.ajaxboxid, "mouseover", this.ajaxboxIn.bind(this));
	// Event.observe(this.ajaxboxid, "mouseout", this.ajaxboxOut.bind(this));
	jQuery(this.ajaxboxid).bind('mouseover',  function (e) { thisCtx.ajaxboxIn(e); } ) ;
	jQuery(this.ajaxboxid).bind('mouseout',   function (e) { thisCtx.ajaxboxOut(e); } ) ;
};
ALinkObj.prototype.hidebox2 = function () {
	if (this.inAjaxBox == 'n' && this.ajaxBoxSattus == 'on') {
		jQuery(this.ajaxboxid).hide();
		document.body.removeChild(this.ajaxboxid);
		this.ajaxBoxSattus = 'off';
	}
};
ALinkObj.prototype.ajaxboxIn = function (evt) {
	this.inAjaxBox = 'y';
};
ALinkObj.prototype.ajaxboxOut = function (evt) {
	var eX = evt.pageX;//Event.pointerX(evt);
	var eY = evt.pageY;//Event.pointerY(evt);
	var boxX = this.ajaxboxid.offsetLeft;
	var boxY = this.ajaxboxid.offsetTop;
	var boxh = jQuery(this.ajaxboxid).height();
	var boxw = this.ajaxboxid.offsetWidth;//jQuery(this.ajaxboxid).width();
	var offsetxy = 3;
	var x1 = boxX + offsetxy;
	var x2 = boxX + boxw;
	var y1 = boxY + offsetxy;
	var y2 = boxY + boxh;
	//jQuery("#dsp").html("eX=" + eX + ",eY=" + eY  + ",x1=" + x1  + ",x2=" + x2 );
	if (eX > x1 && eX < x2 && eY > y1 && eY < y2) {
		return;
	}
	//var isIn = Position.within($(this.ajaxboxid),eX + offsetxy ,eY + offsetxy);
	// var isIn2 = Position.within($(this.ajaxboxid),eX - offsetxy ,eY - offsetxy);
	//if (isIn && isIn2) { return; }
	jQuery(this.ajaxboxid).hide(); //jq
	document.body.removeChild(this.ajaxboxid);//2014-11-21: add
	this.ajaxBoxSattus = 'off';
	this.inAjaxBox = 'n';
};
/*******************************************************************
 * Set Ajax box on page as popup box.
 ******************************************************************/
ALinkObj.prototype.setAjaxbox1 = function (data) { //context is this obj
	var offsetx = 12; var offsety = 3;
	var linkElement = this.element;
	var ajaxbox =  this.ajaxboxid ;

	document.body.appendChild(ajaxbox);
	if (data) { //2014-04-10)
		jQuery('.ajaxboxinner').html(data);
	}
	if (this.boxh == 0 || this.boxh == 'auto') {
		this.boxh = jQuery(this.ajaxboxid).outerHeight();
		this.boxh += 100;
	} else {
	   this.boxh = parseInt(this.boxh);
	}
	jQuery(this.ajaxboxid).css({width:this.boxw, height:this.boxh} );

	var linkpos = jQuery(linkElement).offset();
	var tagx = linkpos.left;
	var tagy  = linkpos.top;

	jQuery(this.ajaxboxid).css('position', 'absolute');
	var bodyw = jQuery(document).width();//document.body.clientWidth;
	var winsize = windowSize();
	var winh = winsize.h;
	var left = tagx + this.element.offsetWidth + offsetx;

	var top  = tagy +  offsety;
	if (this.cursorY > winh / 2 ) {
		top  = tagy + this.element.offsetHeight - this.boxh;
	}
	var tagw = jQuery(this.ajaxboxid).width();
	if (tagx > bodyw / 2) {
		//  left  = tagx - this.element.offsetWidth - offsetx;
		//  left  = tagx - this.boxw - offsetx; // fix made 2015-06-22 by BD
	 left  = tagx - tagw - offsetx; //2015-09-01 : fix 23663
	 if (left < 0) {
		 left = 10;
	 }
		var browserName = navigator.appName;
		if (browserName != 'Microsoft Internet Explorer') {left = left - 20;}
	}

	var wh = jQuery( window ).height() ;  //window height
	var dt =  jQuery( document ).scrollTop(); //doc top
	top = tagy - this.boxh * 0.5;
	if (top < dt) {
    	top = dt;
	} else if (top + this.boxh > dt + wh) {
    	top = top - (top + this.boxh - (dt + wh)) - 30;
	}
	top = parseInt(top);

	jQuery(this.ajaxboxid).css({left:left, top:top} );


};

function setSmartlinkAjax () {
	var atagls =  document.getElementsByTagName('span');
	var atags = $A(atagls);
	for (i = 0; i < atags.length ; i++) {
		var alnkid = atags[i].id;
		if (alnkid.indexOf('ajaxlnk') >= 0)  {
			//new ALinkObj(alnkid);
			Element.addClassName(alnkid, 'ajaxlink');
		}
	}
}
function getAjaxBox () {
	var elemSpan = document.createElement('div');
	elemSpan.className = 'ajaxboxclass';
	return elemSpan;
}
function windowSize () {
	var winWidth = 0, winHeight = 0;
	if( typeof( window.innerWidth ) == 'number' ) {
		//Non-IE
		winWidth = window.innerWidth;
		winHeight = window.innerHeight;
	} else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
		//IE 6+ in 'standards compliant mode'
		winWidth = document.documentElement.clientWidth;
		winHeight = document.documentElement.clientHeight;
	} else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
		//IE 4 compatible
		winWidth = document.body.clientWidth;
		winHeight = document.body.clientHeight;
	}
	var winsize = {'w':winWidth, 'h':winHeight};
	return winsize;
}
function addLoadListener (func) {
	if ( typeof window.addEventListener != 'undefined' ) {
		window.addEventListener('load', func, false);
	} else if ( typeof window.document.addEventListener != 'undefined' ) {
		window.document.addEventListener('load', func, false);
	}  else if ( typeof window.attachEvent != 'undefined' ) {
		window.attachEvent('onload', func);
	}  else {
		if ( typeof window.onload != 'function' ) {
			window.onload = func;
		} else {
			var oldFunction = window.onload;
			window.onload = function () { oldFunction(); func(); };
		}
	}
}
//#########################################################
var timedelay = 1000; //ms
var mrcobj = new Object();
mrcobj.Loader = function (url, onload, onerror, method, params, contentType) {
	this.req = null;
	mrcobj.currentLoader = this;
	this.onload = onload;
	this.onerror = (onerror) ? onerror : this.defaultError;
	this.loaddoc(url, method, params, contentType);
};
mrcobj.Loader.prototype.loaddoc = function (url, method, params, contentType) {
	if (!method) {
		method = 'GET';
	}
	if (!contentType && method == 'POST') {
		contentType = 'application/x-www-form-urlencoded';
	}
	if (window.XMLHttpRequest) {
		this.req = new XMLHttpRequest();
	} else if (window.ActiveXObject) {
		this.req = new ActiveXObject('Microsoft.XMLHTTP');
	}

	if (this.req) {

		try{
			var loader = this;
			this.req.onreadystatechange = function () {
				mrcobj.Loader.onReadyState.call(loader);
			};
			this.req.open(method, url, true);

			if (contentType) {
				this.req.setRequestHeader('Content-Type', contentType);
			}
			this.req.send(params);
		}catch (err) {
			this.onerror.call(this);
		}
	}
};
//####################################
mrcobj.Loader.onReadyState = function () {
	var req = this.req;
	var ready = req.readyState;

	try{
		var httpStatus = req.status;

		if (ready == 4) {
			if (httpStatus == 200 || httpStatus == 0) {
				this.onload.call(this);
			}else{
				//this.onerror.call(this);
			}
		}
	}
	catch(e) {}
};
//####################################
mrcobj.Loader.prototype.defaultError = function () {
	alert('error fetching data!'
    + '\n\nreadyState:' + this.req.readyState
    + '\nstatus: ' + this.req.status
    + '\nheaders: ' + this.req.getAllResponseHeaders());
};
//#############################################################
function  init (server_app, qtype) {
	//alert("run!");
	setJaxbox();
	if (qtype == 'I') {
		setJaxboxsRET(server_app);
	}
}

function  init2 (server_app, qtype, form) {
	//alert("run!");
	setJaxbox();
	if (qtype == 'I') {
		setJaxboxsRET2(server_app, form);
	}
}
//####################################
function  setJaxboxsRET (server_app) {
	var msg = 'No matches';// message for no matching record
	msg = localeMsg2(msg);//call localeMsg2 in mrcjsajax.js
	var fm = document.forms[0];
	var hasAjax = false;
	/*for(j=0;j<fm.elements.length;j++){
    var formObj=fm.elements[j];
    if(formObj.type && formObj.type.toLowerCase() == "text" && formObj.name.indexOf("val_") == 0) {
	   //if (!$--(formObj).hasClassName('noajax') ) {
	   if (!jQuery(formObj).hasClass('noajax') ) { //jq
         formObj.obj = SetProperties(formObj,"k",server_app,true,true,true,true,msg,false, 10000);
		 hasAjax = true;
	   }
    }
  }*/
	//2013-06-19 - changed to jquery to fix IE10 bug
	jQuery(fm).find(':text[name^=\'val_\']').each(function () {
		var formObj = jQuery(this).get(0);
	   if (!jQuery(formObj).hasClass('noajax') ) { //jq
			formObj.obj = SetProperties(formObj, 'k', server_app, true, true, true, true, msg, false, 10000);
		 hasAjax = true;
	   }
	});
	if (hasAjax) {
	 jQuery('body').keyup( HideTheBox2  ); //jq
		jQuery('body').click( HideTheBox  );
	}
}

function  setJaxboxsRET2 (server_app, form) {
	var msg = 'No matches';// message for no matching record
	msg = localeMsg2(msg);//call localeMsg2 in mrcjsajax.js
     var fm = form;
	var hasAjax = false;

     jQuery(form).find(':input').each(function(){
          var formObj = this;
          if(formObj.type && formObj.type.toLowerCase() == 'text' && formObj.name.indexOf('val_') == 0) {
            
               if(!jQuery(formObj).hasClass('noajax') ) { //jq
                    formObj.obj = SetProperties(formObj, 'k', server_app, true, true, true, true, msg, false, 10000);
                    hasAjax = true;
               }
          }
     });
	
	
	if (hasAjax) {
	 jQuery('body').keyup( HideTheBox2  ); //jq
		jQuery('body').click( HideTheBox  );
	}
}
//####################################
function  setJaxbox () {
	if(jQuery('.jaxboxclass').length > 0) { //only need one jaxbox in the source
		return;
	}
	var elemSpan = document.createElement('span');
	elemSpan.id = 'jaxbox';
	elemSpan.className = 'jaxboxclass';
	document.body.appendChild(elemSpan);
}
//####################################
function SetProperties (xElem, xHidden, xserverCode,
	xignoreCase, xmatchAnywhere, xmatchTextBoxWidth,
	xshowNoMatchMessage, xnoMatchingDataMessage, xuseTimeout, xtheVisibleTime) {
	var props = {
		elem: xElem,
		hidden: xHidden,
		serverCode: xserverCode,
		regExFlags: ( (xignoreCase) ? 'i' : '' ), //case
		regExAny: ( (xmatchAnywhere) ? '' : '^' ),
		matchAnywhere: xmatchAnywhere,
		matchTextBoxWidth: xmatchTextBoxWidth,
		theVisibleTime: xtheVisibleTime,
		showNoMatchMessage: xshowNoMatchMessage,
		noMatchingDataMessage: xnoMatchingDataMessage,
		useTimeout: xuseTimeout
	};
	AddHandler(xElem);

	return props;
}

//####################################
function AddHandler (objText) {

	objText.onkeyup = GiveOptions;
	objText.onblur = function () {
		//alert("this.obj.useTimeout=" + this.obj.useTimeout + "  );
		//if(this.obj.useTimeout)StartTimeout();
		//setTimeout("HideTheBox()",10);
	};

}

var arrOptions = new Array();
var strLastValue = '';
var bMadeRequest;
var theTextBox;
var objLastActive;
var currentValueSelected = -1;
var bNoResults = false;
var isTiming = false;
	  var ajaxFormRunning = false;
	   var ajaxSuggestRunning = false;
//####################################
function GiveOptions (e) {
	var intKey = -1;
	if(window.event) {
		intKey = event.keyCode;
		theTextBox = event.srcElement;
	}
	else{
		intKey = e.which;
		theTextBox = e.target;
	}
	if(theTextBox.obj.useTimeout) {
		//if(isTiming)EraseTimeout();
		//StartTimeout();
	}
	if(theTextBox.value.length == 0  ) {
		arrOptions = new Array();
		HideTheBox();
		strLastValue = '';
		return false;
	}
	if(objLastActive == theTextBox) {
		if(intKey == 13) {
			GrabHighlighted();
			theTextBox.blur();
			//document.forms[0].submit();
			return false;
		}   else if(intKey == 38) {
			MoveHighlight(-1);
			GrabHighlighted2();
			return false;
		}   else if(intKey == 40) {
			MoveHighlight(1);
			GrabHighlighted2();
			return false;
		}  else {
			// var charCode = (e.charCode)?e.charCode : ((e.which) ? e.which : e.keyCode);
			if (intKey == 32) {
				//alert("space!");
				return false;}
		}
	}
	if(objLastActive != theTextBox  ||
       theTextBox.value.indexOf(strLastValue) != 0 ||
           ( (arrOptions.length == 0 || arrOptions.length == 15 ) && !bNoResults) ||
           (theTextBox.value.length > strLastValue.length)) {
		objLastActive = theTextBox;
		bMadeRequest = true;
		callajax0(theTextBox.value);
		// document.getElementById("mmsg").innerHTML="get data....theTextBox.value="+theTextBox.value;
	}  else if(!bMadeRequest) {
		BuildList(theTextBox.value);
		//document.getElementById("mmsg").innerHTML="not get data................";
	}
	strLastValue = theTextBox.value;
}

//####################################
function callajax0 (xStrText) {
	var today = new Date();
	var t0 = today.getTime(); tt0 = t0;
	ajaxSuggestRunning = true;
	setTimeout(function(){callajax1(t0);}, timedelay);
	//alert("op");
}
var tt0 = 0;
//####################################
function callajax1 (stamp) {
	if(stamp == tt0) {
		var params = setParms(theTextBox);
		var loader1 = new mrcobj.Loader(theTextBox.obj.serverCode, BuildChoices, null, 'POST', params);

	}
}

//####################################
function setParms (jbox) {
	var mrclookupfield = jQuery(jbox).attr('mrclookupfield'); //TW 2010-06-07 change for maintainer/report ajax
	var parms = '';
	if(mrclookupfield != null && mrclookupfield != '') {
		parms = 'AJAX=M&INPFIELD=' + jbox.name + '&AJAXFIELDM=' + mrclookupfield;
	}else{
		parms = 'AJAX=Y&AJAXFIELD=' + jbox.name;
	}

	//if grid add maintainer, only send the active input value
	var inpId = jQuery(jbox).attr('id');
	if (inpId && (inpId.indexOf('_grid') > 0 || (jQuery(jbox).closest('.single-row-maintainer, .report-prompt').length ||
     jQuery(jbox).closest('[data-app_type="M"]').length))) {
		var name =  jQuery(jbox).attr('name');
		var value = jQuery(jbox).val();
		parms += '&' + name + '=' + escape(value);
		return parms;
	}
	//var fm=document.forms[0];
	var $form = jQuery(jbox).closest('form,[data-program]'); ////TW 2010-06-15 change for maintainer/report ajax

     $form.find(':input').each(function(){
          var formObj = this;
          if(formObj.name && formObj.name.indexOf('rls_') == 0) {
               var val = '';
               var indx = formObj.selectedIndex;
               if (indx == undefined) {
                     val = formObj.value;
               } else {
                    val = formObj.options[indx].value;
               }
               parms += '&' + formObj.name + '=' + val;
          } else if(formObj.type && (formObj.type.toLowerCase() == 'text' ||
               formObj.type.toLowerCase() == 'hidden' ||
               formObj.name.indexOf('btn_') == 0) ) {

               if(formObj.name == 'work_with' || formObj.name == 'action_mode'){
                    return;
               }
               parms += '&' + formObj.name + '=' + escape(formObj.value);
          }
     });
     
	//alert("parms=" + parms );
	return parms;
}

//####################################00000000000000000
function BuildChoices () {
	if (theTextBox.value.length == 0) return;
	var jaxfield = theTextBox.name;
	var jaxvalue = theTextBox.value;
	var errcode = 0;
	//alert(theTextBox.value+'=='+jaxvalue);
	var strText = this.req.responseText;
    if (strText.length == 0) return;
    parseResponse(strText);
	//eval(strText);
	if (errcode == 1) return;
	var boxvalue = theTextBox.value;

	try{
		if(fieldtype == 0) {
			boxvalue = boxvalue.replace(/,/gi, '');
			strLastValue = strLastValue.replace(/,/gi, '');
		}
	}catch(e) {}

	var mrclookupfield = jQuery(theTextBox).attr('mrclookupfield'); //TW 2010-06-07 change for maintainer/report ajax
	if(mrclookupfield != null && mrclookupfield != '') {
		jaxfield = theTextBox.name;
	}
	if (theTextBox.name != jaxfield || boxvalue != jaxvalue) return;
	if(ajaxSuggestRunning == false) {
		return;
	}
	BuildList(strLastValue);
	ajaxSuggestRunning = false;
	bMadeRequest = false;

}
function parseResponse(responseText){
    jaxfield = getResponsePart(responseText,"jaxfield='","';var jaxvalue");
    jaxvalue = getResponsePart(responseText,"jaxvalue='","';var errcode");
    errcode = getResponsePart(responseText,"errcode='","';arrOption");
    var aryStr = "[" + getResponsePart(responseText,"new Array(",");") + "]";
    arrOptions = JSON.parse(aryStr);
}
function getResponsePart(responseText, begin, end){
    var pos1 = responseText.indexOf(begin) + begin.length;
    var pos2 = responseText.indexOf(end);
    return responseText.substring(pos1, pos2);

}
//####################################
function BuildList (theText) {
	SetElementPosition(theTextBox);
	var theMatches = MakeMatches(theText);
	//change to use !~! as delimiter (16743)
	theMatches = theMatches.join('!~!').replace(/\!~!/gi, '');
	if(theMatches.length > 0) {
		document.getElementById('jaxbox').innerHTML = theMatches;
		document.getElementById('OptionsList_0').className = 'highEl';
		currentValueSelected = 0;
		bNoResults = false;
	}
	else{
		currentValueSelected = -1;
		bNoResults = true;
		if(theTextBox.obj.showNoMatchMessage)
			document.getElementById('jaxbox').innerHTML = '<span class=\'noMatchData\'>' +
              theTextBox.obj.noMatchingDataMessage + '</span>';
		else HideTheBox();
	}
}

//####################################
function SetElementPosition (theTextBoxInt) {
    var filterContainerScroll = jQuery('.selection-scroll-container').scrollTop();
    var modalScroll = jQuery('.selection-scroll-container').closest('.modal').scrollTop();
	var selectedPosX = 0;
	var selectedPosY = 0;
	var theElement = theTextBoxInt;
	if (!theElement) return;
	var theElemHeight = theElement.offsetHeight;
	var theElemWidth = theElement.offsetWidth;
	while(theElement != null) {
		selectedPosX += theElement.offsetLeft;
		selectedPosY += theElement.offsetTop;
		theElement = theElement.offsetParent;
	}
	xPosElement = document.getElementById('jaxbox');
	xPosElement.style.left = selectedPosX + 'px';
	if(theTextBoxInt.obj.matchTextBoxWidth)
        xPosElement.style.width = theElemWidth + 20;
    var top = selectedPosY + theElemHeight

    if(!!modalScroll) top -= modalScroll;
    if(!!filterContainerScroll) top -= filterContainerScroll;
	xPosElement.style.top = (top) + 'px';
	xPosElement.style.display = 'block';
	if(theTextBoxInt.obj.useTimeout) {
		xPosElement.onmouseout = StartTimeout;
		xPosElement.onmouseover = EraseTimeout;
	}
	else{
		xPosElement.onmouseout = null;
		xPosElement.onmouseover = null;
	}
}

//####################################
var countForId = 0;
function MakeMatches (xCompareStr) {
      	xCompareStr = xCompareStr.replace('$', '\\$');
      	countForId = 0;
	var matchArray = new Array();
	var regExp = new RegExp(theTextBox.obj.regExAny + xCompareStr, theTextBox.obj.regExFlags);
	for(i = 0;i < arrOptions.length;i++) {
		var theMatch = arrOptions[i].match(regExp);
		//var theMatch = arrOptions[i][0].match(regExp);
		if(theMatch) {
			matchArray[matchArray.length] = CreateUnderline(arrOptions[i], xCompareStr, i);
		} else {
			//CA/CO may not match, dsp without highlight
			matchArray[matchArray.length] = CreateUnderline2(arrOptions[i], xCompareStr, i);
		}
	}
	return matchArray;
}


//####################################
var hiStart = '<span class=\'spanMatchText\'>';
var hiEnd = '</span>';
var selStart = '<span style=\'width:100%;display:block;\' class=\'normalEl\' onmouseover=\'SetHighColor(this)\'';
var selEnd = '</span>';
function CreateUnderline (xStr, xTextMatch, xVal) {
	selMid = 'onclick=\'SetText(' + xVal + ')\'' + 'id=\'OptionsList_' + countForId + '\' theArrayNumber=\'' + xVal + '\'>';
	var regExp = new RegExp(theTextBox.obj.regExAny + xTextMatch, theTextBox.obj.regExFlags);
	var aStart = xStr.search(regExp);
	var matchedText = xStr.substring(aStart, aStart + xTextMatch.length);
	countForId++;
	return selStart + selMid + xStr.replace(regExp, hiStart + matchedText + hiEnd) + selEnd;
}
function CreateUnderline2 (xStr, xTextMatch, xVal) {
	selMid = 'onclick=\'SetText(' + xVal + ')\'' + 'id=\'OptionsList_' + countForId + '\' theArrayNumber=\'' + xVal + '\'>';
	var matchedText = xStr;
	countForId++;
	var xstr2 = selStart + selMid + xStr + selEnd;
	return xstr2;
}

//####################################
function MoveHighlight (xDir) {
	if(currentValueSelected >= 0) {
		newValue = parseInt(currentValueSelected) + parseInt(xDir);
		if(newValue > -1 && newValue < countForId) {
			currentValueSelected = newValue;
			SetHighColor (null);
		}
	}
}
//####################################
function SetHighColor (theTextBox) {
	if(theTextBox) {
		currentValueSelected = theTextBox.id.slice(theTextBox.id.indexOf('_') + 1,
			theTextBox.id.length);
	}
	for(i = 0; i < countForId; i++) {
		document.getElementById('OptionsList_' + i).className = 'normalEl';
	}
	document.getElementById('OptionsList_' + currentValueSelected).className = 'highEl';
}

//####################################
function SetText (xVal) {
	theTextBox.value = arrOptions[xVal]; //set text value

	document.getElementById('jaxbox').style.display = 'none';
	currentValueSelected = -1; //remove the selected index
	// if (document.forms[0].btn_search) {
		//document.forms[0].btn_search.focus();
	// }
	 if(jQuery(theTextBox).attr('fn') != null && jQuery(theTextBox).attr('fn') != '') { //TW change for ajax features 2010-06-17
		checkInput(jQuery(theTextBox).attr('fn'));
	 } //TW added for maintainer report prompt ajax suggest 2010-06-28

}
//####################################
function GrabHighlighted () {
	if(currentValueSelected >= 0) {
		xVal = document.getElementById('OptionsList_' + currentValueSelected).getAttribute('theArrayNumber');
		SetText(xVal);
		HideTheBox();
	}
}
//####################################
function GrabHighlighted2 () {
	if(currentValueSelected >= 0) {
		xVal = document.getElementById('OptionsList_' + currentValueSelected).getAttribute('theArrayNumber');
		theTextBox.value = arrOptions[xVal]; //set text value
		//SetText(xVal);
		//HideTheBox();
	}
}
//####################################
function HideTheBox () {
	document.getElementById('jaxbox').style.display = 'none';
	currentValueSelected = -1;
	EraseTimeout();
}
//####################################
// On blur due to TAB key, hide box
function HideTheBox2 (evt) {
	var intKey = -1;
	if(window.event) {
	  intKey = event.keyCode;
	} else {
	  intKey = evt.which;
	}
	//alert(intKey + "-----" + evt.keyCode);
	if(intKey == 9) { HideTheBox(); } //tab key
}
//####################################
function EraseTimeout () {
	clearTimeout(isTiming);
	isTiming = false;
}
//####################################
function StartTimeout () {
	// alert("theTextBox.obj.theVisibleTime=" );
	//isTiming = setTimeout("HideTheBox()",theTextBox.obj.theVisibleTime);
}
//#######################################################
function setSortImage (names) { // for set grey sort images
	for (var i = 0; i < names.length; i++) {
		var im = names[i];
		if (document.images[im]) {
			if (im.indexOf('s0') == 0) {
				document.images[im].src =  s0.src ;
			} else {
				document.images[im].src =  s1.src ;
			}
		}
	}
}
function setSortImage2 (names, form) { // for set grey sort images
	var form = jQuery('#' + form);
	form.find('.sort-col').parent().removeClass('highlight');
	for (var i = 0; i < names.length; i++) {
		var im = names[i];
		if (im.indexOf('s0') == 0) {
			form.find('img[name=\'' + im + '\']').attr('src', s0.src);
			name = im.substring(2);

			form.find('.sort-col[data-field=\'' + name + '\'] i').removeClass('fa-sort fa-caret-up').addClass('fa-caret-down').closest('th').addClass('highlight');
			form.find('th[name=\'' + im + '\']').attr('data-sort_type', '1').attr('name', 's1' + name);
		} else {
			form.find('img[name=\'' + im + '\']').attr('src', s1.src);
			name = im.substring(2);
			form.find('.sort-col[data-field=\'' + name + '\'] i').removeClass('fa-sort fa-caret-down').addClass('fa-caret-up').closest('th').addClass('highlight');
			form.find('th[name=\'' + im + '\']').attr('data-sort_type', '0').attr('name', 's0' + name);
		}

	}

}
//#######################################################
function setDropV (fld, val) { // for set value dropdown field
	var selects = document.getElementsByTagName('select');
	for (var i = 0; i < selects.length; i++) {
		var name0 = 'val_' + fld;
		if (selects[i].name == name0) {
			selects[name0].value = val;
		}
	}
}
//Functions below for new ajax Lookup/validation and Ajax Drop Downs
//#######################################################
function callAjaxLookup (app, cur_fld, lookup_fld, type, callback) {
	var fieldValue = jQuery('#' + cur_fld).val();
	var otherParms = '&val_' + lookup_fld + '=' + encodeURIComponent(jQuery.trim(fieldValue)) + '&rls_' + lookup_fld + '=EQ';
	makeHelperAjaxCall(app, cur_fld, lookup_fld, otherParms, type, fieldValue, callback);
}
//########################################################################
function set_ajax_dropdown (app, cur_fld, cascadeField, lookupField, type) {
	var otherParms = '';
	var fieldValue = '';
	if(!!cascadeField) {
		fieldValue = jQuery('#' + cascadeField).val();
        otherParms = '&' + lookupField + '=' + encodeURIComponent(fieldValue);
        if(fieldValue == '' && type == 'load'){
            jQuery('#' + cur_fld).addClass('form-control').prop('disabled', true)
            return;
        } 
	}
	callDropdown(app, cur_fld, cascadeField, otherParms, lookupField, type);
}
//########################################################################################
function makeHelperAjaxCall (cur_fld, callback, form, index) {
	var app = jQuery('#' + cur_fld).attr('mrclookup');
	var ret_fld = jQuery('#' + cur_fld).attr('mrclookupfield');
	var fieldValue = jQuery('#' + cur_fld).val();
	var otherParms = '&val_' + ret_fld + '=' + encodeURIComponent(jQuery.trim(fieldValue)) + '&rls_' + ret_fld + '=EE';
	jQuery.ajax({ url:app, data:'AJAX=Y&AJAXFIELD=val_' + ret_fld + otherParms, dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			buildHelperResultFormSubmit(data, cur_fld, fieldValue, callback, form, index);
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			alert(textStatus);
		},
		complete: function (XMLHttpRequest, textStatus) { //Ajax completed
		}
	});
}
//##############################################################################
function buildHelperResultFormSubmit (data, cur_fld, fieldValue, callback, form, index) {
    //eval(data);
    parseResponse(data);
	mrcValidate = false;
	for(var i = 0; i < arrOptions.length;i++) {
		if(fieldValue.toUpperCase() == arrOptions[i].toUpperCase()) {
			mrcValidate = true;
			break;
		}
	}
	if(fieldValue == '') {mrcValidate = true;}
	jQuery('#err' + cur_fld).hide();
	jQuery('#' + cur_fld).attr('fldvalid', 'true');
	if(mrcValidate != true) {
		jQuery('#err' + cur_fld).show();
		jQuery('#' + cur_fld).attr('fldvalid', 'false');
	}
	callback(form, index);
}
//##############################################################################
function callDropdown (app, cur_fld, cascadeField, otherParms, lookupField, type) {

	jQuery.ajax({ url:app, data:'reqtype=5&slnk=1&x=' + Math.random() + otherParms, dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			buildAjaxSelectList(data, app, cur_fld, cascadeField, lookupField, type);
			setOnChangeEvents(cur_fld, cascadeField, lookupField, type);
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			if (XMLHttpRequest.responseText.length > 0) alert(textStatus);
		},
		complete: function (XMLHttpRequest, textStatus) { //Ajax completed
		}
	});
}
//##############################################################################
function buildAjaxSelectList (data, app, cur_fld, cascadeField, lookupField, type) {

	var loadValue = jQuery('#' + cur_fld).val();
	var multipleSelect = jQuery('#' + cur_fld).attr('mrcmultiple');
	var disabled = jQuery('#' + cur_fld).is(':disabled') || jQuery('#' + cur_fld).is('[readonly]') ? 1 : -1;// check for disabled input in maintainer

	//if grid, replace name="RXXX" by field name
	if (cur_fld.indexOf('_grid') > 0) {
		var name = jQuery.gridFieldName(cur_fld);
		data = data.replace(/name="RXXX"/ig, 'name="' + name  + '"');
	}
	data = data.replace(/RXXX/ig, cur_fld);
	var blank = data.indexOf('select');
	if(multipleSelect != null && multipleSelect != '') { //check if in the list and create multiple select box
		data = data.substring(0, 7) + ' multiple=\"multiple\" ' + data.substring(7);
	}
	if(blank != -1) { // check if dropdown is changed to blank
		jQuery("#" + cur_fld).replaceWith(data);
		if(disabled != -1) {
			jQuery('#' + cur_fld).prop('readonly',true);
		}
		
	}else{
		var select = '<select id="' + cur_fld + '"><option value=""></option></select>';
		jQuery('#' + cur_fld).replaceWith(select);
	}
	jQuery('#' + cur_fld).attr('mrccascadefield', cascadeField);
	jQuery('#' + cur_fld).attr('mrclookupfield', lookupField);
    jQuery('#' + cur_fld).attr('mrclookup', app);
    jQuery('#' + cur_fld).addClass('gridinput');

	if(type == 'load') {
		jQuery('#' + cur_fld).val(loadValue);
		if (disabled == 1) {
			jQuery('#' + cur_fld + ' option:not(:selected)').attr('disabled', true);
		}
	}
	if(disabled == -1 && type != 'load') {
		jQuery('#' + cur_fld).removeAttr('disabled').removeAttr('readonly');
	}else if( type == 'change') {
		jQuery('#' + cur_fld).removeAttr('disabled').removeAttr('readonly');
	}
	
	var html = jQuery('head').html();
	var index = html.indexOf('asmselect');
	if(index >= 0 && multipleSelect != null) {
		jQuery('#select_menu select[multiple] option[value=\'\']').remove();
		jQuery('#select_menu select[multiple]').asmSelect({
			addItemTarget: 'bottom', animate: true, highlight: true, sortable: false
		});
	}
	var formid = jQuery('#' + cur_fld).closest('form').attr('id');
	if(type == 'load' && jQuery('#select_menu').length != 0) {
		try{
			var report = irtReports[formid];
			report.setupSubmitOnInput();
		}catch(err) {}
	}else if(jQuery('#select_menu').length != 0) {
		jQuery('#' + cur_fld).change(function () {
			blockUIandRun(formid);
		});
	}
    jQuery('#select_menu').width( '');
    jQuery('#' + cur_fld).trigger('mrc:cascadeLoaded');

}
//#############################################################################
function setOnChangeEvents (cur_fld, cascadeField, lookupField, type) {
	//2012-09-05: Add for Grid Add MNT
	var cur_fld0 = cur_fld;
	//if (jQuery.isGridApp()) {
		//return;//2022-03-25 - need to still disable the inputs in grid add
	//}
	var isCascadeField = jQuery('select[mrccascadefield=' + cur_fld0 + ']').length;
	if(isCascadeField > 0) {
		jQuery('#' + cur_fld).change(function () {
			setCascadingDropdowns(cur_fld);
		});
	}
	if(cascadeField != '' && type != 'load') {
		if( jQuery('#' + cascadeField).val() == '') {
			jQuery('#' + cur_fld).attr('disabled', 'disabled');
			setCascadingDropdowns(cur_fld);
		}
	}
	if(jQuery('#' + cur_fld).val() == '') {
		jQuery('select[mrccascadefield=' + cur_fld + ']').each(function () {
			jQuery(this).attr('disabled', 'disabled');
			jQuery(this).val('');
		});
	}
	if(cascadeField && jQuery('#' + cascadeField).val() == '') {
		jQuery('#' + cur_fld).attr('disabled', 'disabled');
	}
}
//###########################################################################
function setCascadingDropdowns (cur_fld) {
	jQuery('select[mrccascadefield=' + cur_fld + ']').each(function () {
		var lookupTable = jQuery(this).attr('mrclookup');
		jQuery(this).attr('disabled', 'disabled');
		set_ajax_dropdown(lookupTable, jQuery(this).attr('id'), cur_fld, jQuery(this).attr('mrclookupfield'), 'change');
	});
}
//#################################################################
function  set_ajax_suggest (server_app, ret_fld, mnt_fld) {
	setJaxbox();
	setJaxboxsMNT(server_app, ret_fld, mnt_fld);
}
//#############################################################
function  setJaxboxsMNT (server_app, ret_fld, mnt_fld, functions) {
	var msg = 'No matches';// message for no matching record
	msg = localeMsg2(msg);//call localeMsg2 in mrcjsajax.js
	var formObj = jQuery('#' + mnt_fld).get(0);

	//alert( functions + functions.click);
	if (formObj) {
		if (functions) {
			formObj.functions = functions;
	  }
		formObj.obj = SetProperties(formObj, 'k', server_app, true, true, true, true, msg, false, 10000);
	  formObj.ret_fld = ret_fld;
	  jQuery('body').keyup( HideTheBox2  ); //jq
	  jQuery('body').click( HideTheBox  );
	}
}
function switchLanguage (el) {
	var lang = el;
	var url = window.location.toString();
	var firstc = url.indexOf('?') > 0 ? '&' : '?';
	var pos = url.indexOf('#');
	if (pos == url.length - 1) {
	   url = url.substr(0, url.length - 1);
	}
	var localeParm = 'locale=' + lang;
	if (url.indexOf('locale=') < 0) {
		url = url + firstc + localeParm;
	} else {
		url = url.replace(/locale=\w{2}/, localeParm);
	}
	window.location  = url;
}
function switchView (el) {
	var view = el;
	var url = window.location.toString();
	view =  encodeURIComponent(view);
	url = replaceQueryString(url, 'view', view);
	url = url.replace('#?', '?');
	window.location  = url;
}
function replaceQueryString (url, param, value) {
	url = url.replace(/#/ig, '');
	var re = new RegExp('([?|&])' + param + '=.*?(&|$)', 'i');
	if (url.match(re)) {
		return url.replace(re, '$1' + param + '=' + value + '$2');
	}else{
		if(url.indexOf('?') >= 0) {
			return url + '&view=' + value;
		}else{
			return url + '?view=' + value;
		}
	}
}

function saveView () {
	var viewname = jQuery('#dialogViewName').val();
	if(viewname == '' || viewname == ' ') {
		alert('You must enter a Saved Search name in order to save your search.');
		return false;
	}
	var exists = false; //view name already exists
	var sameNameEntered = false;
	jQuery('table[mrc_app]').find('a').each(function () {
		if(jQuery(this).text() == viewname) {
			sameNameEntered = true;
			var a = confirm('This Saved Search name already exists. Would you like to overwrite the existing Saved Search?');
			if(!a) {
				exists = true;
			}
		}
	});
	if(exists == true) {
		return false;
	}
	var isIRT = jQuery('input[name=isIRT]').val();
	isIRT = (isIRT && isIRT == 'Y') ? 'Y' : 'N';
	var app = jQuery('table[mrc_app]').attr('mrc_app');
	var encodeViewName = encodeURIComponent(viewname);
	jQuery.ajax({ url:app, data:'&saveView=Y&viewName=' + encodeViewName + '&isIRT=' + isIRT + '&x=' + Math.random(), dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			if(sameNameEntered == true) {
				return;
			}
			if(exists == false) {
				jQuery('img[mrc_selected],i[mrc_selected]').remove();
			}
			var originalView = viewname;
			viewname = viewname.replace(/(\s)/ig, '_SP_');
			var html = '<tr data-id="' + viewname + '"><td>&nbsp;<i class="fa fa-check fa-large">&nbsp;</i></td><td><font size="2">&nbsp;&nbsp;<a href="#" onclick="switchView(\'' + viewname + '\')">' + originalView + '</a></font></td>' +
                         '<td>&nbsp;</td><td><i class="fa fa-times fa-large" onclick="deleteView(this,\'' + viewname + '\')" t="Delete Saved Search" style="cursor:pointer">&nbsp;</i></td></tr>';

			if(exists == false) {
				jQuery('table[mrc_app]').append(html);
			}
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			alert('An error has occured. Your view could not be saved.');
		},
		complete: function (XMLHttpRequest, textStatus) { //Ajax completed
		}
	});
}
function viewOptions () {
	jQuery('#applicationOptions').toggle(500);
	//jQuery("#myslider").toggle("slide");
}
function viewFormOptions (el) {
	jQuery(this).find('#applicationOptions').toggle(500);
}
function deleteView (el, view) {
	var app = jQuery(el).parents('table').attr('mrc_app');
	viewname = view.replace(/_SP_/ig, ' ');
	viewname = encodeURIComponent(viewname);
	jQuery.ajax({ url:app, data:'&deleteView=Y&viewName=' + viewname + '&x=' + Math.random(), dataType: 'html',
		success: function (data, textStatus) {  //Ajax success!
			jQuery('tr[data-id=\'' + view + '\']').remove();
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
			alert('error');
		},
		complete: function (XMLHttpRequest, textStatus) { //Ajax completed
		}
	});
}
function switchDevice (device) {
	 var val = device;
	var parm = '';

	 var str = window.location.toString();
	  str = str.replace(/#/ig, '');
	 str = str.replace(/devicetype=pc/ig, '');
	 str = str.replace(/devicetype=mob/ig, '');
	 str = str.replace(/devicetype=tab/ig, '');
	 str = str.replace('&&', '&');
	str = str.replace('?&', '?');
	 var index = str.indexOf('?');
	 if(index < 0) {
		 val = '?devicetype=' + val;
	 }else{
		 val = '&devicetype=' + val;
	 }
	var url = str + val;
	url = url.replace('&&', '&');
	url = url.replace('?&', '?');

	 window.location = url;
}
//#######################################################
// Use Ajax to get locale message from server
//#######################################################
function localeMsg2 (msg) {
	var localemsg = msg ;
	var url = 'mrc.LocaleMessage?msg='  + encodeURIComponent(msg);

	/*  jQuery.ajax({ url:  url, dataType: "html", async:false,
     success: function(data, textStatus) {
       localemsg = data;
     },
     error: function(XMLHttpRequest, textStatus, errorThrown) {
       	localemsg = msg;
     }
    }); */
	return localemsg;
}
//#############################################################
// Get locale message from server and update a div
//*************************************************************
function localeMsg (msg, msgdiv) {
	var url = 'mrc.LocaleMessage?msg='  + encodeURIComponent(msg);
	jQuery.ajax({ url:  url, dataType: 'html',
		success: function (data, textStatus) {
        	jQuery(msgdiv).html(data);
        	// alert(data);
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) {
       	jQuery(msgdiv).html(msg);
		}
	});
}
//#############################################################
// Set select list (with multiple) asm style. 2011-09-15
//*************************************************************
/* function setAsmSelect () {
	jQuery("select[multiple]").not('select[mrccombobox]').asmSelect({
		addItemTarget: 'bottom', animate: true, highlight: true, sortable: false
	});
}*/
//#############################################################
// Reloacated from retrieval template
//*************************************************************
function changeSelected (fld, val) { // for set dropdown value
	try{
		var x = document.getElementById('F_C_LI_' + fld); x.value = val;
	}catch(err) {

	}
}
function changeSelected2 (fld, val, form) { // for set dropdown value
	try{
		fld = jqSelector(fld);
		var x = jQuery('#' + form).find('#F_C_LI_' + fld).get(0); x.value = val;
	}catch(err) {

	}
}
function jqSelector (str)
{
	return str.replace(/([;&,\.\+\*\~':"\!\^#$%@\[\]\(\)=>\|])/g, '\\$1');
}
function returnData (data) {
	if (window.parent.frames.length > 0) {
		window.parent.getReturnData(data);
		window.parent.closeDialog();
	}else {
		if(window.opener) {window.opener.getReturnData(data);window.close();}
	}
}
