/**
 * Legacy code required to ensure old templates work alongside new templates
 */
var mrcLegacy = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';

	/**
	 * checkLib from mrc_FileManager.js is no longer used, as we switched to loadjs
	 * This function attaches to the window and listens for any calls to checkLib (such as from FusionCharts) and then uses loadjs to fulfill
	 *
	 * @param {String} fileName
	 * @param {String} filePath
	 * @param {function} callbackAfterLoaded
	 */
	/* global loadjs */
	function checkLib (fileName, filePath, callbackAfterLoaded) {
		// @ts-ignore
		var base = window.baseURL || '/mrcjava';
		var file = base + '/' + (filePath ? filePath + '/' : '') + fileName + '.js';

		// @ts-ignore
		if (!loadjs.isDefined(fileName)) {
			// @ts-ignore
			loadjs([file], fileName, { async: false });
		}

		// @ts-ignore
		loadjs.ready(fileName, callbackAfterLoaded);
	}

	// @ts-ignore
	window.checkLib = checkLib;

	return {};
}(window, jQuery));
