//###########################################################
// Version:  2010-05-01, mrc ltd., Lombard, IL, USA
// Functions for web 2.0 pivot report .
// Only jQuery library is used. ALways use jQuery in place of $.
//###########################################################
var pivotReports = new Object();
function MrcPivot (reportId) {
	this.vV1 = '';
	this.vV2 = '';
	this.vS1 = '';
	this.vS2 = '';
	this.vS3 = '';
	this.vS4 = '';
	this.vS5 = '';
	this.vT1 = '';
	this.vT2 = '';
	this.vT3 = '';
	this.c1Value = 'mrc';
	this.c2Value = '';
	this.valid = true;
	this.columnDiv;
	this.avDiv;
	this.rowDiv;
	this.valAry = new Array();
	this.valueString;
	this.selectedDimension;
	this.currentSort;

}
var currentSort;
MrcReport.prototype.setIsPivot = function () {
	var len = this.container.find('#pivotContainer').length;
	if(len > 0) {
		this.isPivot = true;
		 this.pivotReport = new MrcPivot();
	}
};
MrcReport.prototype.collapseRows1 = function (el, id1) {
	id1 = id1.replace(/'/ig, '\\\'');
	var status = jQuery(el).attr('status');
	if(status == 'open') {
		jQuery(el).attr('src', row_collapsed_img);
		this.container.find('tr[rowlevel1=\'' + id1 + '\']').each(function () {
			jQuery(this).hide();
		});
		this.container.find('tr[rowlevel1=\'' + id1 + '\'] > td > img').each(function () {
			jQuery(this).attr('src', row_collapsed_img).attr('status', 'closed');
		});
		 jQuery(el).attr('status', 'closed');
	}else {
		jQuery(el).attr('src', row_expanded_img);
		this.container.find('tr[sub=\'2\'][rowlevel1=\'' + id1 + '\']').each(function () {
			jQuery(this).show();
		});
		jQuery(el).attr('status', 'open');
	}
};
MrcReport.prototype.collapseRows4 = function (el, id1, id2, id3, id4) {

	var status = jQuery(el).attr('status');
	if(status == 'open') {
		jQuery(el).attr('src', row_collapsed_img);
		this.container.find('tr[rowlevel4=\'' + id4 + '\'][rowlevel3=\'' + id3 + '\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\']').each(function () {
			jQuery(this).hide();
		});
		this.container.find('tr[rowlevel4=\'' + id4 + '\'][rowlevel3=\'' + id3 + '\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\'] > td > img').each(function () {
			jQuery(this).attr('src', row_collapsed_img).attr('status', 'closed');
		});
		 jQuery(el).attr('status', 'closed');
	}else {
		jQuery(el).attr('src', row_expanded_img);
		this.container.find('tr[sub=\'5\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\'][rowlevel3=\'' + id3 + '\'][rowlevel4=\'' + id4 + '\']').each(function () {
			jQuery(this).show();
		});
		jQuery(el).attr('status', 'open');
	}
};
MrcReport.prototype.collapseRows3 = function (el, id1, id2, id3) {

	var status = jQuery(el).attr('status');
	if(status == 'open') {
		jQuery(el).attr('src', row_collapsed_img);
		this.container.find('tr[rowlevel3=\'' + id3 + '\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\']').each(function () {
			jQuery(this).hide();
		});
		this.container.find('tr[rowlevel3=\'' + id3 + '\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\'] > td > img').each(function () {
			jQuery(this).attr('src', row_collapsed_img);
			jQuery(this).attr('status', 'closed');
		});
		 jQuery(el).attr('status', 'closed');
	}else {
		jQuery(el).attr('src', row_expanded_img);
		this.container.find('tr[sub=\'4\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\'][rowlevel3=\'' + id3 + '\'][sub=4]').each(function () {
			jQuery(this).show();
		});
		jQuery(el).attr('status', 'open');
	}
};
MrcReport.prototype.collapseRows2 = function (el, id1, id2) {

	var status = jQuery(el).attr('status');
	if(status == 'open') {
		 jQuery(el).attr('src', row_collapsed_img);
		this.container.find('tr[rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\']').each(function () {
			jQuery(this).hide();
		});
		this.container.find('tr[rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\'] > td > img').each(function () {
			jQuery(this).attr('src', row_collapsed_img);
			jQuery(this).attr('status', 'closed');
		});
		 jQuery(el).attr('status', 'closed');
	}else {
	 jQuery(el).attr('src', row_expanded_img);
		this.container.find('tr[sub=\'3\'][rowlevel2=\'' + id2 + '\'][rowlevel1=\'' + id1 + '\'][sub=3]').each(function () {
			jQuery(this).show();
		});
		jQuery(el).attr('status', 'open');
	}
};
function collapseColumns1 (el, id1) {
	var report =  getReport(el);

	var status = jQuery(el).attr('status');

	if(status == 'open') {
		jQuery(el).attr('src', row_collapsed_img);

		report.container.find('td[collevel=\'' + id1 + '\']').each(function () {
			jQuery(this).hide();
		});
		 jQuery(el).attr('status', 'closed');
	}else {
		jQuery(el).attr('src', row_expanded_img);
		report.container.find('td[collevel=\'' + id1 + '\'][headlevel=2]').each(function () {
			jQuery(this).show();
		});

		jQuery(el).attr('status', 'open');
	}
	if(status == 'open') {
		jQuery(el).attr('status', 'closed');
		report.container.find('img[keyval=\'' + id1 + '\']').each(function () {
			jQuery(this).attr('src', row_collapsed_img);
			jQuery(this).attr('status', 'closed');
		});
		report.container.find('td[collevel=\'' + id1 + '\']').each(function () {
			jQuery(this).hide();
		});
		var openFlag = 0;
		report.container.find('tr[headerrow=1] > td > img[status=open]').each(function () {
			openFlag = 1;
		});
		if (openFlag == 0) {
			report.container.find('tr[headerrow=2]').hide();
			report.container.find('tr[headerrow=3]').hide();
		}

	}else {
		report.container.find('td[collevel=\'' + id1 + '\'][headlevel=2]').each(function () {
			jQuery(this).show();
		});
		report.container.find('tr[headerrow=2]').show();
		jQuery(el).attr('status', 'open');
	}
}
function collapseColumns2 (el, id1, id2) {
	var report =  getReport(el);

	var status = jQuery(el).attr('status');

	if(status == 'open') {
		jQuery(el).attr('src', row_collapsed_img);
		report.container.find('td[collevel=\'' + id1 + '\'][collevel2=\'' + id2 + '\']').each(function () {
			jQuery(this).hide();
		});
		 jQuery(el).attr('status', 'closed');
	}else {
		jQuery(el).attr('src', row_expanded_img);
		report.container.find('td[collevel=\'' + id1 + '\'][collevel2=\'' + id2 + '\']').each(function () {
			jQuery(this).show();
		});
		jQuery(el).attr('status', 'open');
	}
	if(status == 'open') {
		jQuery(el).attr('src', row_collapsed_img);
		jQuery(el).attr('status', 'closed');
		report.container.find('td[collevel=\'' + id1 + '\'][collevel2=\'' + id2 + '\']').each(function () {
			jQuery(this).hide();
		});
		 var openFlag = 0;
		report.container.find('tr[headerrow=2] > td > img[status=open]').each(function () {
			openFlag = 1;
		});
		if (openFlag == 0) {
			report.container.find('tr[headerrow=3]').hide();
		}
	}else {
		jQuery(el).attr('src', row_expanded_img);
		report.container.find('td[collevel=\'' + id1 + '\'][collevel2=\'' + id2 + '\']').each(function () {
			jQuery(this).show();
		});
		report.container.find('tr[headerrow=3]').show();
		jQuery(el).attr('status', 'open');
	}
}
MrcReport.prototype.collapseColumnsDrill = function () {
	var headerAryRow1 = new Array();
	var headerArykeyval1 = new Array();
	var headerArykeyval2 = new Array();
	var i = 0;
	this.container.find('tr[headerrow=\'1\'] > td > img[status=\'open\']').each(function () {
		headerAryRow1[i] = jQuery(this).attr('keyval');
		i++;
	});
	i = 0;
	this.container.find('tr[headerrow=\'2\'] > td > img[status=\'open\']').each(function () {
		headerArykeyval1[i] = jQuery(this).attr('keyval');
		headerArykeyval2[i] = jQuery(this).attr('keyval2');
		i++;
	});
	this.container.find('tr[headerrow] > td >img[status]').each(function () {
		jQuery(this).attr('src', row_collapsed_img);
		jQuery(this).attr('status', 'closed');
	});
	/*jQuery("td[collevel]").each(function(){
		jQuery(this).hide();
	});
	jQuery("th[collevel]").each(function(){
		jQuery(this).hide();
	});
	jQuery("tr[headerrow=2]").hide();
	jQuery("tr[headerrow=3]").hide();*/

	for(var j = 0; j < headerAryRow1.length;j++) {
		this.container.find('tr[headerrow=\'2\']').show();
		this.container.find('img[keyval=\'' + headerAryRow1[j] + '\']').attr('src', row_expanded_img);
		this.container.find('td[collevel=\'' + headerAryRow1[j] + '\'][headlevel=\'2\']').each(function () {
			jQuery(this).show();
		});
		this.container.find('img[keyval=' + headerAryRow1[j] + ']').attr('status', 'open');
	}
	for(var j = 0; j < headerAryRow1.length;j++) {
		this.container.find('td[collevel=\'' + headerAryRow1[j] + '\'][headlevel=\'2\']').each(function () {
			jQuery(this).show();
		});
	}
	for(var j = 0; j < headerArykeyval1.length;j++) {
		this.container.find('tr[headerrow=\'3\']').show();
		this.container.find('img[keyval=\'' + headerArykeyval1[j] + '\'][keyval2=\'' + headerArykeyval2[j] + '\']').attr('src', row_expanded_img);
		jQuery('td[collevel=\'' + headerArykeyval1[j] + '\'][collevel2=\'' + headerArykeyval2[j] + '\']').each(function () {
			jQuery(this).show();
		});
		this.container.find('img[keyval=\'' + headerArykeyval1[j] + '\'][keyval2=\'' + headerArykeyval2[j] + '\']').attr('status', 'open');
	}
	for(var j = 0; j < headerArykeyval1.length;j++) {
		this.container.find('td[collevel=\'' + headerArykeyval1[j] + '\'][collevel2=\'' + headerArykeyval2[j] + '\']').each(function () {
			jQuery(this).show();
		});
	}

};
MrcReport.prototype.collapseAll = function () {

	this.container.find('th >img[status]').each(function () {
		jQuery(this).attr('src', row_collapsed_img);
		jQuery(this).attr('status', 'closed');
	});
	this.container.find('td[collevel]').each(function () {
		jQuery(this).hide();
	});
	this.container.find('th[collevel]').each(function () {
		jQuery(this).hide();
	});
	this.container.find('tr[headerrow=2]').hide();
	this.container.find('tr[headerrow=3]').hide();
	this.container.find('tr[rowlevel]').each(function () {
		jQuery(this).hide();
	});

	this.container.find('tr[rowlevel1]').each(function () {
		jQuery(this).hide();
	});
};
MrcReport.prototype.processInputs = function (pivotForm) {
	valid = true;
	var report = this;
	this.container.find('input[group=pivot]').each(function () {
		jQuery(this).val(0);
	});
	this.container.find('input[group=pivotval]').each(function () {
		jQuery(this).val('');
	});
	for(var j = 0;j < report.pivotReport.valAry.length;j++) {
		report.pivotReport.valAry[j] = '';
	}
	valueString = '';
	var index = 0;

	this.container.find('input[group=valGroup]:checked').each(function () {
		report.pivotReport.valAry[index] = jQuery(this).attr('id');
		  if(index == 0) {
			valueString = jQuery(this).attr('id');
		}else{
			valueString += ':' + jQuery(this).attr('id');
		}
		index++;
	});
	if(report.pivotReport.valAry[0] == null || report.pivotReport.valAry[0] == '') {
	    var msg = 'You must select at least one value field';
	    localeMsg(msg, '#dimensionError');//call localeMsg in mrcjsajax.js
		//jQuery("#dimensionError").html('You must select at least one value field');
		this.showDimensionsForError();
		this.container.find('#dimensionError').show();
		valid = false;
		return false;
	}

	var colAry = new Array();
	var rowAry = new Array();
	var x = 0;

	this.container.find('#columns').children().each(function () {
		if(x > 2) {
		    var msg = 'You may only select up to 3 column dimensions';
	        localeMsg(msg, '#dimensionError');//call localeMsg in mrcjsajax.js
			//jQuery("#dimensionError").html('You may only select up to 3 column dimensions.');
			report.showDimensionsForError();
			report.container.find('#dimensionError').show();
			valid = false;
			return false;
		}
		colAry[x] = jQuery(this).attr('id');
		x++;
	});
	x = 0;

	this.container.find('#rows').children().each(function () {

		if(x > 4) {
	        var msg = 'You may only select up to 5 row dimensions';
	        localeMsg(msg, '#dimensionError');//call localeMsg in mrcjsajax.js
			//jQuery("#dimensionError").html('You may only select up to 5 row dimensions.');
			this.showDimensionsForError();
			this.container.find('#dError').show();
			valid = false;
			return false;
		}
		rowAry[x] = jQuery(this).attr('id');
		x++;
	});

	if(rowAry[0] == null || rowAry[0] == '') {
	     var msg = 'You must select at least one row dimension';
	    localeMsg(msg, '#dimensionError');//call localeMsg in mrcjsajax.js
		//jQuery("#dimensionError").html('You must select at least one row dimension');
		report.showDimensionsForError();
		report.container.find('#dimensionError').show();
		valid = false;
		return false;
	}
	if (colAry[0] ) {
		pivotForm.C1.value = colAry[0];
	}
	if (colAry[1] ) {
		pivotForm.C2.value = colAry[1];
	}
	if (colAry[2]) {
		pivotForm.C3.value = colAry[2];
	}
	if (rowAry[0]) {
		pivotForm.R1.value = rowAry[0];
	}
	if (rowAry[1])  {
		pivotForm.R2.value = rowAry[1];
	}
	if (rowAry[2])  {
		pivotForm.R3.value = rowAry[2];
	}
	 if (rowAry[3])  {
		pivotForm.R4.value = rowAry[3];
	}
	if (rowAry[4])  {
		pivotForm.R5.value = rowAry[4];
	}
	if (report.pivotReport.valAry[0])  {
		pivotForm.V1.value = valueString;
	}
	var graphType = report.container.find('select[name=graphTypeOptions]').val();
	report.container.find('input[name=GTYPE]').remove();
	report.container.find(pivotForm).append('<input type="hidden" name="GTYPE" value=""/>');
	pivotForm.GTYPE.value = graphType;
	return valid;

};
/*jQuery(document).ready(function() {

	jQuery("#dimensions").dialog({
           autoOpen: false,
           modal: true,
           height: 'auto',
           width: '420',
            title: 'Drag and Drop the available dimensions',
           position: ['center',100],
           bgiframe: true,
           buttons: { "Ok": function() {
		   valid = getSubTotals();
		   if(valid ==true){
				jQuery(this).dialog("close");
				blockUIandRun(formId2, appUrl2, datapaneId2);
			}
			} } ,
           draggable:true
       });
	   openDimensions();
});*/
jQuery(function () {
	jQuery('#avDimensions, #columns, #rows').sortable({
		connectWith: '.connectedSortable'
	});

});
function openDimensions () {
	jQuery('#dimensions').dialog('open');
	jQuery('#dimensionDialog').show();
	jQuery('#dimensionError').html('');
	jQuery('#dError').hide();
}
function displayGraphs (formId) {
	jQuery('#pivotGraph1').show();
	var value2 = jQuery('input[name=V2]').val();
	if(value2 != '') {
		jQuery('#pivotGraph2').show();
	}
}
function hideDimensions () {
	var closed = jQuery('#hideShowDimensions').attr('closed');
	if( closed == '0') {
		jQuery('#hideShowDimensions').attr('closed', '1');
	}else{
		jQuery('#hideShowDimensions').attr('closed', '0');
	}
	this.hideOrShowDimensions();

}
function hideOrShowDimensions (el) {
	var report = getReport(el);
	report.hideOrShowDimensions();
}
MrcReport.prototype.hideOrShowDimensions = function () {
	var closed = this.container.find('#hideShowDimensions').attr('closed');
	if( closed == '1') {
		this.container.find('#availableCell, #columnCell, #rowCell, #valuesCell, #emptyDiv').show();
	}else{
		this.container.find('#availableCell, #columnCell, #rowCell, #valuesCell, #emptyDiv').hide();
	}
};
MrcReport.prototype.showDimensionsForError = function () {
	this.container.find('#availableCell, #columnCell, #rowCell, #valuesCell, #emptyDiv').show();
	this.container.find('#hideShowDimensions').attr('closed', '1');
};
function switchDimensions (el) {

	var report = getReport(el);
	report.saveDimensions();
	valid = report.getSubTotals();
	if( valid == true) {
		report.blockUIandRun(report.formId2, report.appUrl2, report.datapaneId2);
	}

}
function getReport (el) {
	var reportId = jQuery(el).closest('form').attr('id');
	var report = pivotReports[reportId];
	if(report === undefined) {
		report = pivotReports[Object.keys(pivotReports)[0]];
	}
	return report;
}
MrcReport.prototype.saveDimensions = function () {
	this.pivotReport.columnDiv = this.container.find('#columns').html();
	this.pivotReport.rowDiv = this.container.find('#rows').html();
	this.pivotReport.avDiv = this.container.find('#avDimensions').html();
	this.pivotReport.valueDiv = this.container.find('#pivotValues').html();
};
function callSetupDrill3 (el, values, level) {
	//'this' is the td containing drill icon
	var report = getReport(el);

	var thistr = jQuery(el).parents('tr').eq(0);
	var drillParms = '';// = values;
	 var valAry = values.split(':;');
	 values = '';

	 for(var i = 0; i < valAry.length;i++) {
	   var num = i + 1;
		values += '&DRILL' + num + '=' + encodeURIComponent(valAry[i]);
		drillParms += encodeURIComponent(valAry[i]);
		if((i + 1) != valAry.length) {
			drillParms += ':;';
		}
	 }

	 if(jQuery(el).attr('status') == 'open' || jQuery(el).attr('drilldown') == 'y') {

		if(valAry.length == 1) {
			report.collapseRows1(el, valAry[0]);
		}
		if(valAry.length == 2) {
			report.collapseRows2(el, valAry[0], valAry[1]);
		}
		if(valAry.length == 3) {
			report.collapseRows3(el, valAry[0], valAry[1], valAry[2]);
		}
		if(valAry.length == 4) {
			report.collapseRows4(el, valAry[0], valAry[1], valAry[2], valAry[3]);
		}
	}else{
		jQuery(el).parent().append('<img class="spin-img" src="/mrcjava/image/maploader.gif" />');
		jQuery(thistr).find('.spin-img').show();
		jQuery(el).attr('status', 'open').attr('drilldown', 'y').attr('src', row_expanded_img);

		if (thistr.attr('loaded') != 'y') { //rows have not been loaded

			report.drillDownPivot(thistr, values, level, drillParms);
			thistr.attr('loaded', 'y').attr('shown', 'y');
			jQuery(el).attr('src', row_expanded_img);
			thistr.find('img.drill_icon').attr('title', 'Collapse');
			return;
		}
	}
}
function drillMore (el, drillNext, drillLevel, drillParms) {
	var report =  getReport(el);

	 var thistr = jQuery(el).parents('tr').eq(0);
	 jQuery(thistr).find('.loader').show();
	 var valAry = drillParms.split(':;');
	 drillParms = '';
	 values = '';

	 for(var i = 0; i < valAry.length;i++) {
	    var num = i + 1;
		values += '&DRILL' + num + '=' + encodeURIComponent(valAry[i]);
		drillParms += encodeURIComponent(valAry[i]);
		if((i + 1) != valAry.length) {
			drillParms += ':;';
		}
	 }

	report.drillDownPivotNext(thistr, values, drillNext, drillLevel, drillParms);

}
MrcReport.prototype.drillDownPivotNext = function (theRow, values, drillNext, level, drillParms) {

	//values = encodeURIComponent(values);
	//drillParms = encodeURIComponent(drillParms);
	var report = this;
	var parms = '';
	parms = parmValues(report.formId2, report);
	parms += '&reqtype=22' + values + '&DRILLLEVEL=' + level + '&DRILLPARMS=' + drillParms + '&DRILLNEXT=' + drillNext + '&pivot=y';
	 parms = parms.substring(parms.indexOf('run=1'));
	//Add other selections

	if (this.otherParms) {
		this.otherParms = this.otherParms.replace(/_%_/g, '_%25_');//mrc encode for space
		parms += this.otherParms;
	}

	var appurl = report.appUrl2;

	var urldiv = jQuery(theRow).parent('tbody');
	//alert(urldiv.attr('id') + ",  " + urldiv.attr('appurl'));
	/*if (jQuery(urldiv).length > 0) {
         appurl = jQuery(urldiv).attr('appurl');
        //alert('appurl=' + appurl);
    }
*/
	//make url to Ajax request drill down rows
	var qoramp = '?';
	if(appurl.indexOf('?') >= 0) {
		qoramp = '&';
	}
	var url = appurl + qoramp + parms;
	url += '&x=' + Math.random();
    var qIndex = url.indexOf("?");
    var app = url.substring(0, qIndex);
    var data = url.substring(qIndex + 1);
	jQuery.ajax({  url: app, data:data, type:'POST', dataType: 'html',
		success: function (data, textStatus) {	//Ajax success!
			var pos = data.indexOf('<i>Debug');
			if (pos > 0) { //extract debug info if exists
				var bugInfo = data.substring(pos);
				data = data.substring(0, pos);
				report.container.find('#debug-info').html(bugInfo);
			}
			var s = data.toString();

			s = s.substring(s.indexOf('<tr rowlevel'));
			s = s.substring(0, s.indexOf('<!--<end pivot>-->'));
			report.container.find('.loader').hide();
			jQuery(theRow).after(s).remove();
			report.setupDrill(); //setup drill down for next level
			report.setupPaging();//setup paging in the same level
			report.setupLinkWithParms();
			report.collapseColumnsDrill();
	   },
	   error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
	     var errmsg = 'Error happened during Ajax drilldown: ';
	     errmsg += XMLHttpRequest.responseText;
	     errmsg = '<tr>' + errmsg + '</tr>';
			jQuery(theRow).html(errmsg);
	   },
	   complete: function (XMLHttpRequest, textStatus) { //Ajax completed
	      jQuery(theRow).find('.spin-img').hide();
	   }
	});

	return ;
};
MrcReport.prototype.parmValuesPivot = function (formId) {
	if(this.isPivot == true) {
		var getSub = this.getSubTotals();
	}
	var valueUrl = '';
	var ids = '#' + formId + ' input,' + '#' + formId + ' select';
	var saved = [];
	var report = this;
	this.container.find(ids).each(function (index) {
	  var fieldName = jQuery(this).attr('name');
	  if(fieldName) {
			if(fieldName.substring(0, 2) == 'R0') {
				return true;
			}
	  var sels = report.container.find('#sel_' + fieldName + ' select.mrcselect');//chk select
	  if (sels.length == 0) {
				sels = report.container.find('input[name=' + fieldName + ']'); //chk inputs
	  }

	  var pair = parmPair(fieldName, this);
	  var pairInSaved = false;
	  for (var i in saved) {
	      //do not add dup parm (pair: R001=A%20B, saved[i]: R001=A
	      if ( pair == saved[i] || (pair.indexOf(saved[i]) == 0 && pair.indexOf('%20') > 0) ) {
	         if (sels.length != 2  ) { //for range allow dup
	           pairInSaved = true;
	         }
				}
	  }
	  if (!pairInSaved && pair.length > 0) {
				valueUrl += pair;
				saved.push(pair);
	  }

			var thisId = jQuery(this).attr('id');
			var errid = '#msg_' + thisId; 	 //remove err msg if any
			report.container.find(errid).text('');
		}
	});

	return valueUrl;
};
MrcReport.prototype.drillDownPivot = function (theRow, values, level, drillParms) {
	//values = encodeURIComponent(values);
	//drillParms = encodeURIComponent(drillParms);
	var thisClass = jQuery(theRow).attr('class');
	var thisLevel = getLevelInt(thisClass);

	var report = this;
	var parms = '';
	parms = this.parmValuesPivot(this.formId2);
	parms += '&reqtype=22' + values + '&DRILLLEVEL=' + level + '&DRILLPARMS=' + drillParms + '&pivot=y';
	parms = parms.substring(parms.indexOf('run=1'));
	//Add other selections

	if (this.otherParms) {
		this.otherParms = this.otherParms.replace(/_%_/g, '_%25_');//mrc encode for space
		parms += this.otherParms;
	}

	var appurl = this.appUrl2;

	var urldiv = jQuery(theRow).parent('tbody');
	//alert(urldiv.attr('id') + ",  " + urldiv.attr('appurl'));
	/*if (jQuery(urldiv).length > 0) {
         appurl = jQuery(urldiv).attr('appurl');
        //alert('appurl=' + appurl);
    }
*/
	//make url to Ajax request drill down rows
	var qoramp = '?';
	if(appurl.indexOf('?') >= 0) {
		qoramp = '&';
	}
	 url = appurl + qoramp + parms;
    url += '&x=' + Math.random();
    var qIndex = url.indexOf("?");
    var app = url.substring(0, qIndex);
    var data = url.substring(qIndex + 1);
	jQuery.ajax({  url: app, data:data, type:'POST', dataType: 'html',
		success: function (data, textStatus) {	//Ajax success!
			var pos = data.indexOf('<i>Debug');
			if (pos > 0) { //extract debug info if exists
				var bugInfo = data.substring(pos);
				data = data.substring(0, pos);
				report.container.find('#debug-info').html(bugInfo);
			}
			var s = data.toString();
			s = s.substring(s.indexOf('<tr rowlevel'));
			s = s.substring(0, s.indexOf('<!--<end pivot>-->'));
		 var table = report.container.find('#pvTable').clone();
		 table.find('tbody tr').remove();
		 table.find('tbody').append(s);
		 table.tablesorter({ sortList:currentSort});
			jQuery(theRow).after(table.find('tbody tr'));
			report.setupDrill(); //setup drill down for next level
			report.setupPaging();//setup paging in the same level
			report.setupLinkWithParms();
		 report.collapseColumnsDrill();

	   },
	   error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
	     var errmsg = 'Error happened during Ajax drilldown: ';
	     errmsg += XMLHttpRequest.responseText;
	     errmsg = '<tr>' + errmsg + '</tr>';
			jQuery(theRow).html(errmsg);
	   },
	   complete: function (XMLHttpRequest, textStatus) { //Ajax completed
	      jQuery(theRow).find('.spin-img').remove();
	   }
	});

	return ;
};
function switchGraphValue (el) {
	var report = getReport(el);

	var graphType = report.container.find('select[name=graphTypeOptions] option:selected').val();
	if(graphType == 8) {
		report.container.find('select[name=graphValues] option:nth(1)').attr('selected', 'selected');
	}
	switchGraph(el);
}
function switchGraph (el) {
	var report =  getReport(el);
	addLoadingMessageToContainer(report.container.find('#pivotGraph'));
	var graphType = report.container.find('select[name=graphTypeOptions] option:selected').val();
	var graphValue = report.container.find('select[name=graphValues] option:selected').val();
	var graphValueField = report.container.find('select[name=valueFields] option:selected').val();
	var graphSelectedField = report.container.find('input[name=R1]').val();

	var parms = '';
	parms = parmValues(report.formId2, report);
	parms += '&GTYPE=' + graphType + '&' + graphSelectedField + '=' + graphValue + '&GVALUEFIELD=' + graphValueField + '&pivot=y';
	parms = parms.substring(parms.indexOf('run=1'));
	//Add other selections
	if (report.otherParms) {
		report.otherParms = report.otherParms.replace(/_%_/g, '_%25_');//mrc encode for space
		parms += report.otherParms;
	}

	var appurl = report.appUrl2;

	//make url to Ajax request drill down rows
	var qoramp = '?';
	if(appurl.indexOf('?') >= 0) {
		qoramp = '&';
	}
	 url = appurl + qoramp + parms;
	url += '&x=' + Math.random();
	 jQuery.ajax({  url: url, dataType: 'html',
		success: function (data, textStatus) {	//Ajax success!
			var pos = data.indexOf('<i>Debug');
			if (pos > 0) { //extract debug info if exists
				var bugInfo = data.substring(pos);
				data = data.substring(0, pos);
				report.container.find('#debug-info').html(bugInfo);
			}
		 //substring out the graph
			var s = data.toString();
			var div = '<div id="pivotGraph">';
			s = s.substring(s.indexOf(div) + div.length);

			var fusionEndIndex = s.indexOf('myChart'); // indicates fusion chart
			if( fusionEndIndex >= 0) {
				s = s.substring(0, s.indexOf('</script>') + 9);
			}else{
				s = s.substring(0, s.indexOf('</div>'));
			}
			report.container.find('#pivotGraph').html(s);
			removeLoadingMessageFromContainer (report.container.find('#pivotGraph'));
			report.setupDrill(); //setup drill down for next level
			report.setupPaging();//setup paging in the same level
			report.setupLinkWithParms();
	   },
	   error: function (XMLHttpRequest, textStatus, errorThrown) { //Ajax failed
	     var errmsg = 'Error happened during Ajax drilldown: ';
	     errmsg += XMLHttpRequest.responseText;
	     errmsg = '<tr>' + errmsg + '</tr>';
			//jQuery(theRow).html(errmsg);
	   },
	   complete: function (XMLHttpRequest, textStatus) { //Ajax completed
	      //jQuery(theRow).find('.spin-img').hide();
	   }
	});

	return ;
}
/****************************************************************
Each time the ajax content is inserted, reset all pivot dimension
information. It is lost each time an ajax call is made and needs to be
saved prior to making the ajax call and then reset after.
****************************************************************/
MrcReport.prototype.resetDimensions = function () {
	var report = this;
	this.container.find('#avDimensions, #columns, #rows').sortable({
		connectWith: '.connectedSortable',
		//revert:true,
		start: function (event, ui) {
			report.container.find('#rows').attr('height', '150px').css({borderColor:'black', backgroundColor:'#A7C2D5'});
			report.container.find('#columns').css({borderColor:'black', backgroundColor:'#A7C2D5'});
			report.container.find('#avDimensions').css({borderColor:'black', backgroundColor:'#A7C2D5'});
			report.container.find('#rows').append('<div divType="placeHolder" style=" background:transparent; height:15px;"></div>');
			report.container.find('#columns').append('<div divType="placeHolder" style="width:100px; background:transparent; height:15px;"></div>');
		},
		stop: function (event, ui) {
			report.container.find('div[divType=placeHolder]').each(function () {
				jQuery(this).remove();
			});
			report.container.find('#rows,#columns,#avDimensions').css({borderColor:'transparent', backgroundColor:'transparent'});

		},
		receive: function (event, ui) {
			var i = 0;
			if( jQuery(this).attr('id') == 'columns') {
				jQuery(this).children().each(function () {
					i++;
				});
				if(i > 4) {
					jQuery(ui.sender).sortable('cancel');
					var msg = 'You may only select up to 3 column dimensions';
	            localeMsg(msg, '#dimensionError');//call localeMsg in mrcjsajax.js
					//jQuery("#dimensionError").html('You may only select up to 3 column dimensions.');
					report.showDimensionsForError();
					report.container.find('#dimensionError').show();
				}else{
					report.container.find('#dimensionError').hide();
				}
			}
			if( jQuery(this).attr('id') == 'rows'  ) {
				jQuery(this).children().each(function () {
					i++;
				});
				if(i > 6) {
					jQuery(ui.sender).sortable('cancel');
					 var msg = 'You may only select up to 5 row dimensions';
	                localeMsg(msg, '#dimensionError');//call localeMsg in mrcjsajax.js
					//jQuery("#dimensionError").html('You may only select up to 5 row dimensions.');
					report.showDimensionsForError();
					report.container.find('#dimensionError').show();
				}else{
					report.container.find('#dimensionError').hide();
				}
			}
		}
	});
	report.container.find('#columns').html(report.pivotReport.columnDiv);
	report.container.find('#rows').html(report.pivotReport.rowDiv);
	report.container.find('#avDimensions').html(report.pivotReport.avDiv);
	report.container.find('#pivotValues').find('input').each(function () {
		for(var i = 0; i < report.pivotReport.valAry.length;i++) {
			if(jQuery(this).attr('id') == report.pivotReport.valAry[i]) {
				jQuery(this).attr('checked', 'checked');
				break;
			}else{
				jQuery(this).removeAttr('checked');
			}
		}
	});
	report.hideOrShowDimensions();
};
MrcReport.prototype.setInitialParms = function () {
	var report = this;
	report.container.find('input[name=saveView]').val('N');
	var isFirstTime = report.container.find('input[name=is_first_time]').val();
	var usesview = report.container.find('input[name=usesview]').val();
	if((isFirstTime == 'Y' || isFirstTime == '1') && usesview == 'Y') {
		var R1 = report.container.find('input[name=R1]').val();
		if(R1 && R1 != '') {
			report.container.find('#columns').find('div').each(function () {
				report.container.find('#avDimensions').append(jQuery(this));
			});
			report.container.find('#rows').find('div').each(function () {
				report.container.find('#avDimensions').append(jQuery(this));
			});
			report.container.find('input[group=pivotval]').each(function () {
				var value = jQuery(this).val();
				if(value != '') {
					var name = jQuery(this).attr('name');
					var type = name.substring(0, 1);
					if(value && value != '') {
						var selector =  '#' + value.replace(/#/ig, '\\#');
					}else{
						var selector = '';
					}
					if(type == 'R') {
						var el = report.container.find(selector);
						report.container.find('#rows').append(el);
					}
					if(type == 'C') {
						var el = report.container.find(selector);
						report.container.find('#columns').append(el);
					}
					if(type == 'V') {
						report.container.find('input[group=valGroup]').removeAttr('checked');
						var valAry = value.split(':');
						for(var i = 0;i < valAry.length;i++) {
							report.container.find('#' + valAry[i]).attr('checked', 'checked');
						}
					}
				}
			});
		}

	}
	if(isFirstTime == 'Y') {
		report.container.find('input[name=is_first_time]').val('1');
	}
	if(isFirstTime == '1') {
		report.container.find('input[name=is_first_time]').val('2');
	}
};
MrcReport.prototype.getSubTotals = function () {
	var pivotForm = this.container.find('form').get(0);
	var r1 = this.container.find('input[name=R1]').val();
	var first = this.container.find('input[name=is_first_time]').val();
	if(r1 != '' && first == 'Y') {
		return true;
	}else{
		return this.processInputs(pivotForm);
	}
};
if (!Object.keys) Object.keys = function (o) {
	if (o !== Object(o))
		throw new TypeError('Object.keys called on a non-object');
	var k = [], p;
	for (p in o) if (Object.prototype.hasOwnProperty.call(o, p)) k.push(p);
	return k;
};
function setupPivotSorting (report) {
	if(report.container.find('input[name=mode_select]:checked').val() == 1) {
		return;//disable sorting if the all subtotals is set to load by default
	}
	 var sortEnabled = report.container.find('input[name=\'sortenabled\']').val();
	 if(!sortEnabled || sortEnabled != 1) {
		return;
	 }
	 report.container.find('#pvTable').tablesorter({ widgets: ['staticRow'] }).bind('sortEnd', function (sorter) {
		currentSort = sorter.target.config.sortList;
	});
}
