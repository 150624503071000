
/**
 * Handle form submit events
 */
var formHandler = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';
     var PGM_ATTR = 'data-program', PGM_ATTR_SELECTOR = '[' + PGM_ATTR + ']', KEY_ATTR = 'data-key', AJAX_FORM_INPUT = 'ajax_form',
          BTN_SEARCH_SELECTOR = '[name=btn_search]',BTN_MAINT_SELECTOR = '[name=btn_mnt]',
          FILTER_CONTAINER = '.filter-container',PRIMARY_TABLE = '.primary-data-table', PAGE_BTN_CLASS = 'submit-paging',
          REPORT_PROMPT_SUFFIX = '_report_prompt'
          var SUBMIT_BUTTON = '<input type="hidden" name="{sub}" value="1"/>';
          var programForms = {};
     function getForm(e){
               var $el;
               if(e.target){
                    $el = jQuery(e.currentTarget);
               }else if(e instanceof Element || e instanceof HTMLDocument  ){
                    $el = jQuery(e);
               }else if(e instanceof jQuery){
                    $el = e;
               }
               
               var $pgmElement = $el.closest(PGM_ATTR_SELECTOR);
     
               if(!$pgmElement.length) return {'$form': $el.closest('form'), isForm:true}
     
               return  {'$form': jQuery('body').find('[data-program="' + $pgmElement.attr(PGM_ATTR) + '"]'), isForm:false}
     }
     function getFormFrom$HTML($html){
          if($html){
               if($html.is('[data-program]')) return $html;
               return $html.find('form, [data-program]');
          }
          return jQuery('form,[data-program]');
     }    
     function doSubmit($el, buttonName, forceTraditionalSubmit ) {
		forceTraditionalSubmit = typeof forceTraditionalSubmit !== "undefined" ? forceTraditionalSubmit : false;
          var $parent = $el.closest('[' + PGM_ATTR + ']'),
               pgm = $parent.attr(PGM_ATTR),
               $body =  jQuery('body'),
               $dataAreas = $body.find('[data-program="' + pgm + '"]') ,
               action = pgm.substring(pgm.indexOf('-') + 1),
               isAjaxForm = false,
               $form = jQuery('<form>').attr({method:'POST',action:action}).css('display','none');
          
          reportInit.checkInputAll();
          $dataAreas.find(':input:not(button)').each(function(){
               var $input = jQuery(this);
               var value = jQuery(this).val();

               if(Array.isArray(value)){
                    for(var i = 0; i < value.length;i++){
                         var $newEl = jQuery('<input type="hidden" name="' + $input.attr('name') + '" value="' + value[i] + '"/>');
                         $form.append($newEl);
                    }
                    return;
               }

               var $newEl = jQuery('<input type="hidden" name="' + $input.attr('name') + '" value="' + value + '"/>');

               if($input.is(':radio') || $input.is('input[type=checkbox]')){

                    if(!$input.is(':checked')) return;

               }
               var ajax = getIsAjaxForm($newEl );

               if(ajax){
                    isAjaxForm = true;
               }

               $form.append($newEl);
               
          });
          

          if(!!buttonName) $form.append(SUBMIT_BUTTON.replace('{sub}',buttonName));

          if(isAjaxForm && !forceTraditionalSubmit) {
               submitAjax($form, pgm, $dataAreas);
               return;
          }
          $body.append($form);
          $form.trigger('submit');
	}
  
     function submitAjax($form, pgm, $dataAreas){
          var action = $form.attr('action');
          jQuery.ajax({
               url:action,
               method:'POST',
               dataType:'html',
               data:$form.serialize(),
               success:function(data){
                    var $newProgramAreas = loadPageAreas(pgm, data);
                    $form.remove();

                    pageLoadIntializers($newProgramAreas);//adds a data-oprogram area with hidden fields
                    $newProgramAreas = jQuery('body').find('[data-program="' + pgm + '"]');
                    appInit.initializePage($newProgramAreas)
			     
               }
          });
     }
     function getIsAjaxForm($el){
          if($el.attr('name') == AJAX_FORM_INPUT){
               return parseInt($el.val() || 0) > 0;
          }
          return false;
     }
	function loadPageAreas(pgm, html){
          var $body =  jQuery('body');
          var doc = new DOMParser().parseFromString(html, 'text/html');
          //$body.find(`[data-program="${pgm}"].hidden-fields`).remove();
          $body.find('[data-program="' + pgm + '"]').each(function(){
               var $el = jQuery(this);
               var key= $el.attr(KEY_ATTR);
               var $newEl = jQuery(doc).find('[data-key="' + key + '"]');
               if($newEl.length){
                    $el.replaceWith($newEl);
               }else{
                    $el.remove();
               }
          });
          return $body.find('[data-program="' + pgm + '"]')
     }
     function doPaging(e){
          var $el = target(e);
          var pgm = $el.closest('[' + PGM_ATTR + ']').attr(PGM_ATTR)
          var buttonName = $el.attr('data-pagetype');
          resetPaging($el, pgm);
          doSubmit($el, buttonName)
     }
     function resetPaging ($el, pgm) {
          try {
               var pagenum = parseInt(programForms[pgm].pageNum, 10) || 0;
               var totalPages = parseInt(programForms[pgm].total_pages, 10) || 0;
     
               if (pagenum === totalPages) {
                    $el.closest('.pagination').find('a[data-pagetype="btn_end"], a[data-pagetype="btn_btmm"], a[data-pagetype="btn_btm"], a[data-pagetype="btn_next"]').removeClass(PAGE_BTN_CLASS);
               }
     
               if (pagenum === 1) {
                    $el.closest('.pagination').find('a[data-pagetype="btn_top"], a[data-pagetype="btn_prev"]').removeClass(PAGE_BTN_CLASS);
               }
          }catch (err) {}
     }
     function initializePaging($programEl){
          var pgm = $programEl.attr('data-program');
          var $el = jQuery('[data-program="' + pgm + '"].pagination')
          
          if(!$el.length) return;
         
          var $top = $el.find('.' + PAGE_BTN_CLASS + ':first');
          resetPaging($top, pgm);
     }
     function doSorting (e) {
          var $el = target(e);
          var $parent = $el.closest('[' + PGM_ATTR + ']');
          var pgm = $parent.attr(PGM_ATTR)
          
          var col = $el.attr('data-field');
          
          setInputValue(pgm, 'reorder', 'Y');
     
          var cur_sort_col = getInputValue(pgm, 'cur_sort_col');
          var cur_sort_type = getInputValue(pgm, 'sort_typ');
     
          $parent.find('.highlight').removeClass('highlight');
          $el.closest('th').addClass('highlight');
     
          if (cur_sort_col === col) {
               setInputValue(pgm, 'sort_typ', (cur_sort_type == '0' ? '1' : '0'));
          } else {
               setInputValue(pgm, 'sort_typ','1');
          }
          setInputValue(pgm, 'cur_sort_col',col);

          doSubmit($el, null)
     }
     function setInputValue(pgm, name, value){
          programForms[pgm][name] = value;
     }
     function getInputValue(pgm, name){
          return  programForms[pgm][name];
     }
     function submitViaSubmitButton(e){
          var $el = target(e);
          doSubmit($el, $el.attr('name'));
     }
     function submitViaEnterKey(e){
          var keycode = (e.keyCode ? e.keyCode : e.which);

          if(keycode != '13') return;

          e.preventDefault();
          
          var $el = target(e).closest('[data-program]');

          if($el.is(FILTER_CONTAINER) || $el.is(PRIMARY_TABLE)){
               $el = $el.find(BTN_SEARCH_SELECTOR);
          }else{
               var key = $el.attr(KEY_ATTR);
               $el = jQuery('body').find('[data-key=' + key + '] ' + BTN_MAINT_SELECTOR)
          }

          doSubmit($el, $el.attr('name'));

          return false;
     }
     function clearSelections(e){
          target(e).closest(FILTER_CONTAINER)
               .find(':input[name^="val_"], .scombobox-display').val('');
          
     }
     function setAutoRefresh(){

     }
     function target(e){
          return jQuery(e.currentTarget);
     }
     function initializeArrowsForSort (formIndex, el) {
          var $el = jQuery(el);
          var pgm = $el.attr(PGM_ATTR)
          var key = checkReportPrompt($el) ? pgm + REPORT_PROMPT_SUFFIX : pgm;
          var $table = jQuery('table[data-program="' + pgm + '"]');
		var sortFields = programForms[key].sortFields;
		var sortNames;
		var sortNamesLength;

		if (sortFields === undefined) return;

		sortNames = sortFields.split(',');
		sortNamesLength = sortNames.length;

		$table.find('.sort-col').parent().removeClass('highlight');

		for (var i = 0; i < sortNamesLength; i++) {
			var sortName = sortNames[i];
			var dir = sortName.substring(0, 2);
			var name = sortName.substring(2);

			if (dir === 's0') {
				$table.find('.sorting-col[data-field="' + name + '"] i').removeClass('fa-sort fa-caret-up').addClass('fa-caret-down').closest('th').addClass('highlight');
				$table.find('th[name="' + sortName + '"]').attr('data-sort_type', '1').attr('name', 's1' + name);
			} else {
				$table.find('.sorting-col[data-field="' + name + '"] i').removeClass('fa-sort fa-caret-down').addClass('fa-caret-up').closest('th').addClass('highlight');
				$table.find('th[name="' + sortName + '"]').attr('data-sort_type', '0').attr('name', 's0' + name);
			}
		}
	}

	function initializeAllArrowsForSort ($el) {
          $el.each(initializeArrowsForSort);
         // $hidden.each(initializeMultipleSort);
	}

	function initializeMultipleSort (formIndex, form) {
		var sortFieldsStr = jQuery('input[name=cur_sort_col]').val();
		sortFieldsStr = (sortFieldsStr && sortFieldsStr.toString()) || '';
		if (sortFieldsStr === '') return;

		var	sortTypStr = jQuery('input[name=sort_typ]').val();
		sortTypStr = (sortTypStr && sortTypStr.toString()) || '';
		var sortFieldAry = sortFieldsStr.split(',');
		var sortTypAry = sortTypStr.split(',');
		var $form = jQuery(form);

		for (var i in sortFieldAry) {
			if (i === '0') {
				$form.find('.sort-field-select-box').val(sortFieldAry[i]);
				$form.find('.sort-field-type').val(sortTypAry[i]);
			} else {
				var $rowClone = $form.find('.sort-option-row').first().clone();

				$rowClone.find('.sort-field-select-box').val(sortFieldAry[i]);
				$rowClone.find('.sort-field-type').val(sortTypAry[i]);
				$form.find('.sort-option-row').last().after($rowClone);
			}
		}
	}
     /**
      * Get the encrypted ata from data-program-info attribute and set it as hidden fields div
      * @param {*} $el 
      */
     function setPageInfos($el){
          
          var programInfo = $el.attr('data-page-info');

          if(!!programInfo){
               var pgm = $el.attr('data-program');
               if(!!atob){
                    programInfo = atob(programInfo);
                    var pageInfoObj = JSON.parse(programInfo);
     
                    if(!jQuery('body').find('.hidden-fields[data-program="' + pgm + '"]').length){
                         var $div = jQuery('<div>').addClass('hidden-fields').attr('data-program',pgm);
                         var keys =  Object.keys(pageInfoObj);
                         for(var i = 0 ; i < keys.length;i++){
                              var key = keys[i];
                               if(pageInfoObj[key] === undefined) return;
                              if(key == 'excel'){
                                   var $newEl = jQuery('<input type="hidden" name="excel" id="toexcel" value="0"/>');
                                   $div.append($newEl);
                                   return;
                              }
                              var $newEl = jQuery('<input type="hidden" name="' + key + '" value="' + pageInfoObj[key] + '"/>');
                              $div.append($newEl);
                         }
                         $el.after($div);
                    }
               }
              
          }
               
     }
      /**for report prompt */
     function checkReportPrompt($el){
          return $el.is('.report-prompt');
     }
     /**for report prompt */
     function toggleActiveItem ($promptForm, pgm, name) {
		var $item = $promptForm.find('.list-group-item[data-total="' + name + '"]');
		var value = programForms[pgm][name];

		if (value == 1) {
			$item.addClass('active');
			$promptForm.find('input[name="' + name + '"]').prop('checked', true);
			reportPrompt.setFaActive($item);
		} else {
			$item.removeClass('active');
			$promptForm.find('input[name="' + name + '"]').prop('checked', false);
			reportPrompt.setFaInactive($item);
		}
	}
     function pageLoadIntializers($html){
          var pgm  = !!$html && $html.is('[data-program]') ? $html.attr('data-program') : null;
          var $body = jQuery('body');
          var $programs = !!pgm ? $html : (!!$html && $html.find('[data-program]').length ? $html.find('[data-program]') : $body.find('[data-program]'));
          
          $programs.each(function(){
               try{
                    setPageInfos(jQuery(this));
               }catch(err){
                    console.log(err);
               }
               
              // setAutoRefresh(jQuery(this));
              // initializeAllArrowsForSort(jQuery(this));
               //initializePaging(jQuery(this))
          });
          
     }
     jQuery(function () {
		jQuery('body').on('click', '[data-program] [name=btn_search],[data-program] [name=btn_mnt]', submitViaSubmitButton)
          //.on('click', '.submit-paging', doPaging)
               .on('keydown', '[data-program] :input', submitViaEnterKey)
               .on('click', '.sorting-col', doSorting)
               .on('click', '.clear_form_selections', clearSelections);

          
         
	});
     pageLoadIntializers();
	return {
          pageLoadIntializers:pageLoadIntializers,
          getFormFrom$HTML: getFormFrom$HTML,
          getForm:getForm,
          doSubmit:doSubmit
	};
}(window, jQuery));
