/**
 * All logic for Display and Maintain Calendar
 * @author Duffey
 */
/* global moment */
var mrcCalendar = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';
	var DATE_FMT = 'YYYYMMDD';

	/**
	 * @param {string} mode
	 * @param {{ seqKeys?: Array, seqVals?: Array, fldNames?: Array, fldVals?: Array, nav: number, revert?: function, options: Object }} options
	 */
	/* global mrcModal */
	function calAction (mode, options) {
		var seqKeys = options.seqKeys || [];
		var seqVals = options.seqVals || [];
		var fldNames = options.fldNames || [];
		var fldVals = options.fldVals || [];
		var nav = options.nav;
		var revert = options.revert;
		var $calendar = $(options.options.calendar);
		var $form = $calendar.parents('form');
		var url = $form.attr('action');
		//var url = app;
		var height = $form.find('input[name="maintHt"]').val().toString() || 'auto';
		var width = $form.find('input[name="maintWd"]').val().toString() || 'auto';
		var i;

		url += '?action_mode=' + mode;

		for (i = 0; i < seqKeys.length; i++) {
			url += '&' + encodeURIComponent(seqKeys[i]) + '=' + encodeURIComponent(seqVals[i]);
		}

		for (i = 0; i < fldNames.length; i++) {
			url += '&' + encodeURIComponent(fldNames[i]) + '=' + encodeURIComponent(fldVals[i]);
		}
        //2021-03-22 - rearranged this block - the only available property values are 1 and 0. 0 is maintainer on different page.
		if (nav === 0) {
			window.location.href = url;
		} else if (nav === 1) {
			url += '&hide_header_footer=1&work_with=0&basic=1';

			mrcModal.openBSModal({ height: height, link: '<a modalsubmit="closeAndRefresh" href="#"></a>', title: (mode === 'add' ? 'Add Event' : 'View Event'), url: url, width: width });
		} else if(nav === 2) {
            url += '&one_step=1';
			// @ts-ignore
			jQuery.ajax({ url: url, error: function onCalendarAJAXError (jqXHR, status, err) {
				alert('Error: ' + err);
				revert();
			}});
		}
	}

	function addCalendarEvent (el, options, events) {
		var $calEvent = $(el);
		var attributes = {};
		var seqs = $calEvent.data('seqs');
		var isSeqsArray = $.isArray(seqs);

		$.each($calEvent.get(0).attributes, function assignHTMLAttributesToEvent (i, attrib) {
			attributes[attrib.name] = attrib.value;
		});

		/**
		 * @typedef {Object} eventObj
		 * @property {string} id
		 * @property {Array} seqs
		 * @property {string} title
		 * @property {string} html
		 * @property {Object} attributes
		 * @property {string} [startDate]
		 * @property {string} [startTime]
		 * @property {string} [startTimestamp]
		 * @property {Date} [start]
		 * @property {string} [endDate]
		 * @property {string} [endTime]
		 * @property {string} [endTimestamp]
		 * @property {Date} [end]
		 * @property {boolean} [allDay]
		 */
		/**
		 * @type {eventObj}
		 */
		var event = {
			id: $calEvent.prop('id'),
			seqs: (isSeqsArray ? seqs : JSON.parse(seqs.replace(/'/g, '"'))),
			title: $calEvent.data('title'),
			html: encodeURIComponent($calEvent.html().trim()),
			attributes: attributes
		};

		if (options.startConfig === 1) {
			event.startDate = $calEvent.data('sdt');
			// @ts-ignore
			event.start = moment(event.startDate, options.sDtFormat);
		} else if (options.startConfig === 2) {
			event.startDate = $calEvent.data('sdt');
			event.startTime = $calEvent.data('stm');

			if (event.startTime) {
				// @ts-ignore
				event.start = moment(event.startDate + ' ' + event.startTime, options.sDtFormat + ' ' + options.sTmFormat);
			} else {
				// @ts-ignore
				event.start = moment(event.startDate, options.sDtFormat);
				event.allDay = true;
			}
		} else {
			event.startTimestamp = $calEvent.data('sts');
			// @ts-ignore
			event.start = moment(event.startTimestamp, options.sTsFormat);
		}

		if (options.endConfig === 1) {
			event.endDate = $calEvent.data('edt');
			// @ts-ignore
			event.end = moment(event.endDate, options.eDtFormat);
		} else if (options.endConfig === 2) {
			event.endDate = ($calEvent.data('edt') || event.startDate);
			event.endTime = $calEvent.data('etm');

			if (event.endTime) {
				// @ts-ignore
				event.end = moment(event.endDate + ' ' + event.endTime, (options.eDtFormat || options.sDtFormat) + ' ' + options.eTmFormat);
			} else {
				// @ts-ignore
				event.end = moment(event.endDate, options.eDtFormat);
			}
		} else if (options.endConfig === 3) {
			event.endTimestamp = $calEvent.data('ets');
			// @ts-ignore
			event.end = moment(event.endTimestamp, options.eTsFormat);
		}

		if (event.end < event.start) event.end = options.blankEndDate ? new Date() : null;

		events.push(event);
	}

	function populateAllEventsGenerator (options) {
		return function populateAllEvents (start, end, timezone, callback) {
			var events = [];

			$('.cal_event').each(function (i, el) {
				addCalendarEvent(el, options, events);
			});

			callback(events);
		};
	}

	function defaultClickEventGenerator (options) {
		return function defaultClickEvent (event, jsEvent, view) {
            
            if(!!event.attributes['data-noclick']) return;
            
			calAction('view', {
				seqKeys: options.seqs,
				seqVals: event.seqs,
				nav: options.nav,
				options: options
			});
		};
	}



	function defaultEventResizeEventGenerator (options) {
		return function defaultEventResizeEvent (event, delta, revertFunc, jsEvent, ui, view) {
			// @ts-ignore
			var eventStart = moment(event.end - (delta.asMinutes() * 60000)).format(DATE_FMT);
			// @ts-ignore
			var eventEnd = moment(event.end).format(DATE_FMT);
			var check;
			var endFields;
			var endVal;
			var endVals;

			if (event.end === null) {
				event.end = event.start;
			} else if (delta.asDays() === 0 && eventEnd !== eventStart) {
				// @ts-ignore
				event.end = moment(eventStart + '235959', 'YYYYMMDDHHmmss');
			}

			if (options.endConfig === 1) {
				endFields = [options.eDt];
				// @ts-ignore
				endVal = moment(event.end).format(options.eDtFormat);
				endVals = [endVal];
			} else if (options.endConfig === 2) {
				endFields = [options.eDt, options.eTm];
				// @ts-ignore
				endVal = moment(event.end).format(options.eDtFormat || options.sDtFormat) + ' ' + moment(event.end).format(options.eTmFormat);
				// @ts-ignore
				endVals = [moment(event.end).format(options.eDtFormat || options.sDtFormat), moment(event.end).format(options.eTmFormat)];
			} else {
				endFields = [options.eTs];
				// @ts-ignore
				endVal = moment(event.end).format(options.eTsFormat);
				endVals = [endVal];
			}

			check = (options.confirm === 0) || confirm('Are you sure you want to change this event\'s end date to ' + endVal + '?');

			if (check) {
				calAction('update', {
					seqKeys: options.seqs,
					seqVals: event.seqs,
					fldNames: endFields,
					fldVals: endVals,
					nav: 2,//options.nav,
					revert: revertFunc,
					options: options
				});
			} else {
				revertFunc();
			}
		};
	}

	function defaultSelectEventGenerator (options) {
		return function defaultSelectEvent (startDate, endDate, jsEvent, view) {
			var dateFields = [];
			var dateVals = [];
			var allDay = !startDate.hasTime() && !endDate.hasTime();

			if (options.startConfig === 1) {
				dateFields.push(options.sDt);
				// @ts-ignore
				dateVals.push(moment(startDate).format(options.sDtFormat));
			} else if (options.startConfig === 2) {
				dateFields.push(options.sDt);
				// @ts-ignore
				dateVals.push(moment(startDate).format(options.sDtFormat));
				if (!allDay) {
					dateFields.push(options.sTm);
					// @ts-ignore
					dateVals.push(moment(startDate).format(options.sTmFormat));
				}
			} else {
				dateFields.push(options.sTs);
				// @ts-ignore
				dateVals.push(moment(startDate).format(options.sTsFormat));
			}

			if (options.endConfig === 1) {
				dateFields.push(options.eDt);
				// @ts-ignore
				dateVals.push(moment(endDate).format(options.eDtFormat));
			} else if (options.endConfig === 2) {
				dateFields.push(options.eDt);
				// @ts-ignore
				dateVals.push(moment(endDate).format(options.eDtFormat));

				if (!allDay) {
					dateFields.push(options.eTm);
					// @ts-ignore
					dateVals.push(moment(endDate).format(options.eTmFormat));
				}
			} else {
				dateFields.push(options.eTs);
				// @ts-ignore
				dateVals.push(moment(endDate).format(options.eTsFormat));
			}

			calAction('add', {
				fldNames: dateFields,
				fldVals: dateVals,
				nav: options.nav,
				options: options
			});
		};
	}

	function defaultDayClickEvent1Generator (options) {
		return function defaultDayClickEvent1 (date, jsEvent, view) {
			var dateFields = [];
			var dateVals = [];
			var allDay = !date.hasTime();

			if (view.name !== 'month') return;

			if (options.startConfig === 1) {
				dateFields.push(options.sDt);
				// @ts-ignore
				dateVals.push(moment(date).format(options.sDtFormat));
			} else if (options.startConfig === 2) {
				dateFields.push(options.sDt);
				// @ts-ignore
				dateVals.push(moment(date).format(options.sDtFormat));

				if (!allDay) {
					dateFields.push(options.sTm);
					// @ts-ignore
					dateVals.push(moment(date).format(options.sTmFormat));
				}
			} else {
				dateFields.push(options.sTs);
				// @ts-ignore
				dateVals.push(moment(date).format(options.sTsFormat));
			}

			calAction('add', {
				fldNames: dateFields,
				fldVals: dateVals,
				nav: options.nav,
				options: options
			});
		};
	}

	function defaultDayClickEvent2Generator (options) {
		return function defaultDayClickEvent2 (date, jsEvent, view) {
			var dateFields = [];
			var dateVals = [];
			var allDay = !date.hasTime();

			if (options.startConfig === 1) {
				dateFields.push(options.sDt);
				// @ts-ignore
				dateVals.push(moment(date).format(options.sDtFormat));
			} else if (options.startConfig === 2) {
				dateFields.push(options.sDt);
				// @ts-ignore
				dateVals.push(moment(date).format(options.sDtFormat));

				if (!allDay) {
					dateFields.push(options.sTm);
					// @ts-ignore
					dateVals.push(moment(date).format(options.sTmFormat));
				}
			} else {
				dateFields.push(options.sTs);
				// @ts-ignore
				dateVals.push(moment(date).format(options.sTsFormat));
			}

			calAction('add', {
				fldNames: dateFields,
				fldVals: dateVals,
				nav: options.nav,
				options: options
			});
		};
	}

	function defaultEventDropEventGenerator (options) {
		return function defaultEventDropEvent (event, delta, revertFunc, jsEvent, ui, view) {
			// @ts-ignore
			var theView = $(options.calendar).fullCalendar('getView');
			var dateFields = [];
			var dateVals = [];
			var startVal;
			var endVal;
			var check;

			if (theView.name.indexOf('agenda') > -1 && event.allDay) {
				revertFunc();

				return;
			}

			if (!event.end || event.end <= event.start) event.end = new Date(event.start.valueOf() + 1000);

			if (options.startConfig === 1) {
				dateFields.push(options.sDt);
				// @ts-ignore
				startVal = moment(event.start).format(options.sDtFormat);
				dateVals.push(startVal);
			} else if (options.startConfig === 2) {
				dateFields.push(options.sDt);
				// @ts-ignore
				startVal = moment(event.start).format(options.sDtFormat);
				dateVals.push(startVal);

				if (!event.allDay) {
					dateFields.push(options.sTm);
					// @ts-ignore
					startVal = startVal + ' ' + moment(event.start).format(options.sTmFormat);
					// @ts-ignore
					dateVals.push(moment(event.start).format(options.sTmFormat));
				}
			} else {
				dateFields.push(options.sTs);
				// @ts-ignore
				startVal = moment(event.start).format(options.sTsFormat);
				dateVals.push(startVal);
			}

			if (options.endConfig === 1) {
				dateFields.push(options.eDt);
				// @ts-ignore
				endVal = moment(event.end).format(options.eDtFormat);
				dateVals.push(endVal);
			} else if (options.endConfig === 2) {
				if (options.eDt) {
					dateFields.push(options.eDt);
					// @ts-ignore
					endVal = moment(event.end).format(options.eDtFormat);
					dateVals.push(endVal);
				}
				if (options.eTm) {
					dateFields.push(options.eTm);
					// @ts-ignore
					endVal = startVal + ' ' + moment(event.end).format(options.eTmFormat);
					// @ts-ignore
					dateVals.push(moment(event.end).format(options.eTmFormat));
				}
			} else if (options.endConfig === 3) {
				dateFields.push(options.eTs);
				// @ts-ignore
				endVal = moment(event.end).format(options.eTsFormat);
				dateVals.push(endVal);
			}

			check = (options.confirm === 0) || confirm('Are you sure you want to change this event\'s start date to ' + startVal + '?');

			if (check) {
				calAction('update', {
					seqKeys: options.seqs,
					seqVals: event.seqs,
					fldNames: dateFields,
					fldVals: dateVals,
					nav: 2,
					revert: revertFunc,
					options: options
				});
			} else {
				revertFunc();
			}
		};
	}

	function convertMomentToFullCalendar (mFmt) {
		var fcFmt = mFmt.replace('a', 't');

		return fcFmt;
	}

	function renderEvent (event, $element) {
		var $content = $element.find('.fc-title');
		var attributes = event.attributes || {};

		for (var key in attributes) {
			if (!attributes.hasOwnProperty(key)) continue;

			if (key === 'class') {
				$element.addClass(attributes[key]);
			} else {
				$element.attr(key, attributes[key]);
			}
		}

		$content.html(decodeURIComponent(event.html));
	}

	function getCurrentDateFromForm ($form) {
		var year = parseInt($form.find('input[name="current_year"]').val().toString(), 10);
		var month = parseInt($form.find('input[name="current_month"]').val().toString(), 10) - 1;
		var day = parseInt($form.find('input[name="current_day"]').val().toString(), 10);

		// @ts-ignore
		return moment(new Date(year, month, day));
	}

	function setCurrentDateInForm ($form, date) {
		var $currentYear = $form.find('input[name="current_year"]');
		var $currentMonth = $form.find('input[name="current_month"]');
		var $currentDay = $form.find('input[name="current_day"]');

		$currentYear.val(date.year());
		$currentMonth.val(date.month() + 1);
		$currentDay.val(date.date());
	}

	function handleViewChange (view, $element) {
        var $form = $element.parents('form');
        var currentApp = $form.attr('id');
		var calendarStart = view.start.clone();
		var calendarEnd = view.end.clone().subtract(1, 'day');
		// @ts-ignore
		var firstDate = jQuery.fullCalendar.moment($form.find('input[name="firstDate"]').val(), DATE_FMT);
		// @ts-ignore
		var lastDate = jQuery.fullCalendar.moment($form.find('input[name="lastDate"]').val(), DATE_FMT);
		var $currentView = $form.find('input[name="current_view"]');
		var currentView = $currentView.val().toString();
		var $year = $form.find('select[name="year"]');
		var $month = $form.find('select[name="month"]');
		var year = $year.val().toString();
		var month = $month.val().toString();
		// @ts-ignore
		var viewDate = moment(year + month, 'YYYYM');
		var currentDate = view.intervalStart.clone();
		var hasChanged = false;

		if (view.name !== currentView){
            localStorage.setItem(currentApp + 'defaultView',view.name);
            $currentView.val(view.name);
        } 

		setCurrentDateInForm($form, currentDate);
		var end = calendarEnd.stripTime().stripZone();
		var last = lastDate.stripTime().stripZone();
		var start = calendarStart.stripTime().stripZone();
		var first = firstDate.stripTime().stripZone();
		
		if (start.isBefore(first, 'day')) {
			viewDate = moment(currentDate.year().toString() + (currentDate.month()+1).toString(), 'YYYYM'); 
			hasChanged = true;
			
		} else if (end.isAfter(last, 'day')) {
			viewDate = moment(currentDate.year().toString() + (currentDate.month()+1).toString(), 'YYYYM'); 
			hasChanged = true;
		}

		if (hasChanged) {
			handleCalendarLoading(true, view);
			$month.val(viewDate.month() + 1);
			$year.val(viewDate.year());
			$form.submit();
		}
	}

	/* global addLoadingMessageToContainer, removeLoadingMessageFromContainer */
	function handleCalendarLoading (isLoading, view) {
		var $form = jQuery(view.el).parents('form').first();

		if (isLoading) {
			// @ts-ignore
			addLoadingMessageToContainer($form); // ajax_forms.js, line 694
		} else {
			// @ts-ignore
			removeLoadingMessageFromContainer($form); // ajax_forms.js, line 701
		}
	}

	function addOptions (options, calendar) {
		var timeFormat;
		var $calendar = $(calendar);
		var $form = $calendar.closest('form');
		var currentView = $form.find('input[name="current_view"]').val().toString();
		var currentDate = getCurrentDateFromForm($form);
        var currentApp = $form.attr('id');
        var localViewSaved = localStorage.getItem(currentApp + 'defaultView');

        if(!!localViewSaved) currentView = localViewSaved;

		options.calendar = calendar;

		if (options.sTs) {
			options.startConfig = 3;
		} else if (options.sTm) {
			options.startConfig = 2;
		} else {
			options.startConfig = 1;
		}

		if (options.eTs) {
			options.endConfig = 3;
		} else if (options.eTm) {
			options.endConfig = 2;
		} else if (options.eDt) {
			options.endConfig = 1;
		} else {
			options.endConfig = 0;
		}

		/**
		 * @typedef {Object} fullcalendarOptions
		 * @property {number} aspectRatio
		 * @property {Object} defaultDate
		 * @property {function} [dayClick]
		 * @property {string} defaultView
		 * @property {function} [eventClick]
		 * @property {function} [eventDrop]
		 * @property {function} eventRender
		 * @property {function} [eventResize]
		 * @property {function} events
		 * @property {boolean} fixedWeekCount
		 * @property {boolean} handleWindowResize
		 * @property {function} loading
		 * @property {boolean} navLinks
		 * @property {function} [select]
		 * @property {string} themeSystem
		 * @property {string} [timeFormat]
		 * @property {function} viewRender
		 * @property {string} weekMode
		 */
		/**
		 * @type {fullcalendarOptions}
		 */
		var defaultOpts = {
			aspectRatio: 3,
			defaultDate: currentDate,
			defaultView: currentView,
			eventRender: renderEvent,
			events: populateAllEventsGenerator(options),
			fixedWeekCount: false,
			handleWindowResize: true,
			loading: handleCalendarLoading,
			navLinks: true,
			themeSystem: 'bootstrap4',
			viewRender: handleViewChange,
            weekMode: 'liquid',
            height:'auto'
		};

		if (options.updateMode === 1) defaultOpts.eventClick = defaultClickEventGenerator(options);

		if (options.eventDurationEditable) defaultOpts.eventResize = defaultEventResizeEventGenerator(options);

		if (options.selectable) {
			defaultOpts.select = defaultSelectEventGenerator(options);

			if (typeof options.selectable !== 'boolean') defaultOpts.dayClick = defaultDayClickEvent1Generator(options);
		} else if (options.addMode) {
			defaultOpts.dayClick = defaultDayClickEvent2Generator(options);
		}

		if (options.editable) defaultOpts.eventDrop = defaultEventDropEventGenerator(options);

		if (options.sTm) {
			timeFormat = convertMomentToFullCalendar(options.sTmFormat);
		} else if (options.sTs) {
			timeFormat = 'h:mm';
		}

		if (options.eTm) {
			timeFormat = timeFormat ;//+ ' - ' + convertMomentToFullCalendar(options.eTmFormat) + '';
		} else if (options.eTs) {
			timeFormat = timeFormat + ' - h:mm';
		}

		defaultOpts.timeFormat = timeFormat;

		$.extend(defaultOpts, options);

		return defaultOpts;
	}

	/* global setCalendarFunctions */
	function setupCalendarForms ($html) {
		var selector = 'form[data-app_type="CM"]';
		var $forms = ($html ? $html.find(selector) : $(selector));

		$('body').off('click.mrcCalendarActionMode').on('click.mrcCalendarActionMode', '.btn.action_mode', switchCalendarActionMode);

		$forms.each(function (i, el) {
			// @ts-ignore
			setCalendarFunctions(el); // ajax_forms.js, line 552
		});
	}

	function isTodayOnScreen (calendar) {
		var calendarSelector = calendar || '#calendar';
		// @ts-ignore
		var view = $(calendarSelector).fullCalendar('getView');
		var curr = new Date();
		var start = view.start;
		var end = view.end;
		var displayed;

		if (view.name.indexOf('Day') === -1) {
			displayed = (curr >= start && curr <= end);
		} else {
			displayed = (curr >= start && curr < end);
		}

		return displayed;
	}

	function switchCalendarActionMode (ev) {
		var $label = $(ev.currentTarget);
		var $radio = $label.find('input[type="radio"]');
		var $form = $label.parents('.ajax_content').find('form');
		var $modal = $form.parents('.modal');
		var actionMode = $radio.val();
		var $actionMode = $form.find('input[name="action_mode"]');

		if ($modal.length > 0) $modal.data('skip-check', true);

		$actionMode.val(actionMode);
		if(actionMode.substring(0,1) == 'V') $form.find('input[name="one_step"]').val(0);
		$form.submit();
	}

	/* global loadjs */
	function startCalendar (options, calendarSelector) {
        // @ts-ignore

		// @ts-ignore
		if (!loadjs.isDefined('fullcalendar')) {
			// @ts-ignore
			loadjs(['../assets/vendor/fullcalendar/fullcalendar.min.css', '../assets/vendor/fullcalendar/fullcalendar.min.js'], 'fullcalendar', { async: false });
		}

		// @ts-ignore
		loadjs.ready('fullcalendar', function fullCalendarHasLoaded () {
			fullCalendarLoaded(options, calendarSelector);
		});
	}

	function fullCalendarLoaded (options, calendarSelector) {
		var calendar = (calendarSelector || '#calendar');
		var $calendar = $(calendar);

		// @ts-ignore
		$calendar.fullCalendar(addOptions(options, calendar));

		if (options.addMode) $('.fc-week td').css('cursor', 'pointer').attr('title', 'Click on day to add a new event');
	}

	// @ts-ignore
	if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
		// @ts-ignore
		window.addOptions = addOptions;
		// @ts-ignore
		window.DATE_FMT = DATE_FMT;
		// @ts-ignore
		window.isTodayOnScreen = isTodayOnScreen;
	}

	return {
		setupCalendarForms: setupCalendarForms,
		startCalendar: startCalendar
	};
}(window, jQuery));
