/**
 *
 */
const columnToggle = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	function setColumnsSelected (form) {
		var $form = jQuery(form);
		var name = $form.attr('action') || $form.attr('data-program');

		if (!name) return;

		name = name.replace('.', '_');

		setSelectedColumns(name, $form);

		$form.find('.field-selection-menu input[name!="selectall"]').click(function (ev) {
			setColumnSelectedCookie(name, $form);
		});

		$form.find('input[name="selectall"]').click(function (ev) {
			var $allCheck = jQuery(this);

			$form.find('input[name="fieldselect"]').prop('checked', $allCheck.prop('checked'));

			setColumnSelectedCookie(name, $form);
		});

		$form.find('#searchfieldlist').keyup(function (ev) {
			var val = jQuery(this).val().toString();

			if (val === '') {
				jQuery(this).siblings('[data-field]').show();

				return;
			}

			jQuery(this).siblings('[data-field]').filter(function (i, el) {
				var fieldtext = jQuery(el).attr('data-field').toLowerCase();
				var hideElement = (fieldtext.indexOf(val) >= 0) ? false : true;

				return hideElement;
			}).hide();
		});

		$form.find('.apply-field-selections').click(toggleColumnVisibility);
          let item  = '<i class="fa fa-sort fa-rotate-90 reorder-handle" title="Drag to reorder column"></i>';
          if($form.is('.reorder_column')){
               $form.find('tr.column-headers:first th').prepend(item);
          }else{
               $form.find('.reorder_column tr.column-headers:first th').prepend(item);
          }
		

		initDragTable($form);
	}

	/* global loadjs */
	function initDragTable ($form) {
		var $reorderColumns = $form.find('.reorder_column');
          if($form.is('.reorder_column')){
               $reorderColumns = $form;
          }

		if ($reorderColumns.length === 0) return;

		// @ts-ignore
		if (!loadjs.isDefined('dragtable')) {
			// @ts-ignore
			loadjs(['../assets/vendor/dragtable/dragtable.css', '../assets/vendor/dragtable/jquery.dragtable.js'], 'dragtable', { async: false });
		}

		// @ts-ignore
		loadjs.ready('dragtable', function dragtableHasLoaded () {
			setupDragTable($form);
		});
	}

	function setupDragTable ($form) {
		var $reorderColumns = $form.find('.reorder_column');
          if($form.is('.reorder_column')){
               $reorderColumns = $form;
          }
		// @ts-ignore
		$reorderColumns.dragtable({
			maxMovingRows: 1,
			dragHandle: '.reorder-handle',
			persistState: function persistColumnOrder (table) {
				setColumnOrder($form);
			}
		});
	}

	function setColumnOrder ($form) {
		var futureDate = new Date();

		futureDate.setDate(futureDate.getDate() + 5000);

		var columnOrder = '', delimiter = '';
		var count = 0;

		// Get reorder field list
		$form.find('.reorder_column th[data-col]').each(function (i, el) {
			if (count > 0) delimiter = '-';

			columnOrder += delimiter + jQuery(el).attr('data-col');

			count++;
		});

		var name = $form.attr('action') || $form.attr('data-program');

		name = name.replace('.', '_');

		document.cookie = name + '_column_order=' + columnOrder + ';expires=' + futureDate.toUTCString() + ';'; //create cookie
	}

	function toggleColumnVisibility (ev) {
          let formInfo = formHandler.getForm(ev.currentTarget);
          var $form = formInfo.$form;
          if(!formInfo.isForm) {
               formHandler.doSubmit(jQuery(ev.currentTarget));
               return;
          }
		jQuery(ev.currentTarget).closest('form').submit();
	}

	function setColumnSelectedCookie (name, $form) {
		var futureDate = new Date();

		futureDate.setDate(futureDate.getDate() + 5000);

		var columnOrder = '';
		var delimiter = '';
		var count = 0;

		$form.find('input[type="checkbox"]:not(:checked)').each(function (i, el) {
			if (count > 0) delimiter = '-';

			var val = jQuery(el).attr('value');

			if (val == '1') return;

			columnOrder += delimiter + val;
			count++;
		});

		document.cookie = name + '_column_remove=' + columnOrder + ';expires=' + futureDate.toUTCString() + ';'; //create cookie
	}

	function setSelectedColumns (action, $form) {
		var removeCookie = getCookie(action + '_column_remove');

		if (removeCookie === undefined) {
			$form.find('.field-selection-menu input[type=checkbox]').prop('checked', true);
			return;
		}
		var removeFieldAry = removeCookie.split('-');

		$form.find('.field-selection-menu input').prop('checked', true);

		for (var i = 0; i < removeFieldAry.length; i++) {
			$form.find('.field-selection-menu input[value="' + removeFieldAry[i] + '"]').prop('checked', false);
		}
	}

	function getCookie (c_name) {
		var ARRcookies = document.cookie.split(';');
		var i;
		var x;
		var y;

		for (i = 0; i < ARRcookies.length; i++) {
			x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
			y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
			x = x.replace(/^\s+|\s+$/g, '');

			if (x === c_name) return unescape(y);
		}
	}

	jQuery('body').on('click', '.field-selection-menu', function (ev) {
		/* jshint -W030 */
		jQuery(this).is('.show') && ev.stopPropagation();
		/* jshint +W030 */
	});

	return {
		setColumnsSelected: setColumnsSelected
	};
}(window, jQuery));
