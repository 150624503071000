var mrcGeoMaps = (function (window, $) {
     const selector = '.render-geo-map';
     const mapScriptIDPrefix = 'mapScript';
     function loadGeoMaps($html ){

          const $maps  = (!!$html ? $html.find(selector) : jQuery(selector));

          $maps.each(function(){
               let $div = jQuery(this);
               let id =  $div.attr('id') 
               let scriptId = mapScriptIDPrefix + id;

               if (!loadjs.isDefined(scriptId)) {

                    loadjs([$div.attr('data-script')], scriptId, { async: false });
               }

               loadjs.ready(scriptId, function () {
                    let key = 'mrc' + id + 'Map';
                    console.log(key);
                    console.log(window[key]);
                    window[key].startCreateMap();
               });
              
          });
     }

	return {
          loadGeoMaps
	}

}(window, jQuery));