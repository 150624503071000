/**
 * All combobox logic
 */
var mrcComboboxes = (function (window, $) { // eslint-disable-line no-unused-vars
	'use strict';

	var COMBOBOX_SELECTOR = 'select[mrccombobox]';

	function getCBoxAttr ($el, attr) {
		return $el.data(attr);
	}

	function changeDisabled ($el) {
		var disabled = $el.prop('disabled');

		if (disabled) $el.data('disabled', 'true').prop('disabled', false);

		return disabled;
	}

	function buildComboboxes (form) {
		var $comboboxes = (form ? $(form).find(COMBOBOX_SELECTOR) : $(COMBOBOX_SELECTOR));
        
		$comboboxes.each(function (i, el) {
            var $cbox = jQuery(el);
            var selectedVal = getCBoxAttr($cbox, 'selectedValue');

            if(selectedVal && selectedVal != '' && $cbox.find('option[value="' + selectedVal +'"]').length == 0 && !$cbox.attr('multiple') ){
                $cbox.append('<option value="' + selectedVal + '" selected="selected">' + selectedVal +'</option>');
            }
			var oc = $cbox.attr('onchange');
			// Added this to remove onchange temporarily to set value
			if (oc) $cbox.removeAttr('onchange').data('oc', oc);

			// @ts-ignore
			$cbox.scombobox({
				empty: ($cbox.find('option[value=""]').length > 0 || $cbox.attr('multiple')) && !$cbox.val(),
				disabled: changeDisabled($cbox),
				sort: false,
				removeDuplicates: false,
				fullMatch: (getCBoxAttr($cbox, 'fullMatch') === 1),
				highlight: (getCBoxAttr($cbox, 'highlight') === 1),
				filterIgnoreCase: true,
				expandOnFocus: true,
				expandOnFocusWithValue: true,
				wrap: false,
				forbidInvalid: (getCBoxAttr($cbox, 'allowNew') !== 1),
				invalidAsValue: (getCBoxAttr($cbox, 'allowNew') === 1),
				highlightInvalid: (getCBoxAttr($cbox, 'allowNew') !== 1),
				tabindex: getCBoxAttr($cbox, 'tabindex'),
				reassignId: true,
				mode: ($cbox.attr('multiple') ? 'checkboxes' : 'default')
            });
		});

		// Added this to better set width based on values and replace onchange for cascading
		jQuery('.scombobox').each(function (i, el) {
			var $cbox = jQuery(el);
			var $dd = $cbox.find('select');
			var ddWidth = $dd.css('width');
			var oc = $dd.data('oc');

			$cbox.find('.scombobox-display-div').css('minWidth', ddWidth);

			if (oc) $cbox.find('.scombobox-value').attr('onchange', oc);
		});
	}

	/* global loadjs */
	function initComboboxes (form) {
		var $comboboxes = (form ? $(form).find(COMBOBOX_SELECTOR) : $(COMBOBOX_SELECTOR));

		if ($comboboxes.length === 0) return;

		// @ts-ignore
		if (!loadjs.isDefined('comboboxes')) {
			// @ts-ignore
			loadjs(['../mrcclasses/combobox/css/jquery.scombobox.css', '../mrcclasses/combobox/js/jquery.easing.min.js', '../mrcclasses/combobox/js/jquery.scombobox.js'], 'comboboxes', { async: false });
		}

		// @ts-ignore
		loadjs.ready('comboboxes', function comboboxesHaveLoaded () {
			buildComboboxes(form);
		});
	}

	/**
	 * buildComboboxes is called from ajax_forms, so we need to put the write one into the window based on JS version
	 */
	// @ts-ignore
	if (!window.MRCCONSTANTS || window.MRCCONSTANTS.VERSION < 1) {
		// @ts-ignore
		window.buildComboboxes = buildComboboxes;
	} else {
		// @ts-ignore
		window.buildComboboxes = initComboboxes;
	}

	return {};
}(window, jQuery));
