/**
 * Sets up ajax suggest helpers, handles required inputs
 */
var ajaxFeatures = (function (window, jQuery) { // eslint-disable-line no-unused-vars
	'use strict';

	/**
	 * Check if field is set to required
	 * @param {HTMLFormElement} form
	 * @returns {boolean}//
	 */
	function checkRequiredFields (form) {
		var validated = true;

		jQuery(form).find(':input[mrcrequired="required"]').each(
			/**
			 * @param el {HTMLInputElement}
			 */
			function toggleRequiredMessage (i, el) {
				var $input = jQuery(el);
				var field = $input.attr('id');

				jQuery('#' + field + 'fieldRequired').hide();

				if (el.tagName === 'INPUT' && el.value === '' || el.value === '0') {
					jQuery('#' + field + 'fieldRequired').show();

					validated = false;
				} else if (el.tagName === 'TEXTAREA' && (el.value === '' || el.value === '0')) {
					jQuery('#' + field + 'fieldRequired').show();

					validated = false;
				}
			}
		);

		return validated;
	}
    function setupAjaxDropdownWithTimeout(index, ary){

        if(index == ary.length) return;

        var $select = jQuery(ary[index]);
        var lookupTable = $select.attr('mrclookup');
        var cur_fld = $select.attr('id'); // field to apply ajax suggest to
        var cascadeField = $select.attr('mrccascadefield'); // name of field in current app to get value of and pass to retieval
        var lookupField = $select.attr('mrclookupfield'); // name of field to lookup in retrieval

        if (cascadeField == null || cascadeField === '') cascadeField = '';

        // @ts-ignore
        set_ajax_dropdown(lookupTable, cur_fld, cascadeField, lookupField, 'load'); // mrcajax.js, line 803
        
        index++;

        setTimeout(function(){
            setupAjaxDropdownWithTimeout(index, ary);
        },100 );
    }
	/**
	 * @param {HTMLFormElement} form
	 */
	/* global makeSortable, requiredCallback, set_ajax_dropdown, set_ajax_suggest, setCascadingDropdowns */
	function initializeAjaxHelpers (form) {
		jQuery(form).find('input[mrcsuggest]').each(function (i, el) {
			var $input = jQuery(el);
			var lookupTable = $input.attr('mrclookup');
			var mnt_fld = $input.attr('id'); // field to apply ajax suggest to
			var ret_fld = $input.attr('mrclookupfield'); // name of field to lookup in retrieval

			// @ts-ignore
			set_ajax_suggest(lookupTable, ret_fld, mnt_fld); // mrcajax.js, line 962
		});
        
        var ajaxDropdownAry = jQuery(form).find('select[mrcajaxdropdown]').toArray();
        setupAjaxDropdownWithTimeout(0,ajaxDropdownAry);

		/*jQuery(form).find('select[mrcajaxdropdown]').each(function (i, el) {
			var $select = jQuery(el);
			var lookupTable = $select.attr('mrclookup');
			var cur_fld = $select.attr('id'); // field to apply ajax suggest to
			var cascadeField = $select.attr('mrccascadefield'); // name of field in current app to get value of and pass to retieval
			var lookupField = $select.attr('mrclookupfield'); // name of field to lookup in retrieval

			if (cascadeField == null || cascadeField === '') cascadeField = '';

			// @ts-ignore
			set_ajax_dropdown(lookupTable, cur_fld, cascadeField, lookupField, 'load'); // mrcajax.js, line 803
		});*/

		jQuery(form).find('select[mrccascadefield]').each(function (i, el) {
			var cascadeField = jQuery(this).attr('mrccascadefield');

			try {
				jQuery('#' + cascadeField).change(function (ev) {
					// @ts-ignore
					setCascadingDropdowns(cascadeField); // mrcajax.js, line 954
				});
			} catch (err) {
				/* NOOP */
			}
		});

		// 2014-07-07: output to excel button on retrieval page
		jQuery(form).on('click', '.to-excel-img', function (ev) {
			//jQuery('#toexcel').val('1');
               var formInfo = formHandler.getForm(jQuery(this));
               var $form = formInfo.$form;
               $form.find('#toexcel').val('1');
               if(!formInfo.isForm) {
                    formHandler.doSubmit(jQuery(this),null,true);
                    formInfo.$form.find('#toexcel').val('0');
                    return;
               }else{
                    jQuery(form).submit();
               }
			
			jQuery(form).find('#toexcel').val('0');
		});

		var form1 = form;
		var lookupExists = jQuery(form).find('input[mrclookuplist]').length;
		var requiredFieldsExist = jQuery(form).find('input[mrcrequired="required"], textarea[mrcrequired="required"]').length;

		if (lookupExists !== 0 || requiredFieldsExist !== 0) {
			jQuery(form).find(':input[type="submit"],.ok_button_report').each(function (i, el) {
				var validated = true;

				jQuery(el).click(function (event) {
					if (requiredFieldsExist !== 0) {
						validated = checkRequiredFields(form1);

						if (!validated) {
							event.preventDefault();

							try {
								// @ts-ignore
								requiredCallback(); // call callback, if exists
							} catch (err) {
								/* NOOP */
							}
						}
					}
					if (validated && lookupExists !== 0) {
						event.preventDefault();

						return mrcAjaxValidateForm(form1, 0);
					}
				});
			});

			jQuery(form).keydown(function (ev) {
				var validated = true;

				if (ev.keyCode === 13) {
					ev.preventDefault();

					if (requiredFieldsExist !== 0) {
						validated = checkRequiredFields(form1);

						if (validated && lookupExists === 0) {
							jQuery(this).submit();
						}
					}

					if (validated && lookupExists !== 0) {
						ev.preventDefault();

						return mrcAjaxValidateForm(form1, 0);
					}
				}
			});
		}

		try {
			// @ts-ignore
			makeSortable(); // jquery.asmselect.js, line 102
		} catch (err) {
			/* NOOP */
		}
	}

	function mrcAjaxValidateForm (form, index) {
		var thisEle = jQuery(form).find('input[mrclookuplist]').eq(index);

		if (!thisEle || thisEle.length === 0) {
			if (jQuery('input[mrclookuplist][fldvalid="false"]').length === 0) {
				// @ts-ignore
				if (jQuery.isGridApp()) {
					// If Grid add MNT, need to tell server Accept is clicked
					var inp0 = jQuery('<input name="btn_mnt" value="Accept" type="hidden">');

					jQuery(form).append(inp0);
				}

				jQuery(form).submit();
			}

			return;
		}

		var cur_fld = jQuery(thisEle).attr('id');

		/* global makeHelperAjaxCall */
		setTimeout(function () {
			// @ts-ignore
			makeHelperAjaxCall(cur_fld, mrcAjaxValidateForm, form, index + 1); // mrcajax.js, line 813
		}, 50);
	}

	// @ts-ignore
	window.initializeAjaxHelpers = initializeAjaxHelpers;
	window.checkRequiredFields = checkRequiredFields;

	return {
		initializeAjaxHelpers: initializeAjaxHelpers,
		checkRequiredFields:checkRequiredFields
	};
}(window, jQuery));
