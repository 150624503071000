var ckeditorConfig = (function (window, jQuery) { // eslint-disable-line no-unused-vars
    'use strict';
    
    function setupCkeditor(id){
        var  editor = CKEDITOR.replace(id,{
            plugins: 'autocomplete,textmatch,toolbar,wysiwygarea,basicstyles,link,undo,placeholder',
            toolbar: [
				{ name: 'document', items: [ 'Undo', 'Redo' ] },
				{ name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline' ] },
				{ name: 'links', items: [ 'Link', 'Unlink' ] }
            ],
            height: 150,
			removeDialogTabs: 'image:advanced;link:advanced;link:target'
			
		} );

        return editor;
    }
    function initCkeditor ($html) {
		var selector = '.rich-text';
		var $richTextareas = ($html ? $html.find(selector) : jQuery(selector));

		if ($richTextareas.length === 0) return;

		// @ts-ignore
		if (!loadjs.isDefined('ckeditor')) {
			// @ts-ignore
			loadjs(['../assets/vendor/ckeditor/ckeditor.js'], 'ckeditor', { async: false });
		}

		// @ts-ignore
		loadjs.ready('ckeditor', function () {
            $richTextareas.each(function(){
                var id = jQuery(this).attr('id');
                setupCkeditor(id);
            });
		});
	}
   
    return{
        initCkeditor:initCkeditor
    }
}(window, jQuery));